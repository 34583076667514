import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { toast } from 'react-toastify';

import 'bootstrap-daterangepicker/daterangepicker.css';
import {
    fetchMissions as fetch,
    removeMission as remove,
    undoDeleteMission as undo,
    clearMission as clear
} from './actions';
import Footer from "../../Footer/FooterBottom";
import UserSubMenu from "../../Home/User/UserSubMenu";
import ListItem from './ListItem';
import api from '../../../api/api';
import { fetchClassRooms } from '../ClassRooms/actions';
import TugOfWar from "../../Admin/Question/View/Background/TugOfWar";

class Missions extends React.Component {

    constructor(props) {
        super(props);
        this.pathList = [];
        moment.locale('en');
        this.state = {
            search: '',
            OnlyMe: true,
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY'),
            date: moment().format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY')
        };
    }

    componentDidMount() {
        this.Sync();
        this.props.fetchClassRooms(false);
        window.scrollToPosition("main-mission-section");
    }

    setOnlyMe = OnlyMe => {
        this.setState({
            OnlyMe
        });
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    Sync = () => {
        this.props.fetch(this.state.startDate, this.state.endDate, this.state.OnlyMe);
    }

    renderMissions() {
        const { t, classRooms, missions, user } = this.props;
        let isLoaded = false;

        if (missions) {
            isLoaded = missions.isLoaded;
        }

        let toRemove = [];
        if (missions) {
            toRemove = missions.toRemove;
        }

        if (missions && missions.missions && missions.missions.length > 0) {
            let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
            let filterMission = missions.missions.filter(v => v.Lesson.SearchName.includes(searchText));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Missions.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterMission.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Missions.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Missions.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterMission.sort((a, b) => a.CreationDate < b.CreationDate ? 1 : -1).map(mission =>
                (toRemove && toRemove.includes(mission.Id)) ? "" :
                    <ListItem
                        Sync={this.Sync}
                        isTeacher={true}
                        user={user}
                        classRooms={classRooms}
                        item={mission}
                        remove={this.showDelete}
                        key={`mission-${mission.Id}`} />
            );
        }

        if (missions && missions.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Missions.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Missions.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteMission(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteMission = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/Missions/${id}`).then(response => {
            this.props.clear(id);
            this.Sync();

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Missions.toast_Delete.Title')}</div> <div className="h6">{t('Pages.Missions.toast_Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user } = this.props;

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Missions.title')} | {t('Pages.Profile.title')}
                    </title>
                </Helmet>
                <section className="position-relative" id="main-mission-section" style={{
                    minHeight: '100vh',
                    backgroundColor: '#74caee'
                }}>
                    <TugOfWar />
                    <div className="container padding-top padding-bottom " data-aos="fade-top" data-aos-easing="linear">

                        <div className="row mt-30 mb-30-none">

                            <div className="col-lg-8 mb-50">

                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="section-header mb-4">
                                        <div className="display-4 h3 custome-title" style={{
                                            color: '#ef669d'
                                        }}><i className="mx-3 fal fa-bullseye-arrow "></i> {t('Pages.Missions.title')} </div>
                                    </div>
                                </div>

                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex align-items-center">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form d-flex">
                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>
                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Missions.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            {
                                                user.user.UserType.Id === 3 ?
                                                    <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-danger">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            onChange={e => this.setOnlyMe(e.target.checked)}
                                                            ref={OnlyMe => this.OnlyMe = OnlyMe}
                                                            defaultChecked={this.state.OnlyMe}
                                                            name="OnlyMe"
                                                            id="OnlyMe" />
                                                        <label className="custom-control-label h6" style={{
                                                            whiteSpace: 'pre'
                                                        }} htmlFor="OnlyMe" >
                                                            {t(`Pages.Missions.MyMissions`)}
                                                        </label>
                                                    </div>
                                                    : ""
                                            }

                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Sync()} >
                                                <span>
                                                    <i className="icon fal fa-search"></i> {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {this.renderMissions()}
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user} />
                                </aside>
                            </div>

                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        classRooms: state.classRooms,
        user: state.user,
        missions: state.missions
    };
};

export default connect(mapStateToProps, { fetchClassRooms, fetch, remove, undo, clear })(withTranslation('common')(Missions));