import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { userInitial } from '../../actions/';
import Footer from "../../Footer/FooterBottom";
import DashBoardBg from "../../Admin/Question/View/Background/DashBoard/DashBoardBg";

class UserControl extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-dashboard-section");
    }

    renderNotification = count => {
        if (count > 0) {
            if (count > 99) {
                return <span className="badge badge-danger m-2 position-absolute" style={{
                    top: 0,
                    left: 0
                }}>+99</span>
            }
            else {
                return <span className="badge badge-danger m-2 position-absolute" style={{
                    top: 0,
                    left: 0
                }}>{count}</span>
            }
        }
        return "";
    }

    render() {
        const { t, user, notifications, location } = this.props;

        let Schools = 0;
        let mailbox = 0;
        let ErrorReport = 0;
        if (notifications && notifications.Notification && user.user && user.user.UserType.Id === 1) {
            Schools = notifications.Notification.Schools;
            mailbox = notifications.Notification.Contact;
            ErrorReport = notifications.Notification.ErrorReport;
        }

        return (
            <>

                <Helmet>
                    <title>
                        {t('Pages.UserControl.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>

                <section className="position-relative padding-bottom padding-top " id="main-dashboard-section" style={{
                    minHeight: '100vh'
                }}>
                    <DashBoardBg user={user} />

                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12 mb-50">

                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.UserControl.title')}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix justify-content-center">

                                    {
                                        user.user && user.user.UserType.Id === 1 ?
                                            <>
                                                <Link to="/ControlPanel/Users" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.users')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-users display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.users')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Schools" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.schools')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-school display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.schools')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Schools/Registration" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.SchoolsControl.showRegistered')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        {this.renderNotification(Schools)}
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-registered display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.SchoolsControl.showRegistered')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.Contents')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-keyboard display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.Contents')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/MailBox" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.mailbox')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        {this.renderNotification(mailbox)}
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-mailbox display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.mailbox')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/SendEmail" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.SendEmail')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-paper-plane display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.SendEmail')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/FileManager" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.FileManager')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-photo-video display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.FileManager')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Bills" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.Bills')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-file-invoice-dollar display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.Bills')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/ErrorReport" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.ErrorReport')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        {this.renderNotification(ErrorReport)}
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-tools display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.ErrorReport')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Templates" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.Templates')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-pencil-ruler display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.Templates')}</h5>
                                                    </div>
                                                </Link>


                                                <Link to="/ControlPanel/Game/Templates" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.Templates')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-pencil display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.GameTemplates')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/SearchResult" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.SearchResult')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-magnifying-glass display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.SearchResult')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Sliders" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.sliders')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-image-polaroid display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.sliders')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/SchoolRank" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.SchoolRank')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-trophy-alt display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.SchoolRank')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/StudentRank" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.StudentRank')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-medal display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.StudentRank')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/UserRank" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.UserRank')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-award display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.UserRank')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Statistics" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.CountStatistics')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-user-chart display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.CountStatistics')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Statistics/Lessons" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.LessonsStatistics')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-chart-mixed display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.LessonsStatistics')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/Competition/Statistics" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.CompetitionStatistics')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-chart-scatter-bubble display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.CompetitionStatistics')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/ControlPanel/EmailContent" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.EmailContent')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-mail-bulk display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.EmailContent')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/User/UserGuide" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.UserGuide')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-file-magnifying-glass display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.UserGuide')}</h5>
                                                    </div>
                                                </Link>

                                            </> : ""
                                    }
                                    {
                                        user.user && (user.user.UserType.Id === 2 || user.user.UserType.Id === 3) ?
                                            location &&
                                                location.location &&
                                                location.location.country_code !== "il" &&
                                                location.location.country_code !== "ps" &&
                                                user.user.Country !== "il" &&
                                                user.user.Country !== "ps"
                                                ?
                                                <>
                                                    <Link to="/User/Subscription" className="featured-block col-lg-3 col-md-4 col-sm-6" title={user.user.ActivateUntilDate !== "0" ? t('Pages.Profile.services.renewal') : t('Pages.Profile.services.active')}>
                                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                            backgroundColor: '#ffffffd9'
                                                        }}  >
                                                            <span className="border-one"></span>
                                                            <span className="border-two"></span>
                                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                                <span className="icon"> <i className="fal fa-money-bill-alt display-4 text-white"></i></span>
                                                            </div>
                                                            <h5>
                                                                {user.user.ActivateUntilDate !== "0" ? t('Pages.Profile.services.renewal') : t('Pages.Profile.services.active')}
                                                            </h5>
                                                        </div>
                                                    </Link>
                                                </> :
                                                <>
                                                    <Link to="/User/Renwal" className="featured-block col-lg-3 col-md-4 col-sm-6" title={user.user.ActivateUntilDate !== "0" ? t('Pages.Profile.services.renewal') : t('Pages.Profile.services.active')}>
                                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                            backgroundColor: '#ffffffd9'
                                                        }}  >
                                                            <span className="border-one"></span>
                                                            <span className="border-two"></span>
                                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                                <span className="icon"> <i className="fal fa-money-bill-alt display-4 text-white"></i></span>
                                                            </div>
                                                            <h5>
                                                                {user.user.ActivateUntilDate !== "0" ? t('Pages.Profile.services.renewal') : t('Pages.Profile.services.active')}
                                                            </h5>
                                                        </div>
                                                    </Link>
                                                </>
                                            : ""
                                    }

                                    {
                                        user.user && user.user.UserType.Id === 2 ?
                                            <>
                                                <Link to="/User/SubUsers" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.subUsers')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-users display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.subUsers')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/User/Bills" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.myBills')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-file-invoice-dollar display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.myBills')}</h5>
                                                    </div>
                                                </Link>

                                            </>
                                            : ""
                                    }
                                    {
                                        user.user && user.user.UserType.Id === 3 ?
                                            <>
                                                <Link to="/ControlPanel/Teachers" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.teachers')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-chalkboard-teacher display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.teachers')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/ControlPanel/ClassRooms" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.classrooms')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-users-class display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.classrooms')}</h5>
                                                    </div>
                                                </Link>

                                            </> : ""
                                    }
                                    {
                                        user.user && (user.user.UserType.Id === 3 || user.user.UserType.Id === 4) ?
                                            <>
                                                <Link to="/ControlPanel/School/Lessons/Statistics" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.LessonsStatistics')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-chart-mixed display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.LessonsStatistics')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/School/Competition" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Competition.Stage')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-crown display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.Competition.Stage')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/ControlPanel/Teachers/Statistics" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.TeacherReport')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-chart-bar display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.TeacherReport')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/Competition/Statistics" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.CountStatistics')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-chart-scatter-bubble display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.CompetitionStatistics')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/ControlPanel/School/Statistics" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.statistics')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-user-chart display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.statistics')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/ControlPanel/School/StudentRank" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.StudentRank')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-medal display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.StudentRank')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/ControlPanel/Missions" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.Missions')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-bullseye-arrow display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.Missions')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/ControlPanel/Shared/Lessons/" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.SharedLessons')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-mind-share display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.SharedLessons')}</h5>
                                                    </div>
                                                </Link>
                                                <Link to="/ControlPanel/Shared/Files/" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.SharedFiles')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-head-side-brain display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.SharedFiles')}</h5>
                                                    </div>
                                                </Link>
                                            </>
                                            : ""
                                    }
                                    {
                                        user.user && user.user.UserType.Id === 5 ?
                                            <>
                                                <Link to="/Student/Competition" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Competition.Stage')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-crown display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.Competition.Stage')}</h5>
                                                    </div>
                                                </Link>

                                                <Link to="/Student/Missions" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.UserControl.MyMissions')}>
                                                    <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                        backgroundColor: '#ffffffd9'
                                                    }}  >
                                                        <span className="border-one"></span>
                                                        <span className="border-two"></span>
                                                        <div className="icon-box d-flex justify-content-center flex-column">
                                                            <span className="icon"> <i className="fal fa-bullseye-arrow display-4 text-white"></i></span>
                                                        </div>
                                                        <h5> {t('Pages.UserControl.MyMissions')}</h5>
                                                    </div>
                                                </Link>
                                            </>

                                            : ""
                                    }


                                    <Link to="/Profile" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Profile.title')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-user display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('Pages.Profile.title')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/Profile/Edit" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('edit')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-edit display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('edit')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/Profile/Picture" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('ChangePicture')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-image display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('ChangePicture')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/Profile/ChangePassword" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('ChangePassword')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-key display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('ChangePassword')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/User/Mark" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Profile.markList')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-trophy-alt display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('Pages.Profile.markList')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/User/Favorite/Lesson" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Profile.favoriteList')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-heart display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('Pages.Profile.favoriteList')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/User/Favorite/Files" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Profile.favoriteFiles')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-heart-circle display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('Pages.Profile.favoriteFiles')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/User/History/Lessons" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Profile.lessonsHistory')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-history display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('Pages.Profile.lessonsHistory')}</h5>
                                        </div>
                                    </Link>

                                    <Link to="/User/History/Files" className="featured-block col-lg-3 col-md-4 col-sm-6" title={t('Pages.Profile.filesHistory')}>
                                        <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                            backgroundColor: '#ffffffd9'
                                        }}  >
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="icon-box d-flex justify-content-center flex-column">
                                                <span className="icon"> <i className="fal fa-file-arrow-down display-4 text-white"></i></span>
                                            </div>
                                            <h5> {t('Pages.Profile.filesHistory')}</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        notifications: state.notifications,
        location: state.location
    };
};

export default connect(mapStateToProps, { userInitial })(withTranslation('common')(UserControl));