import React from "react";
import { withTranslation } from 'react-i18next'
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { LazyLoadImage } from "react-lazy-load-image-component";

import TermsAndConditions from '../../../TermsAndConditions.js';
import api from "../../../../../api/api.js";
import configData from "../../../../Config/countries.json";

class UserForm extends React.Component {

    state = { countries: [], showPassword: false, showTermsAndConditions: false, emailCheck: false, CountryIndex: -1 };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.country_code) {
            var index = state.countries.findIndex(p => p.value === props.country_code);
            state.CountryIndex = index;
            state.Address = props.Address;
        }
        return state;
    }

    showPassword = () => {
        if (this.state.showPassword) {
            this.setState({
                showPassword: false
            });
        } else {
            this.setState({
                showPassword: true
            });
        }
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    showTermsAndConditions = () => {
        this.setState({
            showTermsAndConditions: true
        });
    }
    handleClose = () => {
        this.setState({
            showTermsAndConditions: false
        });
    }

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}
                </div>
                <div className="h6">
                    {t('Pages.Register.error_AllInformationIsRequired_toast.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }

    checkIfExist = Email => {
        this.setState({
            emailCheck: false
        });
        if (/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(Email)) {
            const { t } = this.props;
            let _toastId = toast(t('Processing.Title'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });
            api.put(`/Register/`,
                {
                    Email
                }
            ).then(response => {
                if (response.data === "1") {
                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.update(_toastId, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.Register.Email.info_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.info_toast.Body')}</div></div>,
                        type: toast.TYPE.INFO,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        emailCheck: true
                    });
                }
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    onSubmit = () => {
        this.props.onSubmit({
            ...this.props.formValues,
            Address: this.props.Address,
            Country: this.props.country_code
        });
    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;

        return (
            <>
                {
                    this.state.showTermsAndConditions ?
                        <Modal
                            size="xl"
                            show={this.state.showTermsAndConditions}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-route-interstate"></i> {t('Pages.Register.TermsAndConditions.modal')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget">
                                    <div className="widget-body">
                                        <TermsAndConditions />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={this.handleClose}>
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                <div className="contact-form-area">
                    <h4 className="title d-flex justify-content-center align-items-center mb-4">
                        <i className="fa-solid fa-user px-1 text-primary"></i>
                        ادخل بياناتك للبدء بالتجربة المجانية
                    </h4>

                    <form method="POST" className="contact-form" onSubmit={
                        handleSubmit(values => {
                            return new Promise(resolve => resolve())
                                .then(() => {
                                    const errors = validate(values, this.props);
                                    return Object.keys(errors).length !== 0;
                                })
                                .then(errors => {
                                    if (errors) {
                                        this.checkSubmit();
                                    } else {
                                        this.onSubmit();
                                    }
                                })
                        })
                    } autoComplete="new-password">


                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.FullName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={FullName => this.FullName = FullName}
                                    name="FullName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.FullName.Placeholder')}
                                    title={t('Pages.Register.FullName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.Phone.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"></i></label>
                            <div>
                                <Field
                                    ref={Phone => this.Phone = Phone}
                                    name="Phone"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.Phone.Placeholder')}
                                    title={t('Pages.Register.Phone.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'><i className="fa-regular fa-at px-1"></i> {t('Pages.Register.Email.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={Email => this.Email = Email}
                                    name="Email"
                                    component={this.renderField}
                                    onBlur={e => this.checkIfExist(e.target.value)}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.Email.Placeholder')}
                                    title={t('Pages.Register.Email.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'><i className="fa-regular fa-key px-1"></i> {t('Pages.Register.Password.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div className="search-form position-relative">
                                <Field
                                    ref={Password => this.Password = Password}
                                    name="Password"
                                    component={this.renderField}
                                    type={this.state.showPassword ? "text" : "password"}
                                    required={true}
                                    placeholder={t('Pages.Register.Password.Create')}
                                    title={t('Pages.Register.Password.title')}
                                    className="form-control"
                                />
                                <div className="d-flex justify-content-center align-items-center cursor-pointer" title={this.state.showPassword ? t('Pages.Register.Password.hide') : t('Pages.Register.Password.show')} style={{
                                    left: 0,
                                    top: 0,
                                    height: '57px',
                                    width: '50px',
                                    background: 'transparent',
                                    border: 'none',
                                    position: 'absolute'
                                }} onClick={() => this.showPassword()}>
                                    <i className={`fas ${this.state.showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>
                            </div>
                        </div>

                        <div className="form-group w-100">
                            <div className="custom-control ">
                                <label className="trol-label pt-1 cursor-pointer" onClick={() => this.showTermsAndConditions()}>
                                    <Link to="#" title={t('Pages.Register.TermsAndConditions.show')} >
                                        <i className="icon fad fa-route-interstate text-info px-1"></i>
                                    </Link>
                                    {t('Pages.Register.TermsAndConditions.confirm')}
                                </label>
                            </div>
                        </div>

                        <div className="form-group w-100 text-center d-flex justify-content-around align-items-center">

                            <button className={`custom-button  ${pristine || submitting ? "secondary-button" : ""}  `} disabled={pristine || submitting} >
                                <span className="d-flex justify-content-center align-items-center flex-wrap">
                                    تسجيل
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: getFormValues('UserForm')(state),
        location: state.location
    };
};

const validate = values => {
    const errors = {};

    if (!values.FullName) {
        errors.FullName = 'Pages.Register.FullName.error';
    }

    if (!values.Email) {
        errors.Email = 'Pages.Register.Email.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.Email)) {
        errors.Email = 'Pages.Register.Email.error_notmatch';
    }

    if (!values.Password) {
        errors.Password = 'Pages.Register.Password.error';
    }
    // else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(values.Password)) {
    //     errors.Password = 'Pages.Register.Password.error_notmatch';
    // }
    return errors;
}

const formUserForm = reduxForm({
    form: 'UserForm',
    validate
});

export default connect(mapStateToProps, {})(withTranslation('common')(formUserForm(UserForm)));