import { combineReducers } from 'redux';
import indicesReducers from './indicesReducers';
import lessonsReducers from './lessonsReducers';
import questionsReducers from './questionsReducers';
import FilesReducers from './FilesReducers';
import ExamReducers from './ExamReducers';
import UserReducers from './UserReducers';
import UsersReducers from './UsersReducers';
import ServicesReducers from './ServicesReducers';
import BillsReducers from './BillsReducers';
import SchoolsReducers from './SchoolsReducers';
import RegistrationSchoolsReducers from './RegistrationSchoolsReducers';
import ClassRoomsReducers from './ClassRoomsReducers';
import TeachersReducers from './TeachersReducers';
import StudentInClassRoomsReducers from './StudentInClassRoomsReducers';
import ContactUsReducers from './ContactUsReducers';
import SlidersReducers from './SlidersReducers';
import EmailContentReducers from './EmailContentReducers';
import FileManagerReducers from './FileManagerReducers';
import MissionsReducers from './MissionsReducers';
import TemplatesReducers from './TemplatesReducers';
import NotesReducers from './NotesReducers';
import QuestionTypeReducers from './QuestionTypeReducers';
import RanksReducers from './RanksReducers';
import StudentRanksReducers from './StudentRanksReducers';
import NotificationsReducers from './NotificationsReducers';
import ErrorReportReducers from './ErrorReportReducers';
import GameTemplatesReducers from './GameTemplatesReducers';
import userGuideIndicesReducers from './userGuideIndicesReducers';
import SearchReducers from './SearchReducers';
import LessonQuestionsReducers from './LessonQuestionsReducers';
import LocationReducers from './LocationReducers';

import { reducer as formReducer } from 'redux-form';

export default combineReducers({
    form: formReducer,
    indices: indicesReducers,
    UserGuideIndices: userGuideIndicesReducers,
    lessons: lessonsReducers,
    questions: questionsReducers,
    files: FilesReducers,
    exam: ExamReducers,
    user: UserReducers,
    services: ServicesReducers,
    users: UsersReducers,
    bills: BillsReducers,
    schools: SchoolsReducers,
    registrationSchools: RegistrationSchoolsReducers,
    classRooms: ClassRoomsReducers,
    teachers: TeachersReducers,
    studentInClassRooms: StudentInClassRoomsReducers,
    contactUs: ContactUsReducers,
    sliders: SlidersReducers,
    emailContents: EmailContentReducers,
    missions: MissionsReducers,
    templates: TemplatesReducers,
    questionTypes: QuestionTypeReducers,
    notes: NotesReducers,
    ranks: RanksReducers,
    notifications: NotificationsReducers,
    ErrorReport: ErrorReportReducers,
    studentRanks: StudentRanksReducers,
    gameTemplates: GameTemplatesReducers,
    search: SearchReducers,
    location: LocationReducers,
    lessonQuestions: LessonQuestionsReducers,
    fileManager: FileManagerReducers
});