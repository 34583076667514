import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import ScrollArea from "react-scrollbar";

import 'bootstrap-daterangepicker/daterangepicker.css';

import {
    fetchLessonsMark as fetch,
    setLessonFavorite as setFavorite,
    resetLessons
} from '../../Admin/Lessons/actions';
import Footer from "../../Footer/FooterBottom";
import { setIndeices } from "../../../context/sessionStorage";
import LessonItem from "../Tracks/LessonItem";
import UserSubMenu from './UserSubMenu';
import { fetchIndices } from '../../Admin/Indices/actions/';
import history from '../../../history';
import { fetchStudentInClassRooms } from '../../School/StudentInClassRooms/actions/';
import { fetchClassRooms } from '../../School/ClassRooms/actions';
import ClassRoomWithCertificate from "../../Admin/Question/View/Background/ClassRoomWithCertificate";

class UserMarks extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-user-mark-section");
    }

    constructor(props) {
        super(props);
        this.pathList = [];
        this.fetchMoreContainer = React.createRef();
        this.scrollArea = React.createRef();
        this.props.resetLessons();
        this.props.fetchIndices();
        moment.locale('en');
        this.state = {
            indices: [],
            search: '',
            isFetching: false,
            isClicked: false,
            page: 0,
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY'),
            date: moment().format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY')
        };
        setTimeout(() => {
            this.Reset();
        }, 50);
    }

    handleScroll = () => {
        var react = this.fetchMoreContainer;
        if (react && react.current) {
            let rect = react.current.getBoundingClientRect();
            if (rect) {
                if (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    (rect.bottom - rect.height) <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) && this.state.isFetching
                ) {
                    this.setState({
                        isFetching: false
                    });
                    setTimeout(() => {
                        this.Sync();
                    }, 50);
                }
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user &&
            props.user.user &&
            props.user.user.UserType &&
            (props.user.user.UserType.Id === 3 || props.user.user.UserType.Id === 4) &&
            props.classRooms && props.classRooms.length === 0) {
            props.fetchClassRooms();
        }
        return state;
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    setCurrentItem = item => {
        this.pathList = [];
        this.getNodePath(this.props.indices.nodes, item.id);

        const length = this.pathList.length;
        if (length > 1) {
            var mainPath = this.pathList[length - 1];
            var secondPath = this.pathList[length - 2];

            history.push(`/${secondPath.indexType !== "Lessons" ? "BookLessons" : secondPath.indexType}/${mainPath.id}/${item.id}`);
        }
    }

    getNodePath(nodes, currentId) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, currentId);
                if (isOpen) {
                    this.getNodePath(node.nodes, currentId);
                    this.pathList.push(node);
                }
            });
        }
    }

    HasNode(node, currentId) {
        if (node) {
            return (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, currentId) {
        var result = false;
        nodes.forEach(node => {
            result = result || (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        });
        return result;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY'),
            page: 0
        });
    }

    Sync = () => {
        this.props.fetch(this.state.startDate, this.state.endDate, this.state.page);
        this.setState({
            page: this.state.page + 1,
            isClicked: true,
            isFetching: true
        });
    }

    Reset = () => {
        this.props.resetLessons();
        this.setState({
            page: 0
        });
        setTimeout(() => {
            this.Sync();
        }, 50);
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    renderLessons() {
        const { t, user, classRooms } = this.props;
        let lessons = this.props.lessons;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        if (lessons && lessons.lessons && lessons.lessons.length > 0) {
            let filterLesson = lessons.lessons.filter(v => v.Name.includes(this.state.search));

            if (filterLesson.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Lessons.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Lessons.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterLesson.map((lesson, index) =>
                <LessonItem
                    classRooms={classRooms}
                    getStudent={this.fetchStudentInClassRooms}
                    user={user}
                    key={`${index}-${lesson.Id}`}
                    IsAdmin={false}
                    setFavorite={this.setFavorite}
                    setIndex={this.setCurrentItem}
                    lesson={lesson}
                    indices={indices.nodes}
                    remove={this.showDelete}></LessonItem>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Lessons.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    fetchStudentInClassRooms = (ClassRoomId, MediaId) => {
        this.props.fetchStudentInClassRooms(ClassRoomId, MediaId);
    }

    scrollDown = () => {
        this.scrollArea.current.scrollArea.scrollBottom();
        this.Sync();
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { t, user, lessons } = this.props;

        let isLoaded = false;
        let lazyLoading = true;
        if (lessons) {
            lazyLoading = lessons.lazyLoading;
            isLoaded = lessons.isLoaded;
        }

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Profile.markList')} | {t('Pages.Profile.title')}
                    </title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top " id="main-user-mark-section" style={{
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>

                    <ClassRoomWithCertificate currentStatus={this.state.currentStatus} FinishMove={this.FinishMove} />
                    <div className="container">

                        <div className="row mt-30 mb-30-none">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <span className="cate custome-title">{t('Pages.Profile.title')}</span>
                                            <h3 className="title custome-title"> {t('Pages.Profile.markList')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">

                                                <form className="search-form d-flex">
                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>

                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Lessons.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Reset()} >
                                                <span>
                                                    {
                                                        isLoaded || !this.state.isClicked ?
                                                            <i className="icon fal fa-search"></i>
                                                            :
                                                            <i className="icon fas fa-sync-alt fa-spin"></i>
                                                    }
                                                    {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ScrollArea
                                    horizontal={false}
                                    className={`area w-100 lazyLoading rounded p-2 `}
                                    smoothScrolling={true}
                                    style={{
                                        height: '100vh',
                                        background: 'rgb(250 250 250 / 59%)'
                                    }}
                                    ref={this.scrollArea}
                                    contentClassName="content "
                                    onScroll={this.handleScroll} >
                                    {this.renderLessons()}
                                    {
                                        lazyLoading && isLoaded ?
                                            <div className="product-list-item" ref={this.fetchMoreContainer}>
                                                <div className="product-content w-100 text-center ">
                                                    <div className="display-1 text-danger">
                                                        <i className="fad fa-sync-alt fa-spin"></i>
                                                    </div>
                                                    <h4 className="display-3 subtitle">
                                                        {t("Pages.Lessons.Loading")}
                                                    </h4>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </ScrollArea>

                                {
                                    lazyLoading && isLoaded ?
                                        <div className="row justify-content-md-center" style={{
                                            marginTop: '-50px'
                                        }} >
                                            <div className="d-flex justify-content-center w-100">
                                                <div onClick={() => this.scrollDown()} className="theme-btn btn-style-two d-inline-flex mb-1" style={{
                                                    borderRadius: 'unset',
                                                    borderTopRightRadius: '50px',
                                                    borderTopLeftRadius: '50px'
                                                }}>
                                                    <span className="txt pl-2"> {t('Pages.Lessons.LoadMore')} </span>
                                                    <div className="mouse-btn-down">
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms,
        user: state.user,
        indices: state.indices,
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { resetLessons, fetchStudentInClassRooms, fetchClassRooms, setFavorite, fetchIndices, fetch })(withTranslation('common')(UserMarks));
