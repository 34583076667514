import api from '../../../../api/api';

export const fetchLessons = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_LOADING' });
    const response = await api.get(`/Lessons/${id}?byIndex=true`);
    dispatch({ type: 'LESSONS_INITIAL', lessons: response.data.data });
}

export const resetLessons = () => async dispatch => {
    dispatch({ type: 'LESSONS_RESET' });
}

export const fetchFavoriteLessons = () => async dispatch => {
    dispatch({ type: 'LESSONS_LOADING' });
    const response = await api.get(`/UserFavoriteLessons/`);
    dispatch({ type: 'LESSONS_INITIAL', lessons: response.data.data });
}

export const fetchHistoryLessons = (startDate, endDate, page) => async dispatch => {
    dispatch({ type: 'LESSONS_LOADING' });
    const response = await api.get(`/UserLessonHistory/?startDate=${startDate}&endDate=${endDate}&skip=${page}`);
    if (response.data.data.length > 0) {
        dispatch({ type: 'LESSONS_LAZY_LOADING', lessons: response.data.data });
    } else {
        dispatch({ type: 'LESSONS_LAZY_LOADING_STOP' });
    }
}

export const removeCurrentLessons = () => async dispatch => {
    dispatch({ type: 'LESSONS_LAZY_LOADING_STOP' });
}

export const fetchSharedLessons = (startDate, endDate) => async dispatch => {
    dispatch({ type: 'LESSONS_LOADING' });
    const response = await api.get(`/SharedLessonsAndFiles/?startDate=${startDate}&endDate=${endDate}`);
    dispatch({ type: 'LESSONS_INITIAL', lessons: response.data.data });
}

export const fetchLessonsMark = (startDate, endDate, page) => async dispatch => {
    dispatch({ type: 'LESSONS_LOADING' });
    const response = await api.get(`/UserLessonMark/?startDate=${startDate}&endDate=${endDate}&skip=${page}`);
    if (response.data.data.length > 0) {
        dispatch({ type: 'LESSONS_LAZY_LOADING', lessons: response.data.data });
    } else {
        dispatch({ type: 'LESSONS_LAZY_LOADING_STOP' });
    }
}

export const fetchLesson = (id, ByView = false) => async dispatch => {
    try {
        const response = await api.get(`/Lessons/${id}?ByView=${ByView}`);
        dispatch({ type: 'LESSON_INITIAL', lesson: response.data.data, code: response.data.code });
        if (response.data.code === "no_permission") {
            const token = localStorage.getItem("token");
            if (!token) {
                window.location.href = `/Login?backTo=/LessonView/${id}`;
            }
        }
    } catch {
        window.location.href = "/NotFound";
    }
}

export const fetchMyCompetition = () => async dispatch => {
    try {
        const response = await api.get(`/MyCompetition/`);
        dispatch({ type: 'LESSON_INITIAL', lesson: response.data.data });
    } catch {
        const token = localStorage.getItem("token");
        if (token) {
            window.location.href = "/NoPermissions";
        } else {
            window.location.href = `/Login?backTo=/MyCompetition/`;
        }
    }
}

export const fetchGameScores = (id) => async dispatch => {
    const response = await api.get(`/GameScores/${id}`);
    if (response.data && response.data.data && response.data.data.Id) {
        dispatch({ type: 'GAME_SCORES_INITIAL', scores: response.data.data });
    }
}

export const fetchMissionGameScores = (id) => async dispatch => {
    const response = await api.get(`/MissionGameScores/${id}`);
    if (response.data && response.data.data && response.data.data.Id) {
        dispatch({ type: 'GAME_SCORES_INITIAL', scores: response.data.data });
    }
}

export const fetcMyCompetitionGameScores = (id) => async dispatch => {
    const response = await api.get(`/MyCompetition/${id}`);
    if (response.data && response.data.data && response.data.data.Id) {
        dispatch({ type: 'GAME_SCORES_INITIAL', scores: response.data.data });
    }
}

export const setLessonIsView = id => async dispatch => {
    dispatch({ type: 'LESSONS_UPDATE_ISVIEW', id });
}
export const setLessonFavorite = id => async dispatch => {
    const response = await api.put(`/UserFavoriteLessons/${id}`);
    dispatch({ type: 'LESSONS_UPDATE_FAVORITE', id: response.data });
}

export const updateLessonQuestion = (question) => async dispatch => {
    dispatch({ type: 'LESSONS_UPDATE_QUESTION', question });
}

export const removeLessons = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_QUEUE_FOR_REMOVAL', id });
}

export const undoLessons = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_UNDO', id });
}

export const clearLessons = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_CLEAN_NODES', id });
}

export const updateLessons = (newNode, id) => async dispatch => {
    dispatch({ type: 'LESSONS_UPDATE', id, newNode });
}

export const createLessons = (newNode, id) => async dispatch => {
    dispatch({ type: 'LESSONS_CREATE', id, newNode });
}

export const updateLessonSort = (id, sort) => async dispatch => {
    dispatch({ type: 'LESSONS_UPDATE_SORT', sort, id });
}

export const removeLessonQuestion = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_QUESTION_QUEUE_FOR_REMOVAL', id });
}

export const removeLessonPage = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_PAGE_QUEUE_FOR_REMOVAL', id });
}

export const clearLessonPage = (id) => async dispatch => {
    dispatch({ type: 'LESSON_PAGE_CLEAN_NODES', id });
}

export const undoDeleteLessonPage = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_PAGE_UNDO', id });
}

export const undoDeleteLessonQuestion = (id) => async dispatch => {
    dispatch({ type: 'LESSONS_QUESTION_UNDO', id });
}

export const clearLessonQuestion = (id) => async dispatch => {
    dispatch({ type: 'LESSON_QUESTION_CLEAN_NODES', id });
}

export const setLessonQuestionsSort = (id, sort) => async dispatch => {
    dispatch({ type: 'LESSON_QUESTION_SET_SORT', id, sort });
}

export const createNewLessonQuestion = question => async dispatch => {
    dispatch({ type: 'LESSONS_CREATE_QUESTION', question });
}