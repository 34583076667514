import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactHtml from 'raw-html-react';

import { updateQuestion } from '../../actions/';
import ListFiled from '../FillFiled//ListFiled';
import configData from "../../../../Config/CustomStyle.json";
import QuestionTitle from '../QuestionTitle/';

class FillListView extends React.Component {

    state = {
        isFirst: false,
        CustomStyle: configData.CustomStyle,
        answers: [],
        fills: [],
        answersList: [],
        position: {
            x: 0,
            y: 0
        },
        designerContainer: 100,
        mark: {
            type: '',
            userMark: 0,
            isSolved: false
        }
    };

    constructor(props) {
        super(props)
        this.designerContainer = React.createRef();
    }

    componentDidMount() {
        if (this.props.setQuestionDesignerContainer) {
            this.props.setQuestionDesignerContainer(this.designerContainer.current.offsetWidth);
        }
        this.setState({
            designerContainer: this.designerContainer.current.offsetWidth
        });
    }

    static getDerivedStateFromProps(props, state) {
        state.CustomStyle = { ...props.CustomStyle };
        state.answers = props.answers;
        state.categories = props.categories;
        state.QuestionId = props.Id;
        state.version = props.version;

        let answerList = [];
        if (state.answers) {
            state.answers.forEach((item) => {
                let pos = null;
                if (state.CustomStyle.answerPosition && Object.keys(state.CustomStyle.answerPosition).length !== 0) {
                    pos = state.CustomStyle.answerPosition.filter(a => a.Id === item.Id)[0];
                }
                answerList.push({
                    ...item,
                    Position: { ...pos }
                });
            });
        }
        state.answers = answerList;

        let fills = [];
        if (props.fills) {
            props.fills.forEach((node) => {
                const hasAnswer = answerList.filter(p => node.AnswerId === p.Id)[0];
                if (hasAnswer) {
                    const hasCategory = state.categories.filter(p => node.CategoryId === p.Id)[0];
                    if (hasCategory) {
                        fills.push(node);
                    }
                }
            });
        }
        state.fills = fills;

        if (props.onEdit === true && props.categories) {
            let trueAnswer = [];
            props.categories.forEach(category => {
                if (category.CustomStyle && category.CustomStyle.Answers) {
                    category.CustomStyle.Answers.forEach(answer => {
                        var result = { ...answer };
                        result.Sort += 1;
                        result.isTrue = true;
                        trueAnswer.push(result);
                    });
                }
            });
            state.answersList = trueAnswer;
        }

        if (props.examAnswers && (!state.isFirst || props.showResult)) {
            state.isFirst = true;
            const hasAnswer = props.examAnswers.filter(p => p.Id === props.Id)[0];
            if (hasAnswer) {
                state.answersList = hasAnswer.answers;
            } else if (props.showResult) {
                const { t } = props;
                state.mark = {
                    type: 'danger',
                    userMark: 0,
                    isSolved: true,
                    text: t('Pages.Lessons.Questions.forms.Options.error.NoAnswers'),
                    title: props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                };
            }
        }

        return state;
    }

    renderCategories() {
        if (this.state.categories) {

            let AnswersMargin = 0;
            if (this.state.CustomStyle.questionTypeStyle && this.state.CustomStyle.questionTypeStyle.AnswersMargin) {
                AnswersMargin = this.state.CustomStyle.questionTypeStyle.AnswersMargin;
            }

            const length = this.state.categories.length;

            return (
                <div className={`row row-cols-1`} style={{
                    height: '100%',
                    position: 'relative'
                }}>
                    {
                        this.state.categories.sort((a, b) => a.Sort - b.Sort).map((item, index) => {
                            if (index + 1 === length) {
                                AnswersMargin = 0;
                            }
                            return (
                                <div className={`col mb-${AnswersMargin}`} key={item.Id}>
                                    {this.renderListFiled(item)}
                                </div>
                            )
                        })
                    }
                </div >
            );
        }
        return "";
    }

    renderControlButton() {
        const { t, solveButton, showResult } = this.props;
        if (solveButton) {
            return (
                <>
                    {showResult !== true ? this.renderMark() : ""}
                    <div className="w-100 mt-3 d-flex justify-content-around">
                        <button className="btn btn-success mx-3" title={t('Pages.Lessons.Questions.forms.Options.CheckAnswer')} onClick={() => this.CheckAnswer()}>
                            <i className="icon fas fa-badge-check"></i> {t('Pages.Lessons.Questions.forms.Options.CheckAnswer')}
                        </button>
                        <button className="btn btn-dark mx-3" title={t('Pages.Lessons.Questions.forms.Options.ResetAnswer')} onClick={() => this.ResetAnswer()}>
                            <i className="icon fas fa-repeat-alt"></i> {t('Pages.Lessons.Questions.forms.Options.ResetAnswer')}
                        </button>
                    </div>
                </>
            );
        }
        return "";
    }

    ResetAnswer = () => {
        this.state.categories.sort((a, b) => a.Sort - b.Sort).forEach(item => {
            const count = item.Name.split("(_)").length - 1;
            for (let index = 1; index <= count; index++) {
                document.getElementById(`ListFiledAnswer-${item.Id}-${index}`).value = "";
            }
        });
        this.setState({
            answersList: [],
            version: this.state.version + 1,
            mark: {
                type: '',
                userMark: 0,
                isSolved: false
            }
        });

    }

    CheckAnswer = () => {
        setTimeout(() => {
            const { t, mark, toast } = this.props;
            const {
                trueAnswers,
                answersCount,
                trueAnswer,
                falseAnswer,
                answersList
            } = this.GetAnswerList();

            if (answersCount === 0) {
                let _toastId = toast(t('Processing'), {
                    position: "bottom-left",
                    autoClose: false
                });
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-lightbulb-on"></i> {t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Title')}</div> <div className="h6">{t('Pages.Lessons.Questions.forms.Options.error.NoAnswer.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (trueAnswers === trueAnswer) {
                this.setState({
                    answersList,
                    mark: {
                        type: 'success',
                        userMark: mark,
                        isSolved: true,
                        text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                        title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                    }
                });
                this.props.playTrueSound();
                this.props.showConfetti();
            } else if (falseAnswer > trueAnswer) {
                this.setState({
                    answersList,
                    mark: {
                        type: 'danger',
                        userMark: (mark * trueAnswer / trueAnswers).toFixed(2),
                        isSolved: true,
                        text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                        title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                    }
                });
                this.props.playFalseSound();
            } else {
                this.setState({
                    answersList,
                    mark: {
                        type: 'warning',
                        userMark: (mark * trueAnswer / trueAnswers).toFixed(2),
                        isSolved: true,
                        text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                        title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')

                    }
                });
                this.props.playFalseSound();
            }
        }, 200);
    }

    renderMark() {
        const { t, showResult } = this.props;
        if (this.state.mark.isSolved && this.state.mark.type) {
            return (
                <>
                    <div className={`alert mt-4 h5 p-3 alert-${this.state.mark.type}`} role="alert">
                        <h5 className="alert-heading"> <i className="icon fal fa-lightbulb-on"></i>{this.state.mark.title}</h5>
                        {this.state.mark.text}
                        <p className="mb-0">{t('Pages.Lessons.Questions.forms.Options.yourMark')} : {this.state.mark.userMark}</p>
                    </div>
                </>
            );
        } else if (showResult) {
            setTimeout(() => {
                const { t, mark } = this.props;
                const {
                    trueAnswers,
                    trueAnswer,
                    falseAnswer
                } = this.GetAnswerList();

                if (trueAnswers === trueAnswer) {
                    this.setState({
                        mark: {
                            type: 'success',
                            userMark: mark,
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.success.Answers'),
                            title: t('Pages.Lessons.Questions.forms.Options.success.Title')
                        }
                    });
                } else if (falseAnswer > trueAnswer) {
                    this.setState({
                        mark: {
                            type: 'danger',
                            userMark: (mark * trueAnswer / trueAnswers).toFixed(2),
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')
                        }
                    });
                } else {
                    this.setState({
                        mark: {
                            type: 'warning',
                            userMark: (mark * trueAnswer / trueAnswers).toFixed(2),
                            isSolved: true,
                            text: t('Pages.Lessons.Questions.forms.Options.error.Answers'),
                            title: this.props.isExamQuestion === true ? t('Pages.Lessons.Questions.forms.Options.error.ForExam') : t('Pages.Lessons.Questions.forms.Options.error.Title')

                        }
                    });
                }
            }, 500);
        }
        return "";
    }

    handleChangeAnswer = (AnswerId, Sort, CategoryId) => {
        let answersList = [];
        let AutoCorrection = false;
        if (this.props.isExamQuestion !== true) {
            if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {

                this.state.categories.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                    const CustomStyle = item.CustomStyle;
                    const count = item.Name.split("(_)").length - 1;
                    for (let index = 1; index <= count; index++) {
                        var userAnswer = document.getElementById(`ListFiledAnswer-${item.Id}-${index}`).value.trim();
                        if (userAnswer) {
                            let newAnswer =
                            {
                                Id: item.Id,
                                Sort: index,
                                Answer: userAnswer,
                                isTrue: false
                            }
                            if (CustomStyle.Answers) {
                                var answer = CustomStyle.Answers.filter(p => p.Id === item.Id && p.Sort === index - 1)[0];
                                if (answer && answer.Answer && userAnswer === answer.Answer) {
                                    newAnswer.isTrue = true;
                                }
                            }
                            answersList.push(newAnswer);
                        }
                    }
                });

                if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                    AutoCorrection = true;
                    if (this.state.categories.filter(p => p.Id === CategoryId)[0].CustomStyle.Answers.filter(p => p.Answer === AnswerId && p.Sort === Sort - 1)[0]) {
                        this.props.playTrueSound();
                    } else {
                        this.props.playFalseSound();
                    }
                }

                this.setState({
                    answersList,
                    mark: {
                        type: '',
                        userMark: 0,
                        isSolved: AutoCorrection
                    }
                });
            }
        }
        this.saveExamAnswer();
    }

    saveExamAnswer() {
        if (this.props.saveExamAnswer) {
            setTimeout(() => {
                const {
                    trueAnswers,
                    trueAnswer,
                    falseAnswer,
                    answersList,
                    userMark } = this.GetAnswerList();
                this.props.saveExamAnswer(this.props.Id, this.props.TypeId, answersList, {
                    trueResultCount: trueAnswers,
                    trueAnswer,
                    falseAnswer,
                    questionMark: this.props.mark
                }, userMark);
            }, 500);
        }
    }

    GetAnswerList = () => {
        let trueAnswer = 0;
        let falseAnswer = 0;
        let trueAnswers = 0;
        let answersCount = 0;
        let answersList = [];
        this.state.categories.sort((a, b) => a.Sort - b.Sort).forEach(item => {
            const CustomStyle = item.CustomStyle;
            const count = item.Name.split("(_)").length - 1;
            trueAnswers += count;
            for (let index = 1; index <= count; index++) {
                var userAnswer = document.getElementById(`ListFiledAnswer-${item.Id}-${index}`)?.value
                if (userAnswer) {
                    userAnswer.trim();
                }
                if (userAnswer) {
                    let newAnswer =
                    {
                        Id: item.Id,
                        Sort: index,
                        Answer: userAnswer,
                        isTrue: false
                    }
                    if (CustomStyle.Answers) {
                        var answer = CustomStyle.Answers.filter(p => p.Id === item.Id && p.Sort === index - 1)[0];
                        if (answer && answer.Answer && userAnswer === answer.Answer) {
                            newAnswer.isTrue = true;
                            trueAnswer++;
                        } else {
                            falseAnswer++;
                        }
                        answersCount++;
                    }
                    answersList.push(newAnswer);
                } else {
                    falseAnswer++;
                }
            }
        });

        let userMark = 0;
        const mark = this.props.mark
        if (trueAnswers === trueAnswer) {
            userMark = mark;
        } else {
            userMark = (mark * trueAnswer / trueAnswers).toFixed(2);
        }

        return {
            trueAnswers,
            answersCount,
            trueAnswer,
            falseAnswer,
            answersList,
            userMark
        };
    }

    renderListFiled(item) {
        const count = item.Name.split("(_)").length - 1;
        var html = item.Name;
        const CustomStyle = item.CustomStyle;

        let AutoCorrection = false;
        if (this.props.isExamQuestion !== true) {
            if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {
                if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                    AutoCorrection = true;
                }
            }
        }

        for (let index = 0; index < count; index++) {

            let isTrue = "";
            if (this.state.mark.isSolved === true) {
                var hasAnswer = this.state.answersList.filter(p => p.Id === item.Id && p.Sort === (index + 1))[0];
                if (hasAnswer && hasAnswer.isTrue) {
                    isTrue = 1;
                } else if (hasAnswer && AutoCorrection === true) {
                    isTrue = 0;
                } else if (AutoCorrection === false) {
                    isTrue = 0;
                }
            }

            html = html.replace("(_)", `<span version='${this.state.version}' isTrue='${isTrue}'  Sort='${index + 1}' data-react-component="ListFiled"></span>`);
        }

        return <ReactHtml html={html} key={item.Id} SoundIconType={this.state.CustomStyle.questionTypeStyle.AnswersSelectType} SoundIconClass={this.state.CustomStyle.questionTypeStyle.AnswersSelectClass} CustomStyle={CustomStyle} componentMap={{ ListFiled }} fills={this.state.fills} answers={this.state.answers} CategoryId={item.Id} QuestionId={this.state.QuestionId} answersList={this.state.answersList} handleChangeAnswer={this.handleChangeAnswer} />;
    }

    render() {
        const { isExamQuestion, title, mark, index, Id, onEdit, showResult } = this.props;
        const SizeView = this.props.SizeView ? this.props.SizeView : 100;

        let style = {
            minHeight: `${this.state.CustomStyle.questionStyle.height}px`,
            width: `${this.state.CustomStyle.questionStyle.width}%`,
            maxWidth: '100%',
            paddingLeft: `${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            paddingTop: `${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            paddingRight: `${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            paddingBottom: `${this.state.CustomStyle.questionStyle.PaddingBottom}px`,

            borderColor: this.state.CustomStyle.questionStyle.BorderColor !== 'transparent' ? this.state.CustomStyle.questionStyle.BorderColor : "",
            borderStyle: `${this.state.CustomStyle.questionStyle.BorderStyle}`,
            borderLeftWidth: `${this.state.CustomStyle.questionStyle.BorderWidthLeft}px`,
            borderRightWidth: `${this.state.CustomStyle.questionStyle.BorderWidthRight}px`,
            borderTopWidth: `${this.state.CustomStyle.questionStyle.BorderWidthTop}px`,
            borderBottomWidth: `${this.state.CustomStyle.questionStyle.BorderWidthBottom}px`,
            borderRadius: `${this.state.CustomStyle.questionStyle.BorderRadiusTop}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusRight}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusBottom}${this.state.CustomStyle.questionStyle.BorderRadiusUnit} ${this.state.CustomStyle.questionStyle.BorderRadiusLeft}${this.state.CustomStyle.questionStyle.BorderRadiusUnit}`,
            boxShadow: `${this.state.CustomStyle.questionStyle.ShadowPositionX}px ${this.state.CustomStyle.questionStyle.ShadowPositionY}px ${this.state.CustomStyle.questionStyle.ShadowRadius}px 0px ${this.state.CustomStyle.questionStyle.shadowColor}`,
        };

        let styleBackground = {
            height: `100%`,
            marginLeft: `-${this.state.CustomStyle.questionStyle.PaddingLeft}px`,
            marginTop: `-${this.state.CustomStyle.questionStyle.PaddingTop}px`,
            marginRight: `-${this.state.CustomStyle.questionStyle.PaddingRight}px`,
            marginBottom: `-${this.state.CustomStyle.questionStyle.PaddingBottom}px`,
            opacity: `${this.state.CustomStyle.questionStyle.Opacity}%`,
            backgroundColor: this.state.CustomStyle.questionStyle.backgroundColor !== 'transparent' ? this.state.CustomStyle.questionStyle.backgroundColor : "",
            backgroundImage: this.state.CustomStyle.questionStyle.backgroundImage !== '' ? `url('${this.state.CustomStyle.questionStyle.backgroundImage}')` : "",
            backgroundPositionX: `${this.state.CustomStyle.questionStyle.BgPositionX}%`,
            backgroundPositionY: `${this.state.CustomStyle.questionStyle.BgPositionY}%`,
            backgroundSize: `${this.state.CustomStyle.questionStyle.BgSize}`,
            backgroundRepeat: `${this.state.CustomStyle.questionStyle.BgRepeat}`,
            backgroundAttachment: `${this.state.CustomStyle.questionStyle.BgAttachment}`,
        };

        if (this.state.CustomStyle.questionTypeStyle.AnswerOrder === 'absolute') {
            style = { ...style, height: `${this.state.CustomStyle.questionStyle.height}px` };
        }

        let align = "x";
        if (this.state.CustomStyle.questionTypeStyle.AnswersAlign) {
            if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'left') {
                align = "l";
            } else if (this.state.CustomStyle.questionTypeStyle.AnswersAlign === 'right') {
                align = "r";
            }
        }

        let AutoCorrection = false;
        if (this.state.CustomStyle.questionTypeStyle.AutoCorrection) {
            if (this.state.CustomStyle.questionTypeStyle.AutoCorrection === 'true') {
                AutoCorrection = true;
            }
        }

        return (
            <div className={`w-${SizeView} mb-3 mx-auto`} >
                <div className="bg-white w-100 p-3 form-group position-relative">
                    <QuestionTitle mark={mark} onEdit={onEdit} index={index} title={title} ResetAnswer={this.ResetAnswer} isExamQuestion={this.props.isExamQuestion} updateVersion={this.props.updateVersion}></QuestionTitle>
                    <div className="w-100" ref={this.designerContainer}>
                        <div className={`m${align}-auto position-relative overflow-hidden`} style={style} >
                            <div className="w-100 position-absolute" style={styleBackground} id={`SelectDesignerPreview_${Id}`} > </div>
                            <style dangerouslySetInnerHTML={{ __html: `#SelectDesignerPreview_${Id}{${this.state.CustomStyle.questionStyle.Custom} }` }}></style>
                            {this.renderCategories()}
                        </div>
                    </div>
                    {showResult === true ? this.renderMark() : ""}
                    {isExamQuestion !== true ? AutoCorrection === false ? this.renderControlButton() : "" : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions
    };
};

export default connect(mapStateToProps, { updateQuestion })(withTranslation('common')(FillListView));