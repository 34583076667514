import api from '../../api/api';
import { getToken } from "../../context/auth";
import jwt_decode from "jwt-decode";

export const checkLogin = () => async dispatch => {
    const token = getToken();
    if (token) {
        const browserToken = localStorage.getItem("browserToken");
        const response = await api.get(`/User/?browserToken=${browserToken}`);
        if (response.data.data) {
            dispatch({ type: 'USER_INITIAL', user: response.data.data });
            if (response.data.code === "browser_is_not_recognized") {
                if (window.location.pathname !== "/RecognizedBrowser") {
                    window.location.href = "/RecognizedBrowser";
                }
            } else {
                const notification = await api.get(`/Notification/`);
                if (notification.data.data) {
                    dispatch({ type: 'NOTIFICATION_INITIAL', Notification: notification.data.data });
                }
            }
            setTimeout(() => {
                if (checkTokenExpirationMiddleware(token) <= 0) {
                    localStorage.removeItem("token");
                    dispatch({ type: 'USER_NOT_LOGIN', user: {} });
                }
            }, checkTokenExpirationMiddleware(token));
        } else {
            localStorage.removeItem("token");
            dispatch({ type: 'USER_NOT_LOGIN', user: {} });
        }
    } else {
        dispatch({ type: 'USER_NOT_LOGIN', user: {} });
    }
}

export const getNotification = () => async dispatch => {
    const notification = await api.get(`/Notification/`);
    if (notification.data.data) {
        dispatch({ type: 'NOTIFICATION_INITIAL', Notification: notification.data.data });
    }
}

export const notificationIsView = notificationType => async dispatch => {
    dispatch({ type: 'NOTIFICATION_ISVIEW', notificationType });
}

export const newNotification = notificationType => async dispatch => {
    dispatch({ type: 'NEW_NOTIFICATION', notificationType });
}

export const userLogin = user => async dispatch => {
    const time = checkTokenExpirationMiddleware(user.token);
    setTimeout(() => {
        if (checkTokenExpirationMiddleware(user.token) <= 0) {
            localStorage.removeItem("token");
            dispatch({ type: 'USER_NOT_LOGIN', user });
        }
    }, time);
    dispatch({ type: 'USER_INITIAL', user });
    if (user) {
        const notification = await api.get(`/Notification/`);
        if (notification.data.data) {
            dispatch({ type: 'NOTIFICATION_INITIAL', Notification: notification.data.data });
        }
    }
}

export const userInitial = user => async dispatch => {
    dispatch({ type: 'USER_INITIAL', user });
}

export const userLogout = user => async dispatch => {
    dispatch({ type: 'USER_NOT_LOGIN', user });
}

export const userIsActive = () => async dispatch => {
    dispatch({ type: 'USER_IS_ACTIVE' });
}

export const subscriptionsInitial = () => async dispatch => {
    dispatch({ type: 'SERVICES_EMPTY' });
    const response = await api.get(`/Subscriptions/`);
    dispatch({ type: 'SERVICES_INITIAL', data: response.data.data });
}

export const servicesInitial = () => async dispatch => {
    dispatch({ type: 'SERVICES_EMPTY' });
    const response = await api.get(`/Service/`);
    dispatch({ type: 'SERVICES_INITIAL', data: response.data.data });
}

export const servicesEmpty = () => async dispatch => {
    dispatch({ type: 'SERVICES_EMPTY' });
}

export const servicesUserInitial = id => async dispatch => {
    dispatch({ type: 'SERVICES_EMPTY' });
    const response = await api.get(`/Service/${id}`);
    dispatch({ type: 'SERVICES_INITIAL', data: response.data.data });
}

export const billsInitial = id => async dispatch => {
    dispatch({ type: 'BILLS_LOADING' });
    const response = await api.get(`/Bills/${id}?byUser=true`);
    dispatch({ type: 'BILLS_INITIAL', data: response.data.data });
}

export const notesInitial = id => async dispatch => {
    dispatch({ type: 'NOTES_LOADING' });
    const response = await api.get(`/Notes/${id}`);
    dispatch({ type: 'NOTES_INITIAL', data: response.data.data });
}

const checkTokenExpirationMiddleware = token => {
    if (token) {
        const maxNumber = 2147483647;
        const result = (jwt_decode(token).exp - Date.now() / 1000) * 1000;
        if (result > maxNumber) {
            return maxNumber
        }
        return result;
    }
    return 0;
};

export const myBillsInitial = () => async dispatch => {
    dispatch({ type: 'BILLS_LOADING' });
    const response = await api.get(`/MyBills/`);
    dispatch({ type: 'BILLS_INITIAL', data: response.data.data });
}