import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import { Helmet } from "react-helmet";

import api from '../../../api/api';
import SendMissionForm from "../../Home/Forms/SendMissionForm";
import ModalQuestionResult from "./ModalQuestionResult";
import ModalStatistic from "./ModalStatistic";
import { notificationIsView } from '../../../api/socket.io';
import { clearLessonQuestions } from "../../Home/actions";
import { connect } from "react-redux";

class ListItem extends React.Component {

    state = {
        moreInfo: false,
        showStatistic: false,
        ClassRoomId: 0,
        MarkId: [],
        showEdit: false,
        showResult: false,
        showQuestionResult: false,
        markRang: [],
        classRooms: [],
        questions: [],
        OrderByName: false,
        OrderByMark: false,
        studentId: 0,
        studentName: "",
        SelectedQuestions: [],
        isFirst: true
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();

        if (this.props.isTeacher && this.props.item && this.props.item.ClassTypeName) {
            this.state.classRooms = this.props.item.ClassTypeName.map(classRoom => {
                return {
                    value: classRoom.Id,
                    label: classRoom.Name
                };
            });
        }
        this.state.markRang = [{
            value: 1,
            label: '100'
        },
        {
            value: 2,
            label: '90-99'
        },
        {
            value: 3,
            label: '80-99'
        },
        {
            value: 4,
            label: '70-79'
        },
        {
            value: 5,
            label: '60-69'
        },
        {
            value: 6,
            label: '50-59'
        },
        {
            value: 7,
            label: '50 >'
        }];
    }

    static getDerivedStateFromProps(props, state) {
        if (props.item && state.isFirst) {
            if (props.item.customLesson === true) {
                props.clearLessonQuestions();
                state.SelectedQuestions = props.item.Lesson?.Questions.map(item => item.Id);
            }
            state.isFirst = false;
        }
        return state;
    }

    openViewWindow = Id => {
        window.openMissionView(Id);
        notificationIsView("MISSION", this.props.user.user.Id);
    }

    copyUrl = Id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        window.CopyToClipboard(document.location.origin + '/MissionView/' + Id);

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-copy"></i>
                    {t('Pages.Missions.get_link_success_toast.Title')}</div>
                <div className="h6">
                    {t('Pages.Missions.get_link_success_toast.Body')}
                </div>
            </div>,
            type: toast.TYPE.INFO,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    handleClose = () => {
        this.setState({ showEdit: false, showResult: false, showQuestionResult: false, showStatistic: false });
    }

    showStatistic = () => {
        this.setState({ showStatistic: true });
    }

    showResult = () => {
        this.setState({ showResult: true });
    }

    showQuestionResult = (studentId, studentName) => {
        this.setState({ showQuestionResult: true, studentId, studentName });
    }

    hideQuestionResult = () => {
        this.setState({
            showQuestionResult: false
        });
    }

    edit = () => {
        this.setState({ showEdit: true });
    }

    onSubmit = (postData, _toastId) => {
        const { t, item } = this.props;

        api.put(`/Missions/${item.Id}`,
            postData
        ).then(response => {
            this.props.Sync();

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Missions.update_success_toast.Title')}</div> <div className="h6">{t('Pages.Missions.update_success_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.handleClose();

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.label.includes(input);
        }
        return true;
    }

    setSelectedOption = ClassRoom => {
        if (ClassRoom) {
            this.setState({
                ClassRoomId: ClassRoom.value
            });
        } else {
            this.setState({
                ClassRoomId: 0
            });
        }
    }

    setSelectedMarkOption = Mark => {
        if (Mark) {
            this.setState({
                MarkId: Mark
            });
        } else {
            this.setState({
                MarkId: []
            });
        }
    }

    openLessonView = Id => {
        const { item } = this.props;
        if (item.Lesson && item.Lesson.isGame) {
            window.openGameView(Id);
        } else {
            window.openLessonView(Id);
        }
    }

    getClassList = item => {
        let result = "  ";
        item.ClassTypeName.forEach(classRoom => {
            if (this.state.ClassRoomId) {
                if (this.state.ClassRoomId === classRoom.Id) {
                    result += classRoom.Name + ", ";
                }
            } else {
                result += classRoom.Name + ", ";
            }
        });
        return result.substring(0, result.length - 2);
    }

    returnMission = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('Pages.Missions.toast.return')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('Cancel')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.returnMissionConfirm(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('Pages.Missions.toast.returnConfirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    returnMissionConfirm = id => {
        const { t, item } = this.props;

        var student = item.Students.filter(p => p.SID === id)[0];
        if (student) {
            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            api.post(`/ReturnMissionToStudent/${id}`).then(response => {

                student.Mark = "";
                student.StudentTime = "";
                this.setState({
                    student
                });
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Missions.toast.update_success_toast.Title')}</div> <div className="h6">{t('Pages.Missions.toast.update_success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    OrderByName = () => {
        this.setState({
            OrderByName: !this.state.OrderByName
        });
    }

    OrderByMark = () => {
        this.setState({
            OrderByMark: !this.state.OrderByMark
        });
    }

    setSelect = SelectedQuestions => {
        this.setState({
            SelectedQuestions
        });
    }

    renderTableResult = () => {
        const { t, item } = this.props;
        let Students = item.Students;

        if (this.state.OrderByMark) {
            Students = Students.sort((a, b) => a.Mark < b.Mark ? 1 : -1);
        }
        if (this.state.OrderByName) {
            Students = Students.sort((a, b) => a.Name < b.Name ? -1 : 1);
        }
        return <table className="table table-striped table-hover table-bordered">
            <thead>
                <tr className="text-center">
                    <th>
                        {t('Pages.Missions.table.student')}
                        <span title={this.state.OrderByName ? t('Pages.Missions.table.no_order') : t('Pages.Missions.table.order')} onClick={() => this.OrderByName()} className={`mx-2 btn btn-sm ${this.state.OrderByName ? "btn-danger" : "btn-primary"}`}>
                            <i className="fa-light fa-arrow-down-a-z"></i>
                        </span>
                    </th>
                    <th>
                        {t('Pages.Missions.table.Username')}
                    </th>
                    <th>
                        {t('Pages.Missions.table.ClassRoom')}
                    </th>
                    <th>
                        {t('Pages.Missions.table.mark')}
                        <span title={this.state.OrderByMark ? t('Pages.Missions.table.no_order') : t('Pages.Missions.table.order')} onClick={() => this.OrderByMark()} className={`mx-2 btn btn-sm ${this.state.OrderByMark ? "btn-danger" : "btn-primary"}`}>
                            <i className="fa-light fa-arrow-down-9-1"></i>
                        </span>
                    </th>
                    <th>
                        {t('Pages.Missions.table.time')}
                    </th>
                    <th className="d-print-none">
                        {t('Pages.Missions.table.action')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    Students.filter(p => p.ClassRoom.ClassRoomId === this.state.ClassRoomId || this.state.ClassRoomId === 0).map(student => {
                        let display = true;
                        if (this.state.MarkId && this.state.MarkId.length > 0) {
                            display = false;
                            if (student.Mark) {
                                this.state.MarkId.forEach(mark => {
                                    if (mark.value === 1 && student.Mark >= 100) {
                                        display = true;
                                    } else if (mark.value === 2 && student.Mark >= 90 && student.Mark <= 99) {
                                        display = true;
                                    } else if (mark.value === 3 && student.Mark >= 80 && student.Mark <= 89) {
                                        display = true;
                                    } else if (mark.value === 4 && student.Mark >= 70 && student.Mark <= 79) {
                                        display = true;
                                    } else if (mark.value === 5 && student.Mark >= 60 && student.Mark <= 69) {
                                        display = true;
                                    } else if (mark.value === 6 && student.Mark >= 50 && student.Mark <= 59) {
                                        display = true;
                                    } else if (mark.value === 7 && student.Mark < 50) {
                                        display = true;
                                    }
                                });
                            }
                        }
                        if (display === false) {
                            return null;
                        }
                        return <tr key={`student-${student.SID}`}>
                            <td>
                                {student.Name}
                            </td>
                            <td>
                                {student.Username}
                            </td>
                            <td>
                                {
                                    student.ClassRoom ?
                                        <>
                                            {student.ClassRoom.ClassTypeName} -    {student.ClassRoom.DivisionName}
                                        </> : ""
                                }

                            </td>
                            <td>

                                {
                                    student.Mark && student.Mark >= 0 ?
                                        <div className="font-weight-bold h4 d-flex justify-content-center" >
                                            <span className={`${(student.Mark / item.TotalMark) >= 0.5 ? "text-success" : "text-danger"} `}>
                                                {student.Mark}
                                            </span>
                                            /
                                            <span>
                                                {item.TotalMark}
                                            </span>
                                        </div>
                                        :
                                        t('Pages.Missions.table.NoAnswers')
                                }
                            </td>
                            <td>
                                {
                                    student.StudentTime && student.StudentTime.Clock ?
                                        <span style={{
                                            direction: 'ltr'
                                        }}>
                                            <i className="icon fal fa-user-clock"></i>
                                            {
                                                student.StudentTime.Clock.hour ?
                                                    <span title={t('Pages.Missions.TimeHour.title')}>
                                                        {student.StudentTime.Clock.hour}
                                                    </span>
                                                    : "00"
                                            }
                                            :
                                            {
                                                student.StudentTime.Clock.minutes ?
                                                    <span title={t('Pages.Missions.TimeMinutes.title')}>
                                                        {student.StudentTime.Clock.minutes >= 10 ? student.StudentTime.Clock.minutes : "0" + student.StudentTime.Clock.minutes}
                                                    </span>
                                                    : "00"
                                            }
                                            :
                                            {
                                                student.StudentTime.Clock.seconds ?
                                                    <span title={t('Pages.Missions.TimeSeconds.title')}>
                                                        {student.StudentTime.Clock.seconds >= 10 ? student.StudentTime.Clock.seconds : "0" + student.StudentTime.Clock.seconds}
                                                    </span>
                                                    : "00"
                                            }
                                        </span>
                                        : ""
                                }
                            </td>
                            <td className="d-print-none">
                                {
                                    student.Mark != null ?
                                        <div className="d-flex justify-content-center">
                                            <button onClick={() => this.showQuestionResult(student.SID, student.Name)} className="btn btn-sm w-auto btn-primary" title={t('Pages.Missions.table.showStudentResult')}>
                                                <i className="fas fa-eye"></i>
                                            </button>

                                            <button onClick={() => this.returnMission(student.SID)} className="btn btn-sm w-auto btn-danger mx-1" title={t('Pages.Missions.table.return')}>
                                                <i className="fas fa-undo"></i>
                                            </button>
                                        </div>
                                        : ""
                                }
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>;
    }

    render() {
        const { t, item, user, classRooms, isTeacher, Lesson } = this.props;

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        if (item) {
            return (
                <>
                    {
                        isTeacher && this.state.showResult ?
                            <Modal
                                centered
                                key="ModalSendMission"
                                size="xl"
                                show={this.state.showResult}
                                onHide={() => this.handleClose()}
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false} >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <i className="icon fal fa-users-class "></i>
                                        {t('Pages.Missions.studentsResult')}  |  <span className="font-weight-bold">  {item.Lesson.Name}</span>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Helmet>
                                        <title>  {t('Pages.Missions.studentsResult')}  | {item.Lesson.Name} |
                                            (
                                            {this.getClassList(item)}
                                            )
                                        </title>
                                    </Helmet>

                                    <div className="contact-form">
                                        <div className="form-group">
                                            <Select
                                                name="ClassRoomId"
                                                title={t('Pages.Missions.ClassRoom.title')}
                                                placeholder={t('Pages.Missions.ClassRoom.placeholder')}
                                                onChange={e => this.setSelectedOption(e)}
                                                isRtl={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                options={this.state.classRooms}
                                                filterOption={this.filterOptions}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#92278f',
                                                        primary: '#92278f',
                                                    },
                                                })}
                                            />
                                        </div>
                                        <div className="form-group ">
                                            <Select
                                                name="MarkId"
                                                title={t('Pages.Missions.Mark.title')}
                                                placeholder={t('Pages.Missions.Mark.placeholder')}
                                                onChange={e => this.setSelectedMarkOption(e)}
                                                isRtl={true}
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                ref={MarkId => this.MarkId = MarkId}
                                                options={this.state.markRang}
                                                filterOption={this.filterOptions}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#92278f',
                                                        primary: '#92278f',
                                                    },
                                                })}
                                            />
                                        </div>
                                        <div className="form-group mb-5 w-100">
                                            <div className="d-flex justify-content-center w-auto">
                                                <ReactToPrint content={() => this.componentRef}
                                                    trigger={() => {
                                                        return <div className="custom-button orange-button">
                                                            <span>
                                                                <i className="icon fal fa-print"></i> {t('Print')}
                                                            </span>
                                                        </div>;
                                                    }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget widget-category w-100" ref={el => (this.componentRef = el)}>
                                        <div className="d-none d-print-block">
                                            <div className="pt-3 px-3 mt-3 border-bottom border-dark mx-1">
                                                <div className="d-flex justify-content-around align-items-center">
                                                    <img src="/assets/images/logo/logo.png" alt={`${t('alarabeyya')}`} />
                                                    <div className="h5 text-center">
                                                        <div className="h3 title mt-2">
                                                            {item.Lesson.Name}
                                                        </div>
                                                        {t('Pages.Missions.studentsResult')}
                                                    </div>
                                                    <div className="h5 text-right">
                                                        {t('Date')} :
                                                        {`${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`}
                                                        <div className="h4 title mt-2">
                                                            <span className="h5">
                                                                {t("Teacher.Name")} :
                                                            </span>
                                                            {item.User.Name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-body" >
                                            {this.renderTableResult()}
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        isTeacher && this.state.showEdit ?
                            <SendMissionForm
                                {...this.props}
                                item={item}
                                Sync={this.props.Sync}
                                handleClose={this.handleClose}
                                classRooms={classRooms}
                                SelectedQuestions={this.state.SelectedQuestions}
                                lesson={item.Lesson}
                                LessonId={item.Lesson.Id}
                                setSelect={this.setSelect}
                                user={user} />
                            : ""
                    }
                    {
                        isTeacher && this.state.showStatistic ?
                            <ModalStatistic
                                Id={item.Id}
                                item={item}
                                Questions={item.Lesson.Questions}
                                handleClose={this.handleClose} />
                            : ""
                    }
                    {
                        this.state.showQuestionResult ?
                            <ModalQuestionResult
                                ViewGameResult={item.Lesson.isGame}
                                studentName={this.state.studentName}
                                isTeacher={isTeacher}
                                Id={isTeacher ? this.state.studentId : item.Id}
                                handleClose={this.hideQuestionResult} />
                            : ""
                    }
                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >

                            <div className="product-thumb">
                                {
                                    item.Lesson.Icon ?
                                        <LazyLoadImage src={item.Lesson.Icon} alt={`${item.Lesson.Name}`} />
                                        :
                                        <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Lesson.Name}`} />
                                }
                                <div className="product-action-link">
                                    {
                                        isTeacher ?
                                            <>
                                                <div className="my-1" onClick={() =>
                                                    window.openMissionView(item.Id)
                                                    // this.openLessonView(item.Lesson.Id)
                                                } title={t("Pages.Lessons.action.view")}><i className="far fa-eye"></i></div>
                                                <div className="my-1" onClick={() => this.showStatistic()} title={t("Pages.Missions.action.statistic")}><i className="far fa-poll"></i></div>
                                                <div className="my-1" onClick={() => this.showResult()} title={t("Pages.Missions.action.students")}><i className="far fa-users-class"></i></div>
                                                <div className="my-1" onClick={() => this.edit()} title={t("Pages.Missions.action.edit")}><i className="far fa-edit"></i></div>
                                                <div className="my-1" onClick={() => this.copyUrl(item.Id)} title={t("Pages.Missions.action.copyUrl")}><i className="far fa-copy"></i></div>
                                                <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.Missions.action.remove")}><i className="far fa-trash"></i></div>
                                            </>
                                            :
                                            <>
                                                {
                                                    Lesson && Lesson.isGame ?
                                                        <>

                                                            {
                                                                item.Status === 1 ?
                                                                    <div className="my-1" onClick={() => this.openViewWindow(item.Id)} title={t("Pages.Missions.action.view")}><i className="far fa-eye"></i></div> : ""
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                item.Mark != null ?
                                                                    <div className="my-1" onClick={() => this.showQuestionResult()} title={t("Pages.Missions.action.result")}><i className="far fa-poll"></i></div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            item.Status === 1 ?
                                                                                <div className="my-1" onClick={() => this.openViewWindow(item.Id)} title={t("Pages.Missions.action.view")}><i className="far fa-eye"></i></div>
                                                                                : ""
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                }

                                            </>
                                    }
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.Missions.action.info")}><i className="far fa-info"></i></div>
                                </div>
                            </div>
                            <div className="product-content">
                                <h5 className="title"> {item.Lesson.Name} </h5>
                                <p>
                                    <span title={t('Pages.Missions.StartDate')}>
                                        <i className="icon fal fa-calendar-alt"></i>
                                        {item.StartDate}
                                    </span>
                                    <span className="mx-2">  <i className="fal fa-minus"></i></span>
                                    <span className="d-inline-block" title={t('Pages.Missions.EndDate')}>
                                        <i className="icon fal fa-calendar-alt"></i>
                                        {item.EndDate}
                                    </span>
                                </p>
                                {
                                    isTeacher && item.Students ?
                                        <p>
                                            <span className="ml-3 d-flex flex-wrap justify-content-between">
                                                <span title={t('Pages.Missions.studentCount')}>
                                                    <i className="icon fal fa-users-class"></i>
                                                    (
                                                    <span className="comma-list">
                                                        {
                                                            item.ClassTypeName.map(classRoom => {
                                                                return <span key={`classRoom-${classRoom.Id}`} className="mr-1">{classRoom.Name}</span>;
                                                            })
                                                        }
                                                    </span>
                                                    )
                                                </span>
                                                <span title={t('Pages.Missions.studentCount')}>
                                                    <i className="icon fal fa-abacus"></i>
                                                    {item.Students.length}
                                                </span>
                                            </span>
                                        </p>
                                        : ""
                                }
                                {
                                    !isTeacher && item.Mark != null && item.TotalMark ?
                                        <p>
                                            <span className="ml-3" title={t('Pages.Missions.yourMark')}>
                                                <i className="icon fal fa-tally"></i>
                                                <span className="font-weight-bold" >
                                                    <span className={`${item.Mark / item.TotalMark < 0.5 ? "text-danger" : "text-success"} ml-1`}>
                                                        {item.Mark}
                                                    </span>
                                                    /  {item.TotalMark}
                                                </span>
                                                <span className={` mx-1`}>
                                                    (
                                                    <span className={`font-weight-bold ${item.Mark / item.TotalMark < 0.5 ? "text-danger" : "text-success"}`}>
                                                        {(item.Mark / item.TotalMark * 100).toFixed(2)}
                                                    </span>
                                                    <i className=" icon fal fa-percent mx-1"></i>
                                                    )
                                                </span>
                                            </span>
                                            {
                                                item.StudentTime && item.StudentTime.Clock ?
                                                    <span title={t('Pages.Missions.yourTime')} style={{
                                                        direction: 'ltr'
                                                    }}>
                                                        <i className="icon fal fa-user-clock"></i>
                                                        {
                                                            item.StudentTime.Clock.hour ?
                                                                <span title={t('Pages.Missions.TimeHour.title')}>
                                                                    {item.StudentTime.Clock.hour}
                                                                </span>
                                                                : "00"
                                                        }
                                                        :
                                                        {
                                                            item.StudentTime.Clock.minutes ?
                                                                <span title={t('Pages.Missions.TimeMinutes.title')}>
                                                                    {item.StudentTime.Clock.minutes >= 10 ? item.StudentTime.Clock.minutes : "0" + item.StudentTime.Clock.minutes}
                                                                </span>
                                                                : "00"
                                                        }
                                                        :
                                                        {
                                                            item.StudentTime.Clock.seconds ?
                                                                <span title={t('Pages.Missions.TimeSeconds.title')}>
                                                                    {item.StudentTime.Clock.seconds >= 10 ? item.StudentTime.Clock.seconds : "0" + item.StudentTime.Clock.seconds}
                                                                </span>
                                                                : "00"
                                                        }
                                                    </span>
                                                    : ""
                                            }
                                        </p>
                                        :
                                        <>
                                            {
                                                !isTeacher && item.Status === 2 ?
                                                    <p className="text-danger">
                                                        {t('Pages.Missions.notStart')}
                                                    </p>
                                                    : ""
                                            }
                                            {
                                                !isTeacher && item.Status === 3 ?
                                                    <p className="text-danger">
                                                        {t('Pages.Missions.endTime')}
                                                    </p>
                                                    : ""
                                            }
                                        </>
                                }
                                <p>
                                    {
                                        item.CustomStyle.Clock && (item.CustomStyle.Clock.TimeHour + item.CustomStyle.Clock.TimeMinutes) ?
                                            <>
                                                {
                                                    item.CustomStyle.Clock.TimeType ?
                                                        <>
                                                            <i className="icon fal fa-info-square"></i>
                                                            {t('Pages.Missions.Type.' + item.CustomStyle.Clock.TimeType)}
                                                        </>
                                                        : ""
                                                }
                                                <span className="mx-1" style={{
                                                    direction: 'ltr'
                                                }}>
                                                    <i className="icon fal fa-stopwatch"></i>

                                                    <span title={t('Pages.Missions.TimeHour.title')}>
                                                        {
                                                            item.CustomStyle.Clock.TimeHour ?
                                                                item.CustomStyle.Clock.TimeHour : "00"
                                                        }
                                                    </span>
                                                    :
                                                    <span title={t('Pages.Missions.TimeMinutes.title')}>
                                                        {
                                                            item.CustomStyle.Clock.TimeMinutes ?
                                                                item.CustomStyle.Clock.TimeMinutes >= 10 ? item.CustomStyle.Clock.TimeMinutes : "0" + item.CustomStyle.Clock.TimeMinutes
                                                                : "00"
                                                        }
                                                    </span>

                                                </span>
                                            </>
                                            :
                                            item.CustomStyle.GamePlay ?
                                                <>
                                                    <i className="icon fal fa-sort-numeric-down"></i>
                                                    {t(`Pages.Missions.GamePlay.${isTeacher ? "" : "student_"}${item.CustomStyle.GamePlay}`)}
                                                    <br />
                                                    <i className="icon fal fa-hdd"></i>
                                                    {t(`Pages.Missions.GameSave.${isTeacher ? "" : "student_"}${item.CustomStyle.GameSave}`)}
                                                </>
                                                : ""
                                    }
                                </p>
                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Missions.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User.Name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Missions.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Missions.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
        lessonQuestions: state.lessonQuestions
    };
};

export default connect(mapStateToProps, { clearLessonQuestions })(withTranslation('common')(ListItem));