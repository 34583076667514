import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

import 'bootstrap-daterangepicker/daterangepicker.css';

import {
    fetchSharedFiles as fetch,
    setFileFavorite as setFavorite
} from '../../Admin/Files/actions';
import Footer from "../../Footer/FooterBottom";
import { setIndeices } from "../../../context/sessionStorage";
import ListItem from "../../Admin/Files/ListItem";
import UserSubMenu from '../../Home/User/UserSubMenu';
import { fetchIndices } from '../../Admin/Indices/actions';
import history from '../../../history';
import { fetchClassRooms } from '../ClassRooms/actions';
import { fetchStudentInClassRooms } from '../StudentInClassRooms/actions/';

class SharedFiles extends React.Component {


    constructor(props) {
        super(props);
        this.pathList = [];
        window.scrollToTop();
        this.props.fetchIndices();
        moment.locale('en');
        this.state = { indices: [], search: '', date: moment().format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY') };
    }

    componentDidMount() {
        this.props.fetchClassRooms();
        this.props.fetch(moment().format('DD/MM/YYYY'), moment().format('DD/MM/YYYY'));
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    setCurrentItem = item => {
        this.pathList = [];
        this.getNodePath(this.props.indices.nodes, item.id);

        const length = this.pathList.length;
        if (length > 1) {
            var mainPath = this.pathList[length - 1];
            var secondPath = this.pathList[length - 2];

            history.push(`/${secondPath.indexType !== "Lessons" ? "BookLessons" : secondPath.indexType}/${mainPath.id}/${item.id}`);
        }
    }

    getNodePath(nodes, currentId) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, currentId);
                if (isOpen) {
                    this.getNodePath(node.nodes, currentId);
                    this.pathList.push(node);
                }
            });
        }
    }

    HasNode(node, currentId) {
        if (node) {
            return (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, currentId) {
        var result = false;
        nodes.forEach(node => {
            result = result || (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        });
        return result;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));

        this.props.fetch(picker.startDate.format('DD/MM/YYYY'), picker.endDate.format('DD/MM/YYYY'));
    }

    fetchStudentInClassRooms = (ClassRoomId, MediaId) => {
        this.props.fetchStudentInClassRooms(ClassRoomId, MediaId);
    }

    renderFiles() {
        const { t, user, classRooms } = this.props;
        let files = this.props.files;
        let isLoaded = false;

        if (files) {
            isLoaded = files.isLoaded;
        }

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        if (files && files.files && files.files.length > 0) {
            let searchText = this.state.search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
            let filterFile = files.files.filter(v => v.SearchName.includes(searchText));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Lessons.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterFile.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Lessons.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Lessons.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterFile.map(file =>
                <ListItem
                    classRooms={classRooms}
                    getStudent={this.fetchStudentInClassRooms}
                    setFavorite={this.setFavorite}
                    user={user}
                    key={file.Id}
                    IsAdmin={false}
                    setIndex={this.setCurrentItem}
                    item={file}
                    indices={indices.nodes}></ListItem>
            );
        }

        if (files && files.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Lessons.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Lessons.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    render() {
        const { t, user } = this.props;

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment()];
        ranges[t("range.Last30Days")] = [moment().subtract(29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <>
                <section className="pos-rel padding-bottom padding-top ">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">

                        <Helmet>
                            <title>
                                {t('Pages.Profile.SharedFiles')} | {t('Pages.Profile.title')}
                            </title>
                        </Helmet>

                        <div className="row mt-30 mb-30-none">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <span className="cate">{t('Pages.Profile.title')}</span>
                                            <h3 className="title"> {t('Pages.Profile.SharedFiles')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">

                                                <form className="search-form d-flex">
                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>

                                                    <input type="text" className="mr-2" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Lessons.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderFiles()}
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms,
        user: state.user,
        indices: state.indices,
        files: state.files
    };
};

export default connect(mapStateToProps, { fetchStudentInClassRooms, fetchClassRooms, fetchIndices, fetch, setFavorite })(withTranslation('common')(SharedFiles));
