import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Footer from "../Footer/FooterBottom";
import api, { setToken } from '../../api/api';
import history from '../../history';

import { userLogin } from '../actions/';
import GoogleLogin from "react-google-login";
import GameObjects from "../Admin/Question/View/GameObjects";
import { LazyLoadImage } from "react-lazy-load-image-component";

const isArabic = (str) => /^[\u0600-\u06FF\u0750-\u077F]/.test(str);

class Login extends React.Component {

    state = { showPassword: false, backTo: "", currentStatus: "" };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        const { search } = this.props.location;
        if (search) {
            this.setState({
                backTo: search.substring(8)
            });
        }
        window.scrollToPosition("main-login-section");
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setRememberMe = () => {
        this.props.change('RememberMe', !this.RememberMe.ref.current.getValue());
    }

    validate = () => {
        const errors = {};

        if (!this.Username.ref.current.getValue()) {
            errors.Username = 'Pages.Register.Username.error';
        }

        if (!this.Password.ref.current.getValue()) {
            errors.Password = 'Pages.Register.Password.error';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Login.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Login.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                currentStatus: "Wrong"
            });
        } else {
            const browserToken = localStorage.getItem('browserToken');

            api.post(`/Login/`, {
                Username: this.Username.ref.current.getValue(),
                RememberMe: this.RememberMe.ref.current.getValue(),
                Password: this.Password.ref.current.getValue(),
                browserToken: browserToken
            }).then(response => {
                if (response.data.code === "username_or_password_error") {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Login.error_info_toast.Title')}</div> <div className="h6">{t('Pages.Login.error_info_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        currentStatus: "Wrong"
                    });
                } else if (response.data.code === "user_is_locked") {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-user-lock"></i> {t('Pages.Login.error_user_is_locked_toast.Title')}</div> <div className="h6">{t('Pages.Login.error_user_is_locked_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        currentStatus: "Wrong"
                    });
                } else if (response.data.code === "school_user_is_locked") {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-user-lock"></i> {t('Pages.Login.error_school_user_is_locked_toast.Title')}</div> <div className="h6">{t('Pages.Login.error_school_user_is_locked_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        currentStatus: "Wrong"
                    });
                } else {
                    this.setState({
                        currentStatus: "True"
                    }, () => {
                        setTimeout(() => {

                            setToken(response.data.data.token);
                            localStorage.setItem('token', response.data.data.token);
                            this.props.userLogin(response.data.data);

                            if (this.state.backTo) {
                                if (response.data.code === "user_is_active" && response.data.data.UserType.Id === 2 && response.data.data.ActivateUntilDate === "0") {
                                    if (this.props.location && this.props.location.location && this.props.location.location.country_code === "il") {
                                        history.push("/User/Renwal");
                                    } else {
                                        history.push("/User/Subscription");
                                    }
                                } else {
                                    history.push(this.state.backTo);
                                }
                            } else {
                                let url = "/User/Control";
                                if (response.data.data.UserType.Id === 5) {
                                    url = "/Student/DashBoard";
                                    if (!isArabic(response.data.data.Name)) {
                                        let _toastId = toast(t('Processing'), {
                                            position: "bottom-left",
                                            autoClose: false
                                        });
                                        toast.update(_toastId, {
                                            render: () => <div onClick={() => { history.push("/Profile/Edit") }}>
                                                <div className="h5" >
                                                    <i className="icon fal fa-edit"></i>
                                                    {t('Pages.Login.warrning_toast.Title')}
                                                </div>
                                                <div className="h6">
                                                    {t('Pages.Login.warrning_toast.Body')}
                                                </div>
                                            </div>,
                                            type: toast.TYPE.WARNING,
                                            position: "bottom-left",
                                            autoClose: 150000,
                                            hideProgressBar: false,
                                            closeOnClick: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                        });
                                        history.push("/Profile/Edit");
                                    } else {
                                        history.push(this.props.match.url !== "/Login" ? this.props.match.url : url);
                                    }
                                } else {
                                    if (response.data.code === "user_is_active" && response.data.data.UserType.Id === 2 && response.data.data.ActivateUntilDate === "0") {
                                        if (this.props.location && this.props.location.location && this.props.location.location.country_code === "il") {
                                            history.push("/User/Renwal");
                                        } else {
                                            history.push("/User/Subscription");
                                        }
                                    } else {
                                        history.push(this.props.match.url !== "/Login" ? this.props.match.url : url);
                                    }
                                }
                            }
                        }, 1000);
                    });
                    toast.update(this._toastId.current, {
                        render: () => <div>
                            <div className="h5">
                                <i className="icon fal fa-shield-check"></i>
                                {t('Pages.Login.success_toast.Title')}
                            </div>
                            <div className="h6">
                                {t('Pages.Login.success_toast.Body')}
                            </div>
                        </div>,
                        type: toast.TYPE.INFO,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    currentStatus: "Wrong"
                });
            });
        }
        e.preventDefault();
    }

    fbLogin = () => {
        const LoginWithThirdParty = this.LoginWithThirdParty;
        window.FB.login(function (response) {
            if (response.status === 'connected') {
                const userID = response.authResponse.userID;
                const accessToken = response.authResponse.accessToken;
                LoginWithThirdParty(userID, accessToken, "facebook");
            }
        }, { scope: 'public_profile,email' });
    }

    googleLogin = response => {
        const accessToken = response.tokenId;
        const userID = response.profileObj.googleId;
        this.LoginWithThirdParty(userID, accessToken, "google");
    }

    LoginWithThirdParty = (userID, accessToken, type) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.get(`/LoginWithThirdParty/?type=${type}&userID=${userID}&accessToken=${accessToken}`).then(responseFrom => {
            if (responseFrom.data.code === "email_not_found") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-facebook"></i> {t('Pages.Login.error_facebook_toast.Title')}</div> <div className="h6">{t('Pages.Login.error_facebook_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    currentStatus: "Wrong"
                });
            } else if (responseFrom.data.code === "user_is_locked") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-user-lock"></i> {t('Pages.Login.error_user_is_locked_toast.Title')}</div> <div className="h6">{t('Pages.Login.error_user_is_locked_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    currentStatus: "Wrong"
                });
            } else if (responseFrom.data.code === "school_user_is_locked") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-user-lock"></i> {t('Pages.Login.error_school_user_is_locked_toast.Title')}</div> <div className="h6">{t('Pages.Login.error_school_user_is_locked_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    currentStatus: "Wrong"
                });
            } else if (responseFrom.data.code === "success") {

                this.setState({
                    currentStatus: "True"
                }, () => {
                    setTimeout(() => {

                        this.props.userLogin(responseFrom.data.data);
                        setToken(responseFrom.data.data.token);
                        localStorage.setItem('token', responseFrom.data.data.token);
                        localStorage.setItem('browserToken', responseFrom.data.data.browserToken);

                        if (this.state.backTo) {
                            history.push(this.state.backTo);
                        } else {
                            let url = "/User/Control";
                            if (responseFrom.data.data.UserType.Id === 5) {
                                url = "/Student/DashBoard";
                                if (!isArabic(responseFrom.data.data.Name)) {
                                    let _toastId = toast(t('Processing'), {
                                        position: "bottom-left",
                                        autoClose: false
                                    });
                                    toast.update(_toastId, {
                                        render: () => <div onClick={() => { history.push("/Profile/Edit") }}>
                                            <div className="h5">
                                                <i className="icon fal fa-edit"></i>
                                                {t('Pages.Login.warrning_toast.Title')}
                                            </div>
                                            <div className="h6">
                                                {t('Pages.Login.warrning_toast.Body')}
                                            </div>
                                        </div>,
                                        type: toast.TYPE.WARNING,
                                        position: "bottom-left",
                                        autoClose: 150000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: true
                                    });
                                    history.push("/Profile/Edit");
                                } else {
                                    history.push(this.props.match.url !== "/Login" ? this.props.match.url : url);
                                }
                            } else {
                                history.push(this.props.match.url !== "/Login" ? this.props.match.url : url);
                            }
                        }
                    }, 1000);
                });
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Login.success_toast.Title')}</div> <div className="h6">{t('Pages.Login.success_toast.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                currentStatus: "Wrong"
            });
        });
    }

    showPassword = () => {
        if (this.state.showPassword) {
            this.setState({
                showPassword: false
            });
        } else {
            this.setState({
                showPassword: true
            });
        }
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Login.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel call-overlay call-in-action oh" id="main-login-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>

                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `0%`,
                        animationDuration: '13s'
                    }}><GameObjects objectCss="Cloud2" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `5%`,
                        animationDuration: '15'
                    }}><GameObjects objectCss="Cloud8" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `90%`,
                        animationDuration: '18s'
                    }}><GameObjects objectCss="Cloud3" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `15%`,
                        animationDuration: '15s'
                    }}><GameObjects objectCss="Cloud1" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `15%`,
                        animationDuration: '2s'
                    }}><GameObjects objectCss="Cloud3" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `75%`,
                        animationDuration: '2s'
                    }}><GameObjects objectCss="Cloud1" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `95%`,
                        animationDuration: '5s'
                    }}><GameObjects objectCss="Cloud4" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `50%`
                    }}><GameObjects objectCss="Cloud6" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `55%`,
                        animationDuration: '4s'
                    }}><GameObjects objectCss="Cloud2" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `43%`,
                        animationDuration: '9s'
                    }}><GameObjects objectCss="Cloud3" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `65%`,
                        animationDuration: '6s'
                    }}><GameObjects objectCss="Cloud5" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `85%`,
                        animationDuration: '9s'
                    }}><GameObjects objectCss="Cloud7" /></div>
                    <div className="position-absolute CloudeMove user-select-none" style={{
                        top: `30%`,
                        animationDuration: '12s'
                    }}><GameObjects objectCss="Cloud3" /></div>

                    <div className="position-absolute " style={{
                        top: `20%`,
                        left: `5%`,
                    }}><GameObjects objectCss="GirlOnPincelDefault" status={this.state.currentStatus} FinishMove={this.FinishMove} /></div>

                    <div className="position-absolute " style={{
                        bottom: `20% `,
                        left: `30%`,
                        animationDuration: '30s'
                    }}><GameObjects objectCss="BoyOnPincelDefault" status={this.state.currentStatus} FinishMove={this.FinishMove} /></div>

                    <div className="position-relative col-sm-12 col-md-7 col-lg-5 col-xl-4" style={{
                        backgroundColor: '#fff',
                        top: '0',
                        right: '0',
                        minHeight: '100vh'
                    }}>
                        <div className="position-absolute" style={{
                            backgroundColor: '#fff',
                            width: '80%',
                            top: '0',
                            left: '0',
                            borderRadius: '15vh',
                            minHeight: '110vh',
                            marginTop: '-5vh',
                            marginLeft: '-5vh',
                            transform: 'rotate(10deg)',
                            transformOrigin: 'top left',
                            boxShadow: '-40px 0px 0px 0px #ffffff9c'
                        }}><div className="position-absolute" style={{
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0',
                            borderRadius: '15vh',
                            boxShadow: '-20px 0px 0px 0px #ffffffc0'
                        }}></div>
                        </div>

                        <div className="position-relative w-100 h-100 pt-4 align-items-center wow fadeInLeft animated" data-wow-delay="200ms" data-wow-duration="2000ms">

                            <div className="logo pb-4 text-center " >
                                <Link to="/">
                                    <LazyLoadImage src="/assets/images/logo/login-logo.png" alt={`${t('alarabeyya')}`} />
                                </Link>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="section-header mb-4">
                                        <h4 className="title font-weight-bold text-secondary" > {t('Pages.Login.subTitle')} </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mx-3 ">
                                <div className="col-lg-12">
                                    <div className="section-header mb-3">
                                        <h5 className="title font-weight-bold"> {t('Pages.Login.UserAndPassword')} </h5>
                                    </div>

                                    <form method="POST" className="contact-form mb-2 col-12 mr-3" id="Login" onSubmit={this.onSubmit} autoComplete="new-password">

                                        <div className="form-group w-100 mb-3">
                                            <div>
                                                <Field
                                                    ref={Username => this.Username = Username}
                                                    name="Username"
                                                    component={this.renderField}
                                                    type="text"
                                                    required={true}
                                                    placeholder={t('Pages.Register.Username.Placeholder')}
                                                    title={t('Pages.Register.Username.title')}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group w-100 mb-3 ">
                                            <div className="search-form position-relative">
                                                <Field
                                                    ref={Password => this.Password = Password}
                                                    name="Password"
                                                    component={this.renderField}
                                                    type={this.state.showPassword ? "text" : "password"}
                                                    required={true}
                                                    placeholder={t('Pages.Register.Password.Placeholder')}
                                                    title={t('Pages.Register.Password.title')}
                                                    className="form-control w-100"
                                                />
                                                <div className="d-flex justify-content-center align-items-center cursor-pointer" title={this.state.showPassword ? t('Pages.Register.Password.hide') : t('Pages.Register.Password.show')} style={{
                                                    left: 0,
                                                    top: 0,
                                                    height: '57px',
                                                    width: '50px',
                                                    background: 'transparent',
                                                    border: 'none',
                                                    position: 'absolute'
                                                }} onClick={() => this.showPassword()}>
                                                    <i className={`fas ${this.state.showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group w-100 mb-3">
                                            <div className="d-flex justify-content-between flex-wrap pt-sm-2">

                                                <div className="custom-control custom-checkbox mr-sm-4 checkbox-info">
                                                    <Field
                                                        ref={RememberMe => this.RememberMe = RememberMe}
                                                        type='checkbox'
                                                        className="custom-control-input"
                                                        component={this.renderField}
                                                        title={t('Pages.Login.RememberMe.title')}
                                                        name="RememberMe"
                                                        id="RememberMe" />
                                                    <label className="custom-control-label text-dark font pt-1" onClick={this.setRememberMe} htmlFor="RememberMe">
                                                        <i className="icon fad fa-bookmark text-info"></i>
                                                        {t('Pages.Login.RememberMe.title')}
                                                    </label>
                                                </div>

                                                <Link to="/ForgetPassword" className='text-dark font-size-h5 pt-1 cursor-pointer' >
                                                    {t('Pages.Login.ForgetPassword')}
                                                </Link>
                                            </div>

                                        </div>
                                        <div className="form-group w-100 mb-3 justify-content-center d-flex">
                                            <button className="custom-button mt-1 col-sm-12 col-md-12 col-lg-6" >
                                                <span className="px-0">
                                                    <i className="mx-1 fal fa-sign-in-alt" ></i> {t('Pages.Login.Button')}
                                                </span>
                                            </button>
                                        </div>
                                    </form>

                                    <div className="w-50 my-3 border-top border-secondary mx-auto " style={{ borderWidth: '2px' }} ></div>

                                    <h5 className=" mt-4 font-weight-bold text-center">{t('Pages.Login.socailLogin')}</h5>

                                    <ul className="social-icons justify-content-center my-2">
                                        <li>
                                            <Link to="#" href="#" className="facebook" title={t('Pages.Login.LoginByFacebook')} onClick={() => this.fbLogin()}>
                                                <i className="mx-1 fab fa-facebook-f" ></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <GoogleLogin
                                                clientId="1016091749954-kmt1u889nsnbppu29ekiduv7g3vi7oc2.apps.googleusercontent.com"
                                                buttonText={t('Pages.Login.LoginByGoogle')}
                                                onSuccess={this.googleLogin}
                                                cookiePolicy={'single_host_origin'}
                                                render={
                                                    renderProps =>
                                                        <Link to="#" className="instagram" title={t('Pages.Login.LoginByGoogle')} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                            <i className="mx-1 fab fa-google" ></i>
                                                        </Link>

                                                }
                                            />
                                        </li>
                                    </ul>
                                    <p className="text-justify mx-3 mt-3 ">
                                        <span className="text-danger font-weight-bold">
                                            {t('Pages.Login.socailLoginDesc_1')}
                                        </span>
                                        <br />
                                        {t('Pages.Login.socailLoginDesc')}
                                    </p>

                                    <div className="col-12 text-center" style={{
                                        fontSize: "20px"
                                    }}>
                                        <div className="account-bottom">
                                            <span className="or"><span>{t('Pages.Login.or')}</span></span>
                                            <span className="d-block cate font-weight-bold">  {t('Pages.Login.have_not_account')} <Link to="/Register">{t('Pages.Login.Register')}</Link></span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </section >
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.Password) {
        errors.Password = 'Pages.Register.Password.error';
    }

    if (!values.Username) {
        errors.Username = 'Pages.Register.Username.error';
    }

    return errors;
}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};

const formLogin = reduxForm({
    form: 'Login',
    validate
});
export default connect(mapStateToProps, { userLogin })(withTranslation('common')(formLogin(Login)));