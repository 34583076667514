const LessonQuestionsReducers = (state = [], action) => {
    switch (action.type) {
        case "LESSON_QUESTIONS_LOADING":
            return {
                Questions: [],
                LessonId: null
            };
        case "LESSON_QUESTIONS_INITIAL":
            return {
                Questions: action.Questions,
                LessonId: action.LessonId
            };

        default:
            return state;
    }
}

export default LessonQuestionsReducers;