import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import UserSubMenu from './UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import configData from "../../Config/avatars.json";
import api from '../../../api/api';
import history from '../../../history';
import { userInitial } from '../../actions/';
import Garden from "../../Admin/Question/View/Background/Garden";

class ProfilePicture extends React.Component {

    state = { initialize: false, errorList: [], Sex: "female", picture: [], Photo: "/assets/images/teacher/teacher-single.jpg", currentStatus: "" }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        window.scrollToPosition("main-profile-picture-section");
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.user && Object.keys(props.user).length !== 0 && props.user.user) {
            let data = configData.teachers;
            if (props.user.user.IsTeacher !== true) {
                data = configData.studens;
            }

            state.Photo = props.user.user.Photo;
            setTimeout(() => {
                props.change('URl', state.Photo);
            }, 500);
            const sex = props.user.user.Sex === "female" ? "female" : "male";
            state.picture = data.sort((a, b) => a.icon > b.icon ? 1 : -1).filter(p => p.type === sex).map(item => {
                return {
                    type: item.type,
                    value: process.env.PUBLIC_URL + item.icon,
                    label: <div className="d-flex justify-between align-items-center"><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} style={{ width: '75px' }} />  {item.label}</div>,
                };
            });

            state.initialize = true;
        }
        return state;
    }

    ChangePicture = candidate => {
        if (candidate && candidate.value) {
            this.setURl(process.env.PUBLIC_URL + candidate.value);
        }
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    setURl = Photo => {
        this.props.change('URl', Photo);
        this.setState({
            Photo
        });
    }

    changePhoto = Photo => {
        this.setURl(Photo.target.value);
    }

    validate = () => {
        const errors = {};

        if (!this.URl.ref.current.getValue()) {
            errors.URl = 'Pages.ChangePicture.URl.error';
        }
        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });


        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let postData = {
                URl: this.URl.ref.current.getValue()
            };

            this.setState({ errorList: [] });

            api.put(`/UserChangePhoto/`,
                postData
            ).then(response => {
                if (response.data.code === "success") {

                    this.props.userInitial(response.data.data);

                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ChangePicture.success_toast.Title')}</div> <div className="h6">{t('Pages.ChangePicture.success_toast.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    history.push("/Profile");
                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
        e.preventDefault();
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { submitting, t, user } = this.props;

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.ChangePicture.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top oh" id="main-profile-picture-section" style={{
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>
                    <Garden currentStatus={this.state.currentStatus} FinishMove={this.FinishMove} />

                    <div className="container">


                        <div className="row mt-30 mb-30-none">
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-category">
                                    <div className="widget-header d-flex align-items-center justify-content-between">
                                        <h5 className="title">
                                            <i className="fal fa-image "></i> {t('Pages.ChangePicture.title')}
                                        </h5>
                                    </div>
                                    <div className="widget-body">
                                        <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                                            <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                                                <h4 className="alert-heading">{t('ErrorsList')}</h4>
                                                <div className="border-bottom border-white opacity-20"></div>
                                                <div className="alert-text">
                                                    <ul className="list-unstyled mt-4">
                                                        <li>
                                                            <ul>
                                                                {this.state.errorList}
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className='text-dark font-size-h5'>{t('Pages.ChangePicture.Select.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                                <div>
                                                    <Select
                                                        name="picture"
                                                        placeholder={t('Pages.ChangePicture.Select.Placeholder')}
                                                        isRtl={true}
                                                        isSearchable={true}
                                                        ref={picture => this.picture = picture}
                                                        options={this.state.picture}
                                                        onChange={this.ChangePicture}
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#92278f',
                                                                primary: '#92278f',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className='text-dark font-size-h5'>{t('Pages.ChangePicture.URl.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                                <div>
                                                    <Field
                                                        ref={URl => this.URl = URl}
                                                        name="URl"
                                                        component={this.renderField}
                                                        type="text"
                                                        onChange={this.changePhoto}
                                                        required={true}
                                                        placeholder={t('Pages.ChangePicture.URl.Placeholder')}
                                                        title={t('Pages.ChangePicture.URl.title')}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                            <div className="teacher-single-item w-100 d-flex flex-wrap justify-content-evenly align-items-center">
                                                <div className="teacher-single-thumb d-flex align-items-center justify-content-center mx-auto">
                                                    <LazyLoadImage src={`${this.state.Photo}`} alt={`${t('Pages.ChangePicture.photo')}`} />
                                                </div>
                                            </div>

                                            <div className="form-group w-100 text-center">
                                                <button className="custom-button" disabled={submitting} ><span> <i className="icon fal fa-save"></i> {t('Pages.ChangePicture.Send')}</span></button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.URL) {
        errors.URL = 'Pages.ChangePicture.URL.error';
    }

    return errors;
}


const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const ProfilePictureFrom = reduxForm({
    form: 'ProfilePictureFrom',
    validate
})(ProfilePicture);

export default connect(mapStateToProps, { userInitial })(withTranslation('common')(ProfilePictureFrom));