import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import api from '../../../api/api';
import ShowQuestionModal from "../../Home/Tracks/ExamView/ShowQuestionModal";
import QuestionView from "../../Admin/Question/View/QuestionView";

class ModalQuestionResult extends React.Component {

    state = { questions: [], Solves: [], showQuestionResult: false, showQuestionTrueResult: false };

    componentDidMount() {
        const url = this.props.isTeacher ? "StudentMissionResults" : "MyMissionResults";
        api.get(`/${url}/${this.props.Id}`).then(response => {
            this.setState({
                questions: response.data.data.Questions,
                Solves: response.data.data.Solves
            });
        });
    }

    handleClose = () => {
        this.setState({
            showQuestionResult: false,
            showQuestionTrueResult: false
        });
    }

    showQuestionTrueResult = id => {
        this.setState({
            showQuestionTrueResult: true,
            id
        });
    }

    showQuestionResult = id => {
        this.setState({
            showQuestionResult: true,
            id
        });
    }
    renderQuestionView = () => {
        const question = this.state.questions.filter(p => p.Id === this.state.id)[0];
        if (question) {
            const solve = this.state.Solves.filter(p => p.Id === question.Id)[0];
            return <QuestionView
                question={question}
                answers={question.Answers}
                key={question.Id}
                index={1}
                ViewGameResult={this.props.ViewGameResult}
                showResult={this.props.ViewGameResult ? false : true}
                onEdit={false}
                solveButton={false}
                isExamQuestion={true}
                examAnswers={[solve ? solve.answers : ""]}
                options={solve ? {
                    Mark: solve.Mark,
                    Score: solve.Score,
                    Star: solve.Star,
                    Time: solve.Time,
                    IsPassed: solve.IsPassed,
                    IsTimesUp: solve.IsTimesUp,
                    TryNumber: solve.TryNumber
                } : ""} />
        }
        return "";
    }

    getOption() {
        if (this.props.ViewGameResult) {
            const question = this.state.questions.filter(p => p.Id === this.state.id)[0];
            if (question) {
                const solve = this.state.Solves.filter(p => p.Id === question.Id)[0];
                if (solve) {
                    return {
                        Mark: solve.Mark,
                        Score: solve.Score,
                        Star: solve.Star,
                        Time: solve.Time,
                        IsPassed: solve.IsPassed,
                        IsTimesUp: solve.IsTimesUp,
                        TryNumber: solve.TryNumber
                    };
                }
            }
        }
        return {};
    }

    renderQuestionTrueResult = () => {
        const question = this.state.questions.filter(p => p.Id === this.state.id)[0];
        if (question) {
            return <QuestionView
                question={question}
                key={question.Id}
                index={1}
                ViewGameResult={this.props.ViewGameResult}
                showResult={false}
                onEdit={true}
                solveButton={false} />
        }
        return "";
    }

    render() {
        const { t, studentName, ViewGameResult } = this.props;
        return <>
            {
                this.state.showQuestionResult ?
                    <ShowQuestionModal renderQuestionView={this.renderQuestionView} options={this.getOption()} showResult={this.props.ViewGameResult ? false : true} handleClose={this.handleClose} />
                    : ""
            }
            {
                this.state.showQuestionTrueResult ?
                    <ShowQuestionModal renderQuestionView={this.renderQuestionTrueResult} showResult={true} handleClose={this.handleClose} />
                    : ""
            }
            <Modal
                centered
                key="ModalQuestionResult"
                size="xl"
                show={true}
                onHide={() => this.props.handleClose()}
                backdrop="static"
                keyboard={false}
                enforceFocus={false} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="icon fal fa-poll"></i>
                        {t('Pages.Missions.studentResult')}
                        {
                            studentName ?
                                <span className="mx-1">
                                    (
                                    <span className="text-info mx-1">
                                        {studentName}
                                    </span>
                                    )
                                </span>
                                : ""
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.questions && this.state.questions.length > 0 ?
                            <div className="widget widget-category w-100">
                                <div className="widget-body">
                                    <table className="table table-striped table-hover table-bordered">
                                        <thead>
                                            <tr className="text-center">
                                                <th>
                                                    {t('Pages.Missions.table.question')}
                                                </th>
                                                <th>
                                                    {t('Pages.Missions.table.mark')}
                                                </th>
                                                <th>
                                                    {t('Pages.Missions.table.action')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.questions.sort((a, b) => a.Sort - b.Sort).map(question => {
                                                    const solve = this.state.Solves.filter(p => p.Id === question.Id)[0];
                                                    return <tr key={`questionId-${question.Id}`}>
                                                        <td>
                                                            <div dangerouslySetInnerHTML={{ __html: question.Name }}></div>
                                                        </td>
                                                        <td>
                                                            {
                                                                solve ?
                                                                    <div className="font-weight-bold h4 d-flex justify-content-center" >
                                                                        <span className={`${(solve.Mark / question.Mark) >= 0.5 ?
                                                                            solve.Mark === question.Mark ?
                                                                                "text-success" :
                                                                                "text-warning" :
                                                                            "text-danger"} `}>
                                                                            {solve.Mark}
                                                                        </span>
                                                                        /
                                                                        <span>
                                                                            {question.Mark}
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    t('Pages.Missions.table.NoAnswer')
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-evenly">
                                                                <button onClick={() => this.showQuestionResult(question.Id)} className={`btn w-auto my-1 mx-2 btn-sm btn-primary ${solve ? "" : "invisible"}`} title={t('Pages.Missions.table.showQuestionResult')}>
                                                                    <i className="fas fa-eye"></i>
                                                                </button>
                                                                {
                                                                    ViewGameResult ? "" :
                                                                        <button onClick={() => this.showQuestionTrueResult(question.Id)} className={`btn w-auto my-1 btn-sm btn-success ${solve && solve.Mark === question.Mark ? "invisible" : ""}`} title={t('Pages.Missions.table.showQuestionTrueResult')}>
                                                                            <i className="fas fa-check"></i>
                                                                        </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <div className="product-list-item" >
                                <div className="product-content w-100 text-center ">
                                    <div className="display-1 text-danger">
                                        <i className="fad fa-sync-alt fa-spin"></i>
                                    </div>
                                    <h4 className="display-3 subtitle">
                                        {t("Pages.Missions.Loading")}
                                    </h4>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </>

    }
}


export default withTranslation('common')(ModalQuestionResult);