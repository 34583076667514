import React from "react";
import { withTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';

import { fetchLessonQuestions } from "../actions";
import QuestionView from "../../Admin/Question/View/QuestionView";
import ShowQuestionModal from "./ExamView/ShowQuestionModal";

class LessonQuestionsList extends React.Component {

    state = { isFirstQuestions: true, isFirst: true, showQuestionModal: false, question: null, index: 1, showInfoPageModal: false, SelectedQuestions: [] };

    static getDerivedStateFromProps(props, state) {

        if (props.lessonQuestions?.Questions && props.lessonQuestions?.Questions.length > 0 && state.isFirstQuestions) {
            state.isFirstQuestions = false;
            state.SelectedQuestions = props.lessonQuestions?.Questions.map(item => item.Id);
            props.setSelect(state.SelectedQuestions);
        }

        if (props.lessonQuestions?.Questions && props.lessonQuestions?.Questions.length > 0 && props.SelectedQuestions && props.SelectedQuestions.length > 0 && state.isFirst) {
            state.isFirst = false;
            state.SelectedQuestions = props.SelectedQuestions;
            props.setSelect(state.SelectedQuestions);
        }
        return state;
    }

    componentDidMount() {
        if (this.props.lessonQuestions.LessonId !== this.props.LessonId) {
            this.props.fetchLessonQuestions(this.props.LessonId);
        }
    }

    showQuestionModal = (question, index) => {
        this.setState({
            showQuestionModal: true,
            question,
            index
        });
    }

    showInfoPageModal = () => {
        this.setState({
            showInfoPageModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showQuestionModal: false,
            showInfoPageModal: false,
            question: null,
            index: 1
        });
    }

    renderInfoPageQuestionView = () => {
        return this.props.lessonQuestions.Questions.filter(p => p.Position === true).map((question, index) => {
            return <QuestionView
                question={question}
                answers={question.Answers}
                key={question.Id}
                index={index + 1}
                showResult={false}
                onEdit={true}
                solveButton={false} />
        })
    }

    renderQuestionView = () => {
        return <QuestionView
            question={this.state.question}
            answers={this.state.question.Answers}
            key={this.state.question.Id}
            index={this.state.index}
            showResult={false}
            onEdit={true}
            solveButton={false} />
    }

    SelectQuestion = Id => {
        let SelectedQuestions = this.state.SelectedQuestions;
        SelectedQuestions.push(Id);
        this.setState({
            SelectedQuestions
        });
        this.props.setSelect(SelectedQuestions);
    }
    DeselectQuestion = Id => {
        let SelectedQuestions = this.state.SelectedQuestions;
        SelectedQuestions = SelectedQuestions.filter(function (ele) {
            return ele !== Id;
        });
        this.setState({
            SelectedQuestions
        });
        this.props.setSelect(SelectedQuestions);
    }

    setSelectCustomeInfoQuestions = isChecked => {
        let SelectedQuestions = this.state.SelectedQuestions;
        const questions = this.props.lessonQuestions.Questions.filter(p => p.Position === true);
        questions.forEach(question => {
            if (isChecked) {
                SelectedQuestions.push(question.Id);
            } else {
                SelectedQuestions = SelectedQuestions.filter(function (ele) {
                    return ele !== question.Id;
                });
            }
        });
        this.setState({
            SelectedQuestions
        });
        this.props.setSelect(SelectedQuestions);
    }

    renderQuestionsItem = () => {
        const { t } = this.props;

        if (this.props.lessonQuestions && this.props.lessonQuestions.LessonId && this.props.lessonQuestions.Questions) {
            const rightQuestions = this.props.lessonQuestions.Questions.filter(p => p.Position === true);
            return <>
                {
                    rightQuestions.length > 0 ?
                        <div className="custom-control custom-checkbox mr-sm-4 checkbox-primary" key={`select_questions_${this.props.lessonQuestions.LessonId}`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                defaultChecked={rightQuestions.some(p => this.state.SelectedQuestions.includes(p.Id))}
                                onChange={e => this.setSelectCustomeInfoQuestions(e.target.checked)}
                                name={`SelectCustomeInfoQuestions_${this.props.lessonQuestions.LessonId}`}
                                id={`SelectCustomeInfoQuestions_${this.props.lessonQuestions.LessonId}`} />
                            <label className="custom-control-label w-100" htmlFor={`SelectCustomeInfoQuestions_${this.props.lessonQuestions.LessonId}`} >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        {t('Pages.Lessons.info')}
                                    </div>
                                    <div className="d-flex">
                                        <button type="button" className="btn btn-sm m-1 btn-info" style={{
                                            height: '35px',
                                            width: '35px'
                                        }} onClick={() => this.showInfoPageModal()}
                                            title={t('Pages.Lessons.Questions.forms.View')}>
                                            <i className=" fal fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </label>
                        </div> : ""
                }
                {
                    this.props.lessonQuestions.Questions.filter(p => p.Position === false).map((question, index) => {
                        return <div className="custom-control custom-checkbox mr-sm-4 checkbox-dark" key={`select_questions_${question.Id}`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.SelectedQuestions.includes(question.Id)}
                                onChange={() => this.state.SelectedQuestions.includes(question.Id) ? this.DeselectQuestion(question.Id) : this.SelectQuestion(question.Id)}
                                name={`SelectCustomeQuestions_${question.Id}`}
                                id={`SelectCustomeQuestions_${question.Id}`} />
                            <label className="custom-control-label w-100" htmlFor={`SelectCustomeQuestions_${question.Id}`} >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <span className="text-danger h4 mx-1 my-0">
                                            {index + 1}-
                                        </span>
                                        {
                                            question.Type.Id === 11 ? t('Pages.Lessons.Questions.type.Text') : <div dangerouslySetInnerHTML={{ __html: question.Name }}></div>
                                        }

                                    </div>
                                    <div className="d-flex">
                                        <button type="button" className="btn btn-sm m-1 btn-info" style={{
                                            height: '35px',
                                            width: '35px'
                                        }} onClick={() => this.showQuestionModal(question, index + 1)}
                                            title={t('Pages.Lessons.Questions.forms.View')}>
                                            <i className=" fal fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </label>
                        </div>
                    })
                }
            </>;
        }
        return <div className="product-list-item" >
            <div className="product-content w-100 text-center ">
                <div className="display-1 text-danger">
                    <i className="fad fa-sync-alt fa-spin"></i>
                </div>
                <h4 className="display-3 subtitle">
                    {t("Loading")}
                </h4>
            </div>
        </div>
    }

    render() {
        const { t } = this.props;
        return <>
            {
                this.state.showQuestionModal ?
                    <ShowQuestionModal renderQuestionView={this.renderQuestionView} showResult={true} handleClose={this.handleClose} />
                    : ""
            }
            {
                this.state.showInfoPageModal ?
                    <Modal
                        key="ModalQuestionView"
                        centered
                        size="xl"
                        show={true}
                        onHide={() => this.handleClose()}
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="icon fal fa-info"></i>
                                {t('Pages.Lessons.info')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="w-100 position-relative">
                                <div className="overlayer-question"></div>
                                {this.renderInfoPageQuestionView()}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-12">
                                <button className="btn btn-danger" onClick={() => this.handleClose()}>
                                    <i className="icon fas fa-times"></i>
                                    {t('Close')}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    : ""
            }
            {this.renderQuestionsItem()}
        </>
    }

}

const mapStateToProps = state => {
    return {
        lessonQuestions: state.lessonQuestions
    };
};

export default connect(mapStateToProps, { fetchLessonQuestions })(withTranslation('common')(LessonQuestionsList));