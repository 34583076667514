import React from "react";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import api from "../../../api/api";

class ChangeLoginInfoForm extends React.Component {

    state = { errorList: [] };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    componentDidMount() {
        this.props.initialize({
            Email: this.props.user.Email,
            Username: this.props.user.Username
        });
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};

        if (!this.Username.ref.current.getValue()) {
            errors.Username = 'Pages.Register.Username.error';
        }

        if (!this.Email.ref.current.getValue()) {
            errors.Email = 'Pages.Register.Email.error';
        } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(this.Email.ref.current.getValue())) {
            errors.Email = 'Pages.Register.Email.error_notmatch';
        }

        return errors;
    }

    changeEmail = Email => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
            this.checkIfExist({
                Email: Email.trim(),
                UserId: this.props.user.Id
            }, "Email");
        }
    }

    changeUsername = Username => {
        if (Username) {
            this.checkIfExist({
                Username: Username.trim(),
                UserId: this.props.user.Id
            }, "Username");
        }
    }

    checkIfExist = (postData, type) => {
        const { t } = this.props;
        let ifExist = false;
        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/Register/`,
            postData
        ).then(response => {
            if (response.data === "1") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.' + type + '.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.' + type + '.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                ifExist = true;
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.Register.' + type + '.info_toast.Title')}</div> <div className="h6">{t('Pages.Register.' + type + '.info_toast.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        return ifExist;
    }

    onSubmit = e => {
        const { t } = this.props;

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            this._toastId.current = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(this._toastId.current, {
                render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let postData = {
                Email: this.Email.ref.current.getValue(),
                Username: this.Username.ref.current.getValue()
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData);
        }
        e.preventDefault();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-group w-100">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Username.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Username => this.Username = Username}
                                name="Username"
                                component={this.renderField}
                                type="text"
                                required={true}
                                onBlur={e => this.changeUsername(e.target.value)}
                                placeholder={t('Pages.Register.Username.Placeholder')}
                                title={t('Pages.Register.Username.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group w-100">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Email.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Email => this.Email = Email}
                                name="Email"
                                component={this.renderField}
                                type="text"
                                required={true}
                                onBlur={e => this.changeEmail(e.target.value)}
                                placeholder={t('Pages.Register.Email.Placeholder')}
                                title={t('Pages.Register.Email.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group w-100">
                        <button className="custom-button mt-1 w-100"><span> <i className="fal fa-save"></i> {t('Pages.ChangePassword.submit')}</span></button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.Email) {
        errors.Email = 'Pages.Register.Email.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.Email)) {
        errors.Email = 'Pages.Register.Email.error_notmatch';
    }

    if (!values.Username) {
        errors.Username = 'Pages.Register.Username.error';
    }

    return errors;
}

const formChangeLoginInfo = reduxForm({
    form: 'ChangeLoginInfo',
    validate
});

export default withTranslation('common')(formChangeLoginInfo(ChangeLoginInfoForm));
