import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";

import '../css/lesson.css';
import View from '../../../Admin/Lessons/View';
import { fetchLesson } from '../../../Admin/Lessons/actions/';
import Loading from "../../../Loading/";
import { startExam, saveExamAnswer } from '../../../Admin/Exams/actions';
import ShowQuestionModal from "../ExamView/ShowQuestionModal";
import QuestionView from '../../../Admin/Question/View/QuestionView';
import api from "../../../../api/api";
import history from '../../../../history';

class LessonView extends React.Component {

    state = {
        showSound: false,
        showEndPage: false,
        showResult: false,
        showEnd: false,
        showQuestionModal: false,
        showClock: false,
        showTimesUp: false,
        isTimeFinish: false,
        currentLeft: 0, currentRight: 0, FontSize: 18,
        questionSeparated: {
            BorderStyle: "dashed",
            BorderColor: 'rgba(68, 68, 68, 1)',
            BorderWidth: 3,
            width: 100
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            FontSize: 18,
            showSound: false,
            showText: false,
            styleQuestion: '',
            styleBind: '',
            styleText: '',
            currentLeft: 0,
            currentRight: 0,
            first: true,

            Id: 0,
            Name: '',
            Bio: '',
            Sort: 0,
            Sound: '',
            Url: '',
            Icon: '',
            Background: '',
            Questions: [],
            IsActive: true,
            IsPublic: false,
            IndexId: 0,
            questionSeparated: {
                BorderStyle: "dashed",
                BorderColor: 'rgba(68, 68, 68, 1)',
                BorderWidth: 3,
                width: 100
            }
        };
        this._toastId = new React.createRef();
        this.yourTime = 0;
        this.props.startExam();
    }

    showSound = showSound => {
        this.setState({ showSound });
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            Id: id
        });
        if (id !== '0') {
            this.props.fetchLesson(id, true);
        }

        setInterval(() => {
            this.yourTime += 1;
        }, 1000);
    }

    static getDerivedStateFromProps(props, state) {
        const { id } = props.match.params;
        if (props.lessons && state.Id && state.Id !== '0') {
            if (props.lessons && props.lessons.code && props.lessons.code === "no_permission") {
                history.push(`/NoPermissions/`);
            }
            if (!state.showPagesModal && !state.showLessonInfoModal && props.lessons.current) {
                let lesson = props.lessons.current;

                let FontSize = 18;
                let questionSeparated = state.questionSeparated;
                if (lesson.CustomStyle) {
                    if (lesson.CustomStyle.FontSize) {
                        FontSize = lesson.CustomStyle.FontSize;
                    }
                    if (lesson.CustomStyle.questionSeparated) {
                        questionSeparated = lesson.CustomStyle.questionSeparated;
                    }
                }

                let LessonPage = [];
                if (lesson.LessonPage) {
                    let toRemove = props.lessons.PageToRemove;
                    lesson.LessonPage.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                        if (!toRemove || !toRemove.includes(item.Id)) {
                            LessonPage = [...LessonPage, item];
                        }
                    });
                }

                let obj = {
                    Sort: lesson.Sort,
                    Id: lesson.Id,
                    Name: lesson.Name,
                    Bio: lesson.Bio,
                    Background: lesson.Background,
                    Sound: lesson.Sound,
                    IsActive: lesson.IsActive,
                    IsPublic: lesson.IsPublic,
                    IndexId: lesson.Index ? lesson.Index.Id : state.IndexId,
                    Icon: lesson.Icon,
                    Url: lesson.Url,
                    LessonPage: LessonPage,
                    Questions: lesson.Questions ? lesson.Questions.sort((a, b) => a.Sort - b.Sort) : [],
                    FontSize,
                    questionSeparated
                    //        first: false,
                };

                if (obj.Questions) {
                    var hasRight = obj.Questions.some(p => p.Position === true);
                    var hasLeft = obj.Questions.some(p => p.Position === false);
                    if (hasRight && !hasLeft) {
                        obj = {
                            ...obj,
                            styleQuestion: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    width: '100%'
                                }
                            }
                        };
                    } else if (!hasRight && hasLeft) {
                        obj = {
                            ...obj,
                            styleQuestion: {
                                style: {
                                    width: '100%'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    display: 'none'
                                }
                            }
                        };
                    } else {
                        obj = {
                            ...obj,
                            styleQuestion: {},
                            styleBind: {},
                            styleText: {}
                        };
                    }
                }

                return obj;
            } else if (props.lessons.current) {
                let lesson = props.lessons.current;
                state.Questions = lesson.Questions ? lesson.Questions.sort((a, b) => a.Sort - b.Sort) : [];

                let LessonPage = [];
                if (lesson.LessonPage) {
                    let toRemove = props.lessons.PageToRemove;
                    lesson.LessonPage.sort((a, b) => a.Sort - b.Sort).forEach(item => {
                        if (!toRemove || !toRemove.includes(item.Id)) {
                            LessonPage = [...LessonPage, item];
                        }
                    });
                }
                state.LessonPage = LessonPage;

                if (lesson.CustomStyle) {
                    if (lesson.CustomStyle.questionSeparated) {
                        state.questionSeparated = lesson.CustomStyle.questionSeparated;
                    }
                }

                if (lesson.Questions && lesson.Questions.length !== 0) {
                    var hasRights = lesson.Questions.some(p => p.Position === true);
                    var hasLefts = lesson.Questions.some(p => p.Position === false);
                    if (hasRights && !hasLefts) {
                        state = {
                            ...state,
                            styleQuestion: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    width: '100%'
                                }
                            }
                        };
                    } else if (!hasRights && hasLefts) {
                        state = {
                            ...state,
                            styleQuestion: {
                                style: {
                                    width: '100%'
                                }
                            },
                            styleBind: {
                                style: {
                                    display: 'none'
                                }
                            },
                            styleText: {
                                style: {
                                    display: 'none'
                                }
                            }
                        };
                    } else {
                        state = {
                            ...state,
                            styleQuestion: {},
                            styleBind: {},
                            styleText: {}
                        };
                    }
                }

            }
        }
        state.Id = id;
        return state;
    }

    sumQuestion() {
        let result = { count: 0, mark: 0, level: 0 };
        if (this.state.Questions) {
            this.state.Questions.forEach(function (item) {
                if (item.Type.Id !== 11) {
                    result.mark += item.Mark;
                    result.count++;
                    result.level += item.Level.Id;
                }
            });
        }
        return result;
    }

    sumTotalQuestions() {
        let sum = 0;
        this.props.exam.marks.forEach(item => {
            sum += item.userMark;
        })
        return sum.toFixed(2);
    }

    showFinish = () => {
        if (!this.state.showEndPage) {
            var QuestionsCount = this.state.Questions.filter(p => p.Type.Id !== 11).length;
            var AnswerCount = this.props.exam.current.length;
            if (QuestionsCount !== AnswerCount) {
                const { t } = this.props;
                this._toastId.current = toast(t('Processing'), {
                    position: "bottom-left",
                    autoClose: false
                });
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5"> <i className="icon fal fa-engine-warning"></i> {t('Pages.Lessons.toast.Warning.Title')}</div>
                        <div className="h6">{t('Pages.Lessons.toast.Warning.Body')}</div>
                    </div>,
                    type: toast.TYPE.WARNING,
                    position: "bottom-left",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        this.setState({
            showEndPage: !this.state.showEndPage
        });
    }

    openQuestion = id => {
        const question = this.state.Questions.filter(p => p.Id === id)[0];
        if (question) {
            this.setState({
                showQuestionModal: true,
                question
            });
        }
    }

    renderQuestionItem(question, key) {
        const { t, exam } = this.props;
        let hasAnswer = false;
        if (exam.marks) {
            hasAnswer = exam.marks.some(p => p.Id === question.Id);
        }
        return (
            <div className="faq-item" key={`questionNumber-${key}`} onClick={() => this.openQuestion(question.Id)}>
                <div className="faq-title d-flex">
                    <span className="right-questionNumber">{key + 1}</span>
                    <h5 className="faq-item-questionTitle">  <div dangerouslySetInnerHTML={{ __html: question.Name }}></div></h5>
                    {
                        hasAnswer ? ""
                            :
                            <span className="mr-auto d-flex align-self-center" title={t('Pages.Exams.NoAnswer')}>
                                <i className="text-danger h5 mb-0 fas fa-engine-warning"></i>
                            </span>
                    }
                </div>
            </div>
        );
    }

    renderFinish = () => {
        const { t } = this.props;
        var hasRights = this.state.Questions.some(p => p.Position === true && p.Type.Id !== 11);
        var hasLefts = this.state.Questions.some(p => p.Position === false && p.Type.Id !== 11);
        return (
            <>
                <section className={`mark-panel ${this.state.showEndPage ? "show" : ""}`}  >
                    {
                        this.state.showEndPage ?
                            <>
                                <div className="cross" onClick={() => this.showFinish()} title={t('Pages.Exams.Close')}  >
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="container mt-30">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8">
                                            <div className="section-header">
                                                <h3 className="title">{this.state.Name}</h3>
                                                <span className="cate">{t('Pages.Exams.EndExamSubTitle')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mb--10">
                                        <div className={`col-lg-6 ${hasRights ? "" : "d-none"}`}>
                                            <div className={`section-header pt-3 ${hasLefts ? "" : "d-none"}`}>
                                                <h3 className="title">{t('Pages.Exams.RightSide')}</h3>
                                            </div>
                                            <div className="faq-wrapper">
                                                {
                                                    this.state.Questions.filter(p => p.Position === true && p.Type.Id !== 11).map((question, key) => {
                                                        return this.renderQuestionItem(question, key)
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className={`col-lg-6 ${hasLefts ? "" : "d-none"}`}>
                                            <div className={`section-header pt-3 ${hasRights ? "" : "d-none"}`}>
                                                <h3 className="title">{t('Pages.Exams.LeftSide')}</h3>
                                            </div>
                                            <div className="faq-wrapper">
                                                {
                                                    this.state.Questions.filter(p => p.Position === false && p.Type.Id !== 11).map((question, key) => {
                                                        return this.renderQuestionItem(question, key)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mt-3">
                                        <div className="d-flex justify-content-center w-100">
                                            <div onClick={() => this.showEnd()} className="theme-btn btn-style-two d-inline-flex mb-1">
                                                <span className="txt pl-2"> {t('Pages.Lessons.End')} </span>
                                                <div className="mouse-btn-down">
                                                    <div className="chevron"></div>
                                                    <div className="chevron"></div>
                                                    <div className="chevron"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : ""
                    }
                </section>
            </>
        );
    }

    showEnd = () => {
        this.setState({
            showEnd: true,
            showEndPage: false,
            yourTime: this.yourTime
        });

        const { exam, t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const seconds = Math.floor(this.yourTime % 60);
        const minutes = Math.floor((this.yourTime / 60) % 60);
        const hour = Math.floor(this.yourTime / 3600);

        api.post(`/UserLessonMark/${this.state.Id}`,
            {
                answers: exam.current,
                total: this.sumTotalQuestions(),
                CustomeStyle: {
                    Clock: {
                        seconds,
                        minutes,
                        hour
                    }
                }
            }
        ).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-shield-check"></i>
                        {t('Pages.Lessons.save_toast.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.Lessons.save_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        setTimeout(() => {
            window.scrollToTop();
        }, 200);
    }

    renderEnd = () => {
        if (this.state.showEnd) {
            const { t, user, lessons } = this.props;
            const examTotalMark = this.sumQuestion();
            const userMark = this.sumTotalQuestions();
            const markRate = Math.round(userMark / examTotalMark.mark * 100);
            let innerText = t("Pages.Lessons.FailedIsEnded");
            let textColor = "text-danger";
            let renderReset = true;
            if (markRate === 100) {
                renderReset = false;
                textColor = "text-success";
                innerText = t("Pages.Lessons.SuccessIsEnded");
                if (this.state.isTimeFinish === true) {
                    innerText = t("Pages.Lessons.SuccessButTimesUp");
                }
            } else if (markRate >= 50) {
                textColor = "text-warning";
                innerText = t("Pages.Lessons.SuccessIsEndedWithError");
                if (this.state.isTimeFinish === true) {
                    innerText = t("Pages.Lessons.SuccessButTimesUpWithError");
                }
            }
            const seconds = Math.floor(this.state.yourTime % 60);
            const minutes = Math.floor((this.state.yourTime / 60) % 60);
            const hour = Math.floor(this.state.yourTime / 3600);

            return (
                <>
                    <section className={`pos-rel padding-bottom pt-0`}>
                        {
                            markRate === 100 && this.state.src ?
                                <div className="position-fixed w-100 d-flex align-items-center justify-content-center" style={{
                                    pointerEvents: 'none',
                                    top: '0',
                                    right: '0',
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'hidden',
                                    flexShrink: '0'
                                }}>
                                    <Player
                                        autoplay
                                        loop
                                        src={this.state.src}
                                        style={{ height: '100%' }}
                                    ></Player>
                                </div>
                                : ""
                        }
                        <style dangerouslySetInnerHTML={{
                            __html: `html{background-position: 50% 50%;height: 100vh;background-size: cover;background-repeat: no-repeat;background-attachment: fixed;background-image: ${this.state.Background !== '' ? "url('" + this.state.Background + "?v=1')" : ""};}body{background: unset;}`
                        }}></style>
                        <div className="class-section padding-bottom pos-rel ">
                            <div className="container-lg">
                                <div className="post-item mb-30 mx-1">
                                    <div className="post-content position-relative oh p-4">
                                        <h2 className="title text-center pt-4 " >
                                            <Link to="#">
                                                {this.state.Name}
                                            </Link>
                                        </h2>
                                        <h4 className={`text-center pt-3 ${textColor}`}>
                                            {innerText}
                                        </h4>

                                        <div className="d-flex flex-wrap">

                                            <div className={`round-style`} title={t('Pages.Exams.Marks')} style={{
                                                flexDirection: 'row'
                                            }}>
                                                <div className={`${textColor}`}>{userMark}</div>
                                                <div className="mx-1">{t('Pages.Exams.From')}</div>
                                                <div>{examTotalMark.mark}</div>
                                                <i className={`px-1 fad fa-tally ${textColor}`}></i>
                                            </div>

                                            <div className={`circle-style ${textColor}`} title={t('Pages.Exams.MarkRate')}>
                                                {markRate}
                                                <i className="py-1 fad fa-percent"></i>
                                            </div>

                                            <div className={`round-style`} title={t('Pages.Exams.yourTime')} >
                                                {
                                                    hour > 0 ?
                                                        <>
                                                            <div title={t('Pages.Exams.Hour')} >{hour}</div>
                                                            <div>:</div>
                                                        </> : ""
                                                }
                                                <div title={t('Pages.Exams.Minute')}>{minutes < 10 ? "0" + minutes : minutes}</div>
                                                {
                                                    hour === 0 ?
                                                        <>
                                                            <div>:</div>
                                                            <div title={t('Pages.Exams.Second')}>{seconds < 10 ? "0" + seconds : seconds}</div>
                                                        </> : ""
                                                }
                                                <i className="icon fad fa-clock"></i>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-evenly banner-buttons pt-5" style={{
                                            justifyContent: "space-evenly"
                                        }}>
                                            {
                                                this.props.isMission === true ?
                                                    "" :
                                                    <>
                                                        {
                                                            renderReset ?
                                                                <button onClick={() => this.resetExam()} className="custom-button ">
                                                                    <span>  <i className="icon fas fa-repeat-alt"></i> {t("Pages.Exams.ResetExam")}</span>
                                                                </button>
                                                                : ""
                                                        }
                                                        {
                                                            !lessons.current.isRated && !this.state.hideRate && user && user.user && user.user.UserType && user.user.UserType.Id !== 5 ?
                                                                <button onClick={() => this.showRate()} className="custom-button ">
                                                                    <span>  <i className="icon fas fa-star"></i> {t("Pages.Lessons.tools.rate")}</span>
                                                                </button>
                                                                : ""
                                                        }
                                                    </>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="class-wrapper d-flex flex-wrap align-items-stretch mb-2">
                                    {
                                        this.state.Questions.filter(p => p.Position === true && p.Type.Id !== 11).map((question, key) => {
                                            return this.renderQuestionResult(question, key)
                                        })
                                    }
                                </div>
                                <div className="class-wrapper d-flex flex-wrap align-items-stretch">
                                    {
                                        this.state.Questions.filter(p => p.Position === false && p.Type.Id !== 11).map((question, key) => {
                                            return this.renderQuestionResult(question, key)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        }
        return "";
    }

    openQuestionResult = id => {
        const question = this.state.Questions.filter(p => p.Id === id)[0];
        if (question) {
            this.setState({
                showQuestionModal: true,
                showResult: true,
                question
            });
        }
    }

    resetExam = () => {
        window.location.reload(false);
    }

    renderQuestionResult = (question, key) => {
        const { t, exam } = this.props;
        let currentMark = 0;
        let currentMarkObject = exam.marks.filter(p => p.Id === question.Id)[0];
        if (currentMarkObject) {
            currentMark = currentMarkObject.userMark.toFixed(2);
            currentMark = parseFloat(currentMark);
        }
        let background = "#ecffe8";
        if (question.Mark === currentMark) {
            background = "#ecffe8";
        } else if (question.Mark / 2 > currentMark) {
            background = '#fff5fd';
        } else if (question.Mark !== currentMark) {
            background = '#fffee8';
        }

        return (
            <div className="class-inner " key={key}>
                <div className="event-item" style={{
                    background: background ? `${background}` : '#fff'
                }}>
                    <div className="event-content oh pb-2">
                        <div className="row row-col-2">
                            <div className="col p-0">
                                <div className="text-right text-dark h6 font-weight-bold"> <i className="icon fas fa-question text-primary"></i>
                                    <u>
                                        {t('Pages.Lessons.Questions.forms.Question')}  {key + 1}
                                    </u>
                                </div>
                            </div>
                            <div className="col p-0">
                                <div className="text-left title text-danger h6">
                                    <i className="icon fas fa-tally"></i> {t('Pages.Lessons.Questions.forms.QuestionMark.title')} : {question.Mark}
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div dangerouslySetInnerHTML={{ __html: question.Name }}></div>
                        </div>
                        <ul className="events-info">
                            <li>
                                <i className="icon fas fa-tally"></i> {t('Pages.Exams.Mark')} :
                                <span className={`h6 ${question.Mark === currentMark ? "text-success" : question.Mark / 2 > currentMark ? "text-danger" : "text-warning"} `}> {currentMark} </span>
                                <span className="h5"> {t('Pages.Exams.From')} </span>
                                <span className="h6"> {question.Mark} </span>
                            </li>
                            <li>
                                <i className="icon fas fa-water-rise"></i> {t('Pages.Lessons.Questions.forms.QuestionLevel.title')} :
                                <span className={`text-${question.Level.Id === 1 ? "success" : question.Level.Id === 2 ? "warning" : "danger"}`}>
                                    {question.Level.Name}
                                </span>
                            </li>
                        </ul>

                        <div className="my-1" style={{
                            borderBottom: '1px solid #e5e5e5',
                            marginRight: '-40px',
                            marginLeft: '-40px'
                        }}>
                        </div>
                        <div className="row">
                            <div className="col px-0 d-flex align-items-center " >
                                <div className="cursor-pointer" title={t('Pages.Exams.SeeResult')} onClick={() => this.openQuestionResult(question.Id)}>
                                    <i className="icon text-primary  fas fa-eye"></i>
                                    {t('Pages.Exams.SeeResult')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleClose = () => {
        setTimeout(() => {
            this.setState({
                showQuestionModal: false
            });
        }, 450);
    }

    saveExamAnswer = (id, typeId, answers, mark, userMark) => {
        this.props.saveExamAnswer(id, typeId, answers, mark, parseFloat(userMark));
    }

    renderQuestionView = () => {
        const question = this.state.question;
        const { exam } = this.props;
        if (this.state.question) {
            return <QuestionView
                question={question}
                answers={question.Answers}
                key={question.Id}
                index={1}
                indexType={1}
                showResult={true}
                onEdit={false}
                solveButton={false}
                isExamQuestion={false}
                saveExamAnswer={this.saveExamAnswer}
                examAnswers={exam.current}
                FontSize={this.state.FontSize} />
        }
        return "";
    }

    render() {
        const { t, lessons, exam } = this.props;
        if ((lessons.length === 0 || !lessons.current) && this.state.Id !== '0') {
            return <Loading></Loading>
        }
        return (
            <>
                {
                    this.state.showQuestionModal ?
                        <ShowQuestionModal
                            renderQuestionView={this.renderQuestionView}
                            showResult={true}
                            handleClose={this.handleClose} />
                        : ""
                }
                {
                    !this.state.showSound ?
                        <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% - 15px);overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;padding-bottom:5px !important;}` }}></style>
                        :
                        <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% - 15px);overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;}` }}></style>
                }
                {
                    this.state.showEndPage || this.state.showEnd ? "" :
                        <>
                            <View
                                showSound={this.showSound}
                                IndexId={this.state.IndexId}
                                key={this.state.Id}
                                Id={this.state.Id}
                                Name={this.state.Name}
                                isRated={lessons && lessons.current ? lessons.current.isRated : false}
                                Sound={this.state.Sound}
                                Url={this.state.Url}
                                Questions={this.state.Questions}
                                LessonPage={this.state.LessonPage}
                                questionSeparated={this.state.questionSeparated}
                                QuestionToRemove={[]}
                                FontSize={this.state.FontSize}
                                Background={this.state.Background}
                                isOnEdit={false}
                                examAnswers={exam.current}
                                saveExamAnswer={this.saveExamAnswer}
                            />
                            {
                                lessons && lessons.current && lessons.current.Questions && lessons.current.Questions.some(p => p.Type.Id !== 11) ?
                                    <div className="d-flex justify-content-center">
                                        <div onClick={() => this.showFinish()} className="theme-btn btn-style-two d-inline-flex mb-1">
                                            <span className="txt pl-2"> {t('Pages.Lessons.showEnd')} </span>
                                            <div className="mouse-btn-down">
                                                <div className="chevron"></div>
                                                <div className="chevron"></div>
                                                <div className="chevron"></div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }

                        </>
                }

                {this.renderFinish()}
                {this.renderEnd()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lessons: state.lessons,
        exam: state.exam
    };
};

export default connect(mapStateToProps, { fetchLesson, startExam, saveExamAnswer })(withTranslation('common')(LessonView));