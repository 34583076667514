import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Field, reduxForm } from 'redux-form'
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

import Footer from "../Footer/FooterBottom";
import api from '../../api/api';
import history from '../../history';
import TopOfMountain from "../Admin/Question/View/Background/TopOfMountain";

const TEST_SITE_KEY = `${process.env.REACT_APP_TEST_SITE_KEY}`;

class ForgetPassword extends React.Component {

    state = { captchaErorr: null, errorList: [], currentStatus: "" };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        window.scrollToPosition("main-forget-password-section");
    }

    handleChange = value => {
        this.setState({ value });
        if (value === null) {
            this.setState({ expired: "true" });
        } else {
            this.setState({ captchaErorr: '' });
        }
    };

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};

        if (!this.Email.ref.current.getValue()) {
            errors.Email = 'Pages.Register.Email.error';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.Email.ref.current.getValue())) {
            errors.Email = 'Pages.Register.Email.error_notmatch';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;
        const recaptchaValue = this._reCaptchaRef.current.getValue();

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if ((errors && errors.length !== 0 && Object.keys(errors).length !== 0) || !recaptchaValue) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ActivePage.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ActivePage.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                currentStatus: "Wrong"
            });
        } else {

            api.post(`/ForgetPassword/`, {
                ForgetPassword: this.Email.ref.current.getValue(),
                recaptchaValue
            }).then(response => {
                if (response.data !== "1") {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ForgetPassword.error_info_toast.Title')}</div> <div className="h6">{t('Pages.ForgetPassword.error_info_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        currentStatus: "Wrong"
                    });
                } else {

                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ForgetPassword.success_toast.Title')}</div> <div className="h6">{t('Pages.ForgetPassword.success_toast.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    this.setState({
                        currentStatus: "True"
                    }, () => {
                        setTimeout(() => {
                            history.push("/Login");
                        }, 1000);
                    });
                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    currentStatus: "Wrong"
                });
            });
        }
        e.preventDefault();
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { submitting, t, i18n } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.ForgetPassword.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>


                <section className="pos-rel call-overlay call-in-action oh" id="main-forget-password-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>

                    <TopOfMountain currentStatus={this.state.currentStatus} FinishMove={this.FinishMove} />

                    <div className="container padding-top padding-bottom" style={{
                        minHeight: '100vh'
                    }}>


                        <div className="account-wrapper p-3 pt-5">

                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="section-header mb-0">
                                        <h3 className="cate">{t('Pages.ForgetPassword.title')}</h3>
                                        <h4 className="title">{t('Pages.ForgetPassword.subTitle')}</h4>
                                    </div>
                                </div>
                            </div>

                            <form method="POST" className="contact-form  px-5 pb-5 pt-4" onSubmit={this.onSubmit} autoComplete="new-password">
                                <div className="form-group w-100 mb-2">
                                    <label className='text-dark font-size-h5 w-100 text-right'>{t('Pages.Register.Email.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <Field
                                            ref={Email => this.Email = Email}
                                            name="Email"
                                            component={this.renderField}
                                            type="text"
                                            required={true}
                                            placeholder={t('Pages.Register.Email.Placeholder')}
                                            title={t('Pages.Register.Email.title')}
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="form-group w-100  mb-2">
                                    <div>
                                        <ReCAPTCHA
                                            ref={this._reCaptchaRef}
                                            required={true}
                                            sitekey={TEST_SITE_KEY}
                                            onChange={this.handleChange}
                                            hl={i18n.language}
                                        />
                                    </div>
                                    <div className="invalid-feedback d-block">
                                        {this.state.captchaErorr}
                                    </div>
                                </div>

                                <div className="form-group w-100">
                                    <button className="custom-button mt-1 w-100" disabled={submitting} ><span> {t('Pages.ForgetPassword.Send')}</span></button>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.Email) {
        errors.Email = 'Pages.Register.Email.error';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
        errors.Email = 'Pages.Register.Email.error_notmatch';
    }

    return errors;
}

const formForgetPassword = reduxForm({
    form: 'ForgetPassword',
    validate
});

export default withTranslation('common')(formForgetPassword(ForgetPassword));