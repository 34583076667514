import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';

import {
    fetchFavoriteLessons as fetch,
    setLessonFavorite as setFavorite
} from '../../Admin/Lessons/actions';
import Footer from "../../Footer/FooterBottom";
import { setIndeices } from "../../../context/sessionStorage";
import LessonItem from "../Tracks/LessonItem";
import UserSubMenu from './UserSubMenu';
import { fetchIndices } from '../../Admin/Indices/actions/';
import history from '../../../history';
import { fetchStudentInClassRooms } from '../../School/StudentInClassRooms/actions/';
import { fetchClassRooms } from '../../School/ClassRooms/actions';

class UserFavoriteLesson extends React.Component {

    state = { indices: [], search: '' };

    constructor(props) {
        super(props);
        this.pathList = [];
        window.scrollToTop();
        this.props.fetchIndices();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user &&
            props.user.user &&
            props.user.user.UserType &&
            (props.user.user.UserType.Id === 3 || props.user.user.UserType.Id === 4) &&
            props.classRooms && props.classRooms.length === 0) {
            props.fetchClassRooms();
        }
        return state;
    }

    componentDidMount() {
        this.props.fetch();
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    setCurrentItem = item => {
        this.pathList = [];
        this.getNodePath(this.props.indices.nodes, item.id);

        const length = this.pathList.length;
        if (length > 1) {
            var mainPath = this.pathList[length - 1];
            var secondPath = this.pathList[length - 2];

            history.push(`/${secondPath.indexType !== "Lessons" ? "BookLessons" : secondPath.indexType}/${mainPath.id}/${item.id}`);
        }
    }

    getNodePath(nodes, currentId) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, currentId);
                if (isOpen) {
                    this.getNodePath(node.nodes, currentId);
                    this.pathList.push(node);
                }
            });
        }
    }

    HasNode(node, currentId) {
        if (node) {
            return (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, currentId) {
        var result = false;
        nodes.forEach(node => {
            result = result || (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        });
        return result;
    }

    renderLessons() {
        const { t, user, classRooms } = this.props;
        let lessons = this.props.lessons;
        let isLoaded = false;

        if (lessons) {
            isLoaded = lessons.isLoaded;
        }

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }


        let toRemove = [];
        if (lessons) {
            toRemove = lessons.toRemove;
        }


        if (lessons && lessons.lessons && lessons.lessons.length > 0) {
            let filterLesson = lessons.lessons.filter(v => v.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Lessons.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterLesson.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Lessons.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Lessons.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterLesson.map(lesson =>
                (toRemove && toRemove.includes(lesson.Id)) ? "" :
                    <LessonItem
                        classRooms={classRooms}
                        getStudent={this.fetchStudentInClassRooms}
                        user={user}
                        key={lesson.Id}
                        IsAdmin={false}
                        setFavorite={this.setFavorite}
                        setIndex={this.setCurrentItem}
                        lesson={lesson}
                        indices={indices.nodes}
                        remove={this.showDelete}></LessonItem>
            );
        }

        if (lessons && lessons.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Lessons.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Lessons.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    fetchStudentInClassRooms = (ClassRoomId, MediaId) => {
        this.props.fetchStudentInClassRooms(ClassRoomId, MediaId);
    }

    render() {
        const { t, user } = this.props;

        return (
            <>
                <section className="pos-rel padding-bottom padding-top ">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">

                        <Helmet>
                            <title>
                                {t('Pages.Profile.favoriteList')} | {t('Pages.Profile.title')}
                            </title>
                        </Helmet>

                        <div className="row mt-30 mb-30-none">

                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <span className="cate">{t('Pages.Profile.title')}</span>
                                            <h3 className="title"> {t('Pages.Profile.favoriteList')} </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Lessons.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderLessons()}
                            </div>

                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms,
        user: state.user,
        indices: state.indices,
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { fetchStudentInClassRooms, fetchClassRooms, fetchIndices, setFavorite, fetch })(withTranslation('common')(UserFavoriteLesson));
