import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { Link } from "react-router-dom";

import Footer from "../Footer/FooterBottom";
import SingleRegFrom from "./Forms/SingleRegFrom";
import history from '../../history';
import { userLogin } from '../actions/';
import Backgrounds from "../Admin/Question/View/Background/";

class Register extends React.Component {

    state = {
        showRegisterForm: false,
        IsSchool: false,
        showSchoolModal: false,
        showUserModal: false,
        showContact: false
    };


    componentDidMount() {
        window.scrollToPosition("main-register-section");
    }

    backToMenu = () => {
        this.setState({
            showRegisterForm: false
        });
    }

    showRegisterForm = IsSchool => {
        this.setState({
            IsSchool,
            showRegisterForm: true
        });
    }

    renderSingleForm() {
        const { location } = this.props;
        return <SingleRegFrom
            userLogin={this.props.userLogin}
            IsSchool={this.state.IsSchool}
            goToActivePage={this.goToActivePage}
            country_code={location.location.country_code}
            Address={location.location.Address} />;
    }

    renderSchoolRegister() {
        if (this.state.showRegisterForm === true && this.state.IsSchool === true) {
            const { t } = this.props;
            return (
                <>
                    <Helmet>
                        <title>
                            {t('Pages.Register.School.title')} | {t('alarabeyya')}
                        </title>
                    </Helmet>
                    <div className="widget widget-category col-xl-8 mx-auto">
                        <div className="widget-header">
                            <h5 className="title">
                                <i className="fal fa-school "></i> {t('Pages.Register.School.title')}
                            </h5>

                            <div className="position-absolute" style={{
                                top: '20px',
                                left: '20px'
                            }}>
                                <div className="btn btn-dark " onClick={() => this.backToMenu()} title={t('Pages.Register.Subscriptions')}>
                                    <i className="fal fa-arrow-right"></i> <span className="d-none d-lg-inline-block"> {t('Pages.Register.Subscriptions')} </span>
                                </div>
                            </div>

                        </div>
                        <div className="widget-body">
                            {this.renderSingleForm()}
                        </div>
                    </div>
                </>
            );
        }
    }

    goToActivePage = () => {
        history.push(`/ActivePage`);
    }

    renderSingleRegister() {
        if (this.state.showRegisterForm === true && this.state.IsSchool === false) {
            const { t } = this.props;
            return (
                <>
                    <Helmet>
                        <title>
                            {t('Pages.Register.Single.title')} | {t('alarabeyya')}
                        </title>
                    </Helmet>
                    <div className="widget widget-category col-xl-8 mx-auto">
                        <div className="widget-header">
                            <h5 className="title">
                                <i className="fal fa-user-graduate "></i> {t('Pages.Register.Single.title')}
                            </h5>

                            <div className="position-absolute" style={{
                                top: '20px',
                                left: '20px'
                            }}>
                                <div className="btn btn-dark " onClick={() => this.backToMenu()} title={t('Pages.Register.Subscriptions')}>
                                    <i className="fal fa-arrow-right"></i> <span className="d-none d-lg-inline-block"> {t('Pages.Register.Subscriptions')} </span>
                                </div>
                            </div>

                        </div>
                        <div className="widget-body">
                            {this.renderSingleForm()}
                        </div>
                    </div>
                </>
            );
        }
    }

    showSchoolModal = () => {
        this.setState({
            showSchoolModal: true
        });
    }
    showUserModal = () => {
        this.setState({
            showUserModal: true
        });
    }

    showContact = () => {
        this.setState({
            showContact: true
        }, () => {
            window.Sprintful.initIframe();
        });
    }

    renderStartPage() {
        if (!this.state.showRegisterForm) {
            const { t, location } = this.props;

            let showTestFree = false;
            if (moment().month('July').startOf('month') <= moment()) {
                showTestFree = true;
            }

            return (
                <>
                    {/* <div className="row justify-content-center">
                        <div className="col-lg-8 featured-block">
                            <div className="inner-box d-flex flex-wrap justify-content-center p-0 " >
                                <div className="section-header mb-0 p-5 ">
                                    <h3 className="title mb-3">{t('Pages.Register.subTitle')}</h3>
                                    <span title={t('Pages.Register.Single.Note.title')} className="cate mb-0 d-flex flex-wrap align-items-center justify-content-center" style={{
                                        fontSize: '20px'
                                    }}>
                                        <i className="fa-solid fa-info-circle ml-2 text-info"></i>
                                        {t('Pages.Register.Single.Note.body')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="row clearfix justify-content-center">

                        <div className="featured-block col-sm-6 " >
                            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                <span className="border-one"></span>
                                <span className="border-two"></span>
                                <div className="icon-box d-flex justify-content-center flex-column cursor-pointer">
                                    <span className="icon"> <i className="fal fa-user-graduate display-4 text-white"></i></span>
                                </div>
                                <h5 className="cursor-pointer"> {t('Pages.Register.Single.title')}</h5>
                                <div className="d-flex flex-wrap justify-content-center text-dark align-items-end mt-3">
                                    <h5 className="text-dark m-0" style={{
                                        fontSize: '16px'
                                    }}>معلّم </h5>
                                    <i className="fa-solid fa-circle-small mx-2 text-success"></i>
                                    <h5 className="text-dark m-0" style={{
                                        fontSize: '16px'
                                    }}>طالب </h5>
                                    <i className="fa-solid fa-circle-small mx-2 text-success"></i>
                                    <h5 className="text-dark m-0" style={{
                                        fontSize: '16px'
                                    }}>ولي أمر</h5>
                                </div>
                                {
                                    showTestFree ?
                                        <>
                                            {
                                                location && location.location && (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                    <Link to="#" onClick={() => this.showRegisterForm(false)} className={`custom-button primary-button mt-3`} >
                                                        <span>
                                                            سجل الان
                                                        </span>
                                                    </Link>
                                                    : ""
                                            }
                                            {
                                                location && location.location && (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                    <h5 className="cursor-pointer text-dark" style={{
                                                        fontSize: '16px'
                                                    }} onClick={() => this.showUserModal()}>{t('Pages.Register.desc')}</h5>
                                                    :
                                                    <>
                                                        <Link to="/Register/Single" className={`custom-button primary-button mt-3`} >
                                                            <span>
                                                                اِبدَأْ تجربتك المجّانيّة الآن
                                                            </span>
                                                        </Link>
                                                        <div className="list w-100 text-right">
                                                            <ul>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    إمكانيّة تحميل أيّ ملفّ ورقيّ من الموقع بعد انتهاء التجربة المجّانية.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    فتح كلّ الدروس المحوسبة لمدّة سنة بعد انتهاء التجربة المجّانية.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    فتح كلّ الألعاب المحوسبة لمدّة سنة بعد انتهاء التجربة المجّانية.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    فتح جميع محتويات الموقع لمدّة سنة بعد انتهاء التجربة المجّانية.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    بدون أيّ مخاطر: يمكنك إلغاء الاشتراك بِبِضْعِ نقرات في أيّ وقت خلال الشهر الأوّل، ولن تتحمّل أيّ تكاليف.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Link to="#" onClick={() => this.showRegisterForm(false)} className={`custom-button primary-button mt-3`} >
                                                <span>
                                                    سجل الان
                                                </span>
                                            </Link>
                                            <h5 className="cursor-pointer text-dark" style={{
                                                fontSize: '16px'
                                            }} onClick={() => this.showUserModal()}>{t('Pages.Register.desc')}</h5>
                                        </>
                                }
                            </div>
                        </div>

                        <div className="featured-block col-sm-6 " >
                            <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >
                                <span className="border-one"></span>
                                <span className="border-two"></span>
                                <div className="icon-box d-flex justify-content-center flex-column cursor-pointer">
                                    <span className="icon"> <i className="fal fa-school display-4 text-white"></i></span>
                                </div>
                                <h5 className="cursor-pointer"> {t('Pages.Register.School.title')}</h5>
                                <div className="d-flex flex-wrap justify-content-around text-dark align-items-end mt-3">
                                    <h5 className="text-dark m-0" style={{
                                        fontSize: '16px'
                                    }}>
                                        {t('Pages.Register.School.desc')}
                                    </h5>
                                </div>
                                {
                                    showTestFree ?
                                        <>
                                            {
                                                location && location.location && (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                    <Link to="#" onClick={() => this.showRegisterForm(true)} className={`custom-button primary-button mt-3`} >
                                                        <span>
                                                            سجل الان
                                                        </span>
                                                    </Link>
                                                    : ""
                                            }
                                            {
                                                location && location.location && (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                    <h5 className="cursor-pointer text-dark" style={{
                                                        fontSize: '16px'
                                                    }} onClick={() => this.showSchoolModal()}>{t('Pages.Register.desc')}</h5>
                                                    :
                                                    <>
                                                        <Link to="#" className={`custom-button danger-button mt-3`} onClick={() => this.showContact()}>
                                                            <span className="d-flex justify-content-center align-items-center flex-wrap">
                                                                <div className="d-flex flex-column py-1" >
                                                                    <span style={{
                                                                        lineHeight: '30px'
                                                                    }}>
                                                                        30 دقيقة استشارة مجانية
                                                                    </span>
                                                                    <span style={{
                                                                        lineHeight: '25px',
                                                                        fontSize: '16px'
                                                                    }}>
                                                                        التسجيل يستغرق أقلّ مِن 120 ثانية
                                                                    </span>
                                                                </div>
                                                            </span>
                                                        </Link>

                                                        <div className="list w-100 text-right">

                                                            <ul>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    إمكانية تحميل أي ملف ورقي بالموقع.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    فتح كل الملفات المحوسبة  لمدّة سنة.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    فتح كل الألعاب المحوسبة لمدة سنة.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    فتح جميع محتويات الموقع لمدّة سنة.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    فتح حساب منفرد لكل معلم.
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    امكانية بعث مهام محوسبة للطلاب (غير متوفرة بالاشتراكات الفرديّة).
                                                                </li>
                                                                <li>
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    صفر مخاطر: إذا لم تكن راضٍ لأي سبب، يمكنك استرجاع كامل المبلغ خلال 30 يومًا من الاشتراك.
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Link to="#" onClick={() => this.showRegisterForm(true)} className={`custom-button primary-button mt-3`} >
                                                <span>
                                                    سجل الان
                                                </span>
                                            </Link>
                                            <h5 className="cursor-pointer text-dark" style={{
                                                fontSize: '16px'
                                            }} onClick={() => this.showSchoolModal()}>{t('Pages.Register.desc')}</h5>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    hideModal = () => {
        this.setState({
            showSchoolModal: false,
            showUserModal: false
        });
    }

    handleClose = () => {
        this.setState({
            showContact: false
        });
    }

    render() {
        const { t, location } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Register.title')}  | {t('alarabeyya')}
                    </title>
                    <script type="text/javascript" src="https://app.sprintful.com/widget/v1.js"></script>
                    <link href="https://app.sprintful.com/widget/v1.css" rel="stylesheet"></link>
                </Helmet>
                {
                    this.state.showSchoolModal ?
                        <Modal
                            size="xl"
                            show={this.state.showSchoolModal}
                            onHide={this.hideModal}
                            backdrop="static"
                            centered
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="mx-1 fal fa-info"></i>
                                    {t('Pages.Register.desc')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category ">
                                    <div className="widget-body">
                                        <p className="text-justify">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                المسار الأساسي:
                                            </span>

                                            فيه تحصل المدرسة على جميع المواد التعليمية من ملفات ودروس وامتحانات محوسبة لجميع الصفوف في المرحلة التعليمية بدون تحديدٍ لعدد الطلاب، كما ويمكن للمعلم أن يبعث مهامّا لطلابه وكذلك الأمر مع الملفات، بالإضافة إلى إظهار أيّ مادة محوسبة للطالب ليقوم بدراستها والتدرب عليها في البيت.
                                            <br />
                                            {
                                                location.location.country_code === "ps" || (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                    <span className="font-weight-bold mx-1 cursor-pointer">
                                                        قيمة هذا الاشتراك هو: 2500 شاقل
                                                    </span>
                                                    :
                                                    <span className="font-weight-bold mx-1 cursor-pointer">
                                                        قيمة هذا الاشتراك هو: 850 دولار
                                                    </span>
                                            }
                                        </p>
                                        <p className="text-justify">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                المسار الممتاز
                                                :
                                            </span>
                                            فيه تحصل المدرسة على ما في المسار العادي، بالإضافة إلى قسم الكتب التعليميّة الإثرائيّة وقسم المسار الترفيهيّ والذي يحوي على ألعاب تعليميّة في اللغة العربيّة
                                            <br />
                                            {
                                                location.location.country_code === "ps" || (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                    <span className="font-weight-bold mx-1 cursor-pointer">
                                                        قيمة هذا الاشتراك هو: 3200 شاقل
                                                    </span>
                                                    :
                                                    <span className="font-weight-bold mx-1 cursor-pointer">
                                                        قيمة هذا الاشتراك هو: 980 دولار
                                                    </span>
                                            }
                                        </p>
                                        <p className="text-justify">
                                            مُدّة الاشتراك
                                            :
                                            <u className="font-weight-bold mx-1 d-inline-block">
                                                سنة كاملة
                                                (
                                                12 شهرا
                                                )
                                            </u>
                                            .
                                        </p>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                {
                    this.state.showUserModal ?
                        <Modal
                            size="xl"
                            show={this.state.showUserModal}
                            onHide={this.hideModal}
                            backdrop="static"
                            centered
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="mx-1 fal fa-info"></i>
                                    {t('Pages.Register.desc')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category ">
                                    <div className="widget-body">
                                        <p className="text-justify">
                                            <span className="mx-1 text-primary d-inline-block font-weight-bold">
                                                اشتراك أساسيّ مقابل  3 صفوفٍ
                                                :
                                            </span>
                                            يحصل فيه المستخدم على جميع المواد التعليميّة من ملفّات ودروس وامتحانات محوسبة للصفوف التي تمّ اختيارها.
                                            <br />

                                            <span className="font-weight-bold mx-1 ">
                                                قيمة الاشتراك
                                                <u className="mx-1 d-inline-block font-weight-bold text-primary">
                                                    لثلاثة صفوف
                                                </u>
                                                هو:
                                                {
                                                    location.location.country_code === "ps" || (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                        <>
                                                            600 شاقل
                                                        </>

                                                        :
                                                        <>
                                                            180 دولار
                                                        </>
                                                }
                                            </span>
                                        </p>
                                        <p className="text-justify">
                                            -
                                            كلّ 3 صفوفٍ
                                            <u className="mx-1 d-inline-block font-weight-bold text-primary">
                                                إضافيّة
                                            </u>
                                            تكون بقيمة

                                            {
                                                location.location.country_code === "ps" || (location.location.country_code === "il" || location.location.country_code === "ps") ?
                                                    <span className="mx-1 font-weight-bold d-inline-block text-primary">
                                                        100
                                                        شاقل
                                                    </span>
                                                    :
                                                    <span className="mx-1 font-weight-bold d-inline-block text-primary">
                                                        30
                                                        دولار
                                                    </span>
                                            }
                                            تضاف على السعر الأساسيّ
                                        </p>
                                        <p className="text-justify">
                                            -
                                            بعد التسجيل يمكنك إضافة خدمات أخرى مثل الكتب المحوسبة والألعاب التعليمية وملفّات المعلّم
                                        </p>

                                        <p className="text-justify">
                                            -
                                            يحصل المشترك على
                                            <u className="font-weight-bold mx-1 d-inline-block text-primary">
                                                إضافةٍ مجّانيّة
                                            </u>
                                            ، وهي تسجيل أبنائه
                                            <u className="font-weight-bold mx-1 d-inline-block text-primary">
                                                مجّانا
                                            </u>
                                            بعد الاشتراك، بحيث يكون لكلّ واحد من أبنائه حساب خاصّ به، تُرفَق لهم الصفوف التي تمّ اختيارها وكلّ ما تمّ شراؤه.
                                        </p>
                                        <p className="text-justify">
                                            -
                                            مُدّة الاشتراك
                                            :
                                            <u className="font-weight-bold mx-1 d-inline-block">
                                                سنة كاملة (12 شهرا)
                                            </u>
                                            .
                                        </p>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }

                {
                    this.state.showContact ?
                        <Modal
                            size="xl"
                            show={this.state.showContact}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fa-light fa-handshake-angle"></i> {t('Pages.Register.Contact')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget">
                                    <div className="widget-body">
                                        <div
                                            className="sprintful-inline-widget"
                                            data-copy-parents-query="false"
                                            data-url="https://on.sprintful.com/alarabeyya?hide-logo=false&hide-message=false"
                                            style={{
                                                minWidth: '320px',
                                                height: '80vh'
                                            }}></div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                <section className="pos-rel call-in-action call-overlay oh" id="main-register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>
                    <div className="position-relative padding-bottom padding-top" style={{
                        minHeight: '100vh'
                    }}>
                        <Backgrounds CustomBackground="Registration" />
                        <div className="container h-100" >
                            <div>
                                {this.renderStartPage()}
                                {this.renderSingleRegister()}
                                {this.renderSchoolRegister()}
                            </div>
                        </div>

                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};

export default connect(mapStateToProps, { userLogin })(withTranslation('common')(Register));