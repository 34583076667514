import React from "react";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

import Footer from "../Footer/FooterBottom";
import api from '../../api/api';
import history from '../../history';
import TopOfMountain from "../Admin/Question/View/Background/TopOfMountain";

const TEST_SITE_KEY = `${process.env.REACT_APP_TEST_SITE_KEY}`;

class ResetPassword extends React.Component {

    state = { captchaErorr: null, errorList: [], _token: '', currentStatus: "" };

    constructor(props) {
        super(props);
        this._reCaptchaRef = React.createRef();
        this._toastId = new React.createRef();

        const { search } = this.props.location;
        this.state._token = search.substring(1);
    }


    componentDidMount() {
        window.scrollToPosition("main-reset-password-section");
    }


    handleChange = value => {
        this.setState({ value });
        if (value === null) {
            this.setState({ expired: "true" });
        } else {
            this.setState({ captchaErorr: '' });
        }
    };

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};

        if (!this.NewPassword.ref.current.getValue()) {
            errors.NewPassword = 'Pages.ChangePassword.NewPassword.error';
        } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(this.NewPassword.ref.current.getValue())) {
            errors.NewPassword = 'Pages.ChangePassword.NewPassword.error_notmatch';
        }

        if (!this.RePassword.ref.current.getValue()) {
            errors.RePassword = 'Pages.ChangePassword.RePassword.error';
        } else if (this.RePassword.ref.current.getValue() !== this.NewPassword.ref.current.getValue()) {
            errors.RePassword = 'Pages.ChangePassword.RePassword.error_notmatch';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;
        const recaptchaValue = this._reCaptchaRef.current.getValue();

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if ((errors && errors.length !== 0 && Object.keys(errors).length !== 0) || !recaptchaValue) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                currentStatus: "Wrong"
            });
        } else {
            let postData = {
                NewPassword: this.NewPassword.ref.current.getValue(),
                RePassword: this.RePassword.ref.current.getValue(),
                _token: this.state._token,
                recaptchaValue
            };

            api.put(`/UserResetPassword/`,
                postData
            ).then(response => {
                if (response.data.code === "success") {

                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ChangePassword.success_toast.Title')}</div> <div className="h6">{t('Pages.ChangePassword.success_toast.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    this.setState({
                        currentStatus: "True"
                    }, () => {
                        setTimeout(() => {
                            history.push("/Login");
                        }, 1000);
                    });

                } else {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        currentStatus: "Wrong"
                    });
                }
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    currentStatus: "Wrong"
                });
            });
        }
        e.preventDefault();
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    render() {
        const { submitting, t, i18n } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.ResetPassword.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>

                <section className="pos-rel call-overlay call-in-action oh" id="main-reset-password-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53',
                    minHeight: '100vh'
                }}>

                    <TopOfMountain currentStatus={this.state.currentStatus} FinishMove={this.FinishMove} />

                    <div className="container padding-top padding-bottom" style={{
                        minHeight: '100vh'
                    }}>

                        <div className="account-wrapper p-3 pt-5">

                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="section-header mb-0">
                                        <h3 className="title">{t('Pages.ResetPassword.title')}</h3>
                                    </div>
                                </div>
                            </div>

                            <form method="POST" className="contact-form px-5 pb-5 pt-4" onSubmit={this.onSubmit} autoComplete="new-password">

                                <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                                    <h4 className="alert-heading">{t('ErrorsList')}</h4>
                                    <div className="border-bottom border-white opacity-20"></div>
                                    <div className="alert-text">
                                        <ul className="list-unstyled mt-4">
                                            <li>
                                                <ul>
                                                    {this.state.errorList}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="form-group w-100">
                                    <label className='text-dark font-size-h5 text-right w-100'>{t('Pages.ChangePassword.NewPassword.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <Field
                                        ref={NewPassword => this.NewPassword = NewPassword}
                                        name="NewPassword"
                                        component={this.renderField}
                                        type="password"
                                        required={true}
                                        className="form-control"
                                        placeholder={t('Pages.ChangePassword.NewPassword.Placeholder')}
                                        title={t('Pages.ChangePassword.NewPassword.title')}
                                    />
                                </div>

                                <div className="form-group w-100">
                                    <label className='text-dark font-size-h5 text-right w-100'>{t('Pages.ChangePassword.RePassword.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <Field
                                        ref={RePassword => this.RePassword = RePassword}
                                        name="RePassword"
                                        component={this.renderField}
                                        type="password"
                                        required={true}
                                        className="form-control"
                                        placeholder={t('Pages.ChangePassword.RePassword.Placeholder')}
                                        title={t('Pages.ChangePassword.RePassword.title')}
                                    />
                                </div>

                                <div className="form-group w-100  mb-2">
                                    <div>
                                        <ReCAPTCHA
                                            ref={this._reCaptchaRef}
                                            required={true}
                                            sitekey={TEST_SITE_KEY}
                                            onChange={this.handleChange}
                                            hl={i18n.language}
                                        />
                                    </div>
                                    <div className="invalid-feedback d-block">
                                        {this.state.captchaErorr}
                                    </div>
                                </div>

                                <div className="form-group w-100">
                                    <button className="custom-button mt-1 w-100" disabled={submitting}><span> <i className="fal fa-save"></i> {t('Pages.ChangePassword.submit')}</span></button>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const validate = values => {
    const errors = {};


    if (!values.NewPassword) {
        errors.NewPassword = 'Pages.ChangePassword.NewPassword.error';
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(values.NewPassword)) {
        errors.NewPassword = 'Pages.ChangePassword.NewPassword.error_notmatch';
    }

    if (!values.RePassword) {
        errors.RePassword = 'Pages.ChangePassword.RePassword.error';
    } else if (values.RePassword !== values.NewPassword) {
        errors.RePassword = 'Pages.ChangePassword.RePassword.error_notmatch';
    }

    return errors;
}

const formResetPassword = reduxForm({
    form: 'ResetPassword',
    validate
});

export default withTranslation('common')(formResetPassword(ResetPassword));
