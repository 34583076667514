import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import ScrollArea from "react-scrollbar";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import QuestionView from '../Question/View/QuestionView';
import Tools from "./Tools";
import Paint from "../../Home/Tracks/LessonView/Paint";
import history from '../../../history';
import CountdownCircle from '../Countdown/';
import ErrorReportModal from '../ErrorReport/Model.js';

import { fetchIndices } from '../Indices/actions/';
import { updateQuestionVersion } from '../Question/actions';

var nodeIndexType = {};

const nodePath = (nodes, currentId, Level = 0) => {
    nodeIndexType = {};
    getNodePath(nodes, currentId, Level);
}

const getNodePath = (nodes, currentId, Level = 0) => {
    if (nodes) {
        nodes.forEach(node => {
            const isOpen = HasNode(node, currentId);
            if (isOpen) {
                getNodePath(node.nodes, currentId, Level);
                if (node.Level === 3) {
                    nodeIndexType = node.indexType;
                }
            }
        });
    }
}
const HasNode = (node, currentId) => {
    if (node) {
        return (currentId === node.id) || hasSelectedNodeInChildren(node.nodes, currentId);
    }
    return false;
}
const hasSelectedNodeInChildren = (nodes, currentId) => {
    var result = false;
    nodes.forEach(node => {
        result = result || (currentId === node.id) || hasSelectedNodeInChildren(node.nodes, currentId);
    });
    return result;
}

class View extends React.Component {

    state = {
        showErrorModal: false,
        questionErrorId: 0,
        indexType: 1,
        indices: [],
        currentLeft: 0,
        currentRight: 0,
        currentFullscreen: 0,
        isFullscreenEnabled: false,
        FontSize: 18,
        showLessonInfoModal: false,
        showDragDropModal: false, resetQuestion: false, showPagesModal: false, showPaint: false,
        QuestionToRemove: [],
        questionSeparated: {
            BorderStyle: "dashed",
            BorderColor: 'rgba(68, 68, 68, 1)',
            BorderWidth: 3,
            width: 100
        },
        Clock: {
            TimeBorderColor: 'rgba(146, 39, 143, 1)',
            BackgroundBorderColor: 'rgba(255, 255, 255, 1)',
            TimeType: "continue",
            TimeMinutes: 0,
            TimeHour: 0
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            indices: [],
            showErrorModal: false,
            questionErrorId: 0,
            indexType: 1,
            FontSize: 18,
            showSound: false,
            showText: false,
            styleLeftSide: '',
            styleBind: '',
            styleRightSide: '',
            showPaint: false,
            currentLeft: 0,
            currentRight: 0,
            first: true,

            Id: 0,
            Name: '',
            Bio: '',
            search: '',
            Url: '',
            Sort: 0,
            Sound: '',
            Icon: '',
            Background: '',
            Questions: [],
            QuestionToRemove: [],
            IsActive: true,
            IsPublic: false,
            IndexId: 0,
            questionSeparated: {
                BorderStyle: "dashed",
                BorderColor: 'rgba(68, 68, 68, 1)',
                BorderWidth: 3,
                width: 100
            },
            Clock: {
                TimeBorderColor: 'rgba(146, 39, 143, 1)',
                BackgroundBorderColor: 'rgba(255, 255, 255, 1)',
                TimeType: "continue",
                TimeMinutes: 0,
                TimeHour: 0
            }
        };
        this.props.fetchIndices();
        this._toastId = new React.createRef();
    }
    componentDidMount() {
        setTimeout(
            () => {
                window.webuiPopover();
            },
            2500
        );
    }

    static getDerivedStateFromProps(props, state) {
        state.Id = props.Id;
        state.Name = props.Name;
        state.IndexId = props.IndexId
        state.Sound = props.Sound
        state.Url = props.Url
        state.Background = props.Background
        state.Questions = props.Questions;
        state.LessonPage = props.LessonPage;
        state.questionSeparated = props.questionSeparated;
        state.QuestionToRemove = props.QuestionToRemove;

        if (props.indices && props.indices.nodes) {
            nodePath(props.indices.nodes, props.IndexId);
            if (nodeIndexType && nodeIndexType.includes) {
                state.indexType = nodeIndexType.includes("7") || nodeIndexType.includes("8") || nodeIndexType.includes("9") ? 2 : 1;
            } else {
                state.indexType = 1;
            }
        }

        var hasRights = props.Questions.some(p => p.Position === true);
        var hasLefts = props.Questions.some(p => p.Position === false);
        state.hasLefts = props.hasLefts;
        state.hasRights = props.hasRights;
        state.bothSides = (hasLefts && hasRights);

        if ((props.isOnEdit || state.first) && props.Questions && props.Questions.length > 0) {
            state.FontSize = props.FontSize;
            state.first = false;
            if (hasRights && !hasLefts) {
                state = {
                    ...state,
                    styleLeftSide: {
                        style: {
                            display: 'none'
                        }
                    },
                    styleBind: {
                        style: {
                            display: 'none'
                        }
                    },
                    styleRightSide: {
                        style: {
                            width: '100%'
                        }
                    }
                };
            } else if (!hasRights && hasLefts) {
                state = {
                    ...state,
                    styleLeftSide: {
                        style: {
                            width: '100%'
                        }
                    },
                    styleBind: {
                        style: {
                            display: 'none'
                        }
                    },
                    styleRightSide: {
                        style: {
                            display: 'none'
                        }
                    }
                };
            } else {
                state = {
                    ...state,
                    styleLeftSide: {},
                    styleBind: {},
                    styleRightSide: {}
                };
            }
        }
        return state;
    }
    moreFontSize = () => {
        if (this.state.FontSize < 50) {
            this.setState({ FontSize: parseInt(this.state.FontSize) + 1 });
        }
    }
    lessFontSize = () => {
        if (this.state.FontSize > 14) {
            this.setState({ FontSize: this.state.FontSize - 1 });
        }
    }
    resetFontSize = () => {
        this.setState({ FontSize: this.props.FontSize });
    }
    hideSound = () => {
        this.setState({ showSound: false });
        if (!this.state.showSound) {
            if (this.props.showSound) {
                this.props.showSound(false);
            }
        }
    }
    showSound = () => {
        if (!this.state.showSound) {
            if (this.props.showSound) {
                this.props.showSound(!this.state.showSound);
            }
        }
        this.setState({ showSound: !this.state.showSound });
        if (!this.state.Sound) {
            const { t } = this.props;

            this._toastId.current = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-volume-slash"></i> {t('Error.NoSound.Title')}</div> <div className="h6">{t('Error.NoSound.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({ showSound: false });
        }

    }
    hideBookMenw = () => {
        window.showCart();
    }
    hideLeftSide = () => {
        this.setState({
            styleLeftSide: {
                style: {
                    display: 'none'
                }
            },
            styleBind: {
                style: {
                    display: 'none'
                }
            },
            styleRightSide: {
                style: {
                    width: '100%'
                }
            }
        });
        this.props.updateQuestionVersion(1);
    }
    hideRightSide = () => {
        this.setState({
            styleLeftSide: {
                style: {
                    width: '100%'
                }
            },
            styleBind: {
                style: {
                    display: 'none'
                }
            },
            styleRightSide: {
                style: {
                    display: 'none'
                }
            }
        });
        this.props.updateQuestionVersion(2);
    }
    showAllSides = () => {
        this.setState({
            styleLeftSide: {},
            styleBind: {},
            styleRightSide: {}
        });
        this.props.updateQuestionVersion(3);
    }
    setPage(Id, position) {
        if (position === true) {
            this.setState({
                currentLeft: Id
            });
        } else {
            this.setState({
                currentRight: Id
            });
        }
        setTimeout(
            () => {
                window.webuiPopover();
            },
            1500
        );
    }
    openViewWindow = () => {
        if (this.props.type === "Exams" || this.props.type === "Quizzes") {
            window.openExamView(this.state.Id);
        } else if (this.props.type === "SelfDevelopment") {
            window.openSelfDevelopmentView(this.state.Id);
        } else {
            window.openLessonView(this.state.Id);
        }
    }
    showPaint = () => {
        this.setState({ showPaint: !this.state.showPaint });
    }
    openPDFViewWindow = () => {
        window.openPDFViewWindow(this.state.Id);
    }
    fullscreenEnabled = id => {
        this.setState({
            currentFullscreen: id,
            isFullscreenEnabled: true
        });
    }

    renderPageTitle(position) {
        if (this.state.LessonPage) {
            const { isOnEdit } = this.props;
            const LessonPage = this.state.LessonPage.filter(p => p.Position === position);
            if (LessonPage.length > 1) {
                let first = true;
                let questionCount = 0;
                const questionPagesCount = this.state.Questions.filter(p => p.Position === !position).length;
                return (
                    <>
                        <div className="tags-area p-0 justify-content-center">
                            <ul className="tags py-1" >
                                {
                                    LessonPage.map(page => {
                                        let attr = {};
                                        if (position === true && (this.state.currentLeft === page.Id || (this.state.currentLeft === 0 && first))) {
                                            attr = {
                                                className: "active"
                                            };
                                            first = false;
                                        } else if (position === false && (this.state.currentRight === page.Id || (this.state.currentRight === 0 && first))) {
                                            attr = {
                                                className: "active"
                                            };
                                            first = false;
                                        }
                                        if (!isOnEdit) {
                                            if (questionPagesCount <= questionCount) {
                                                return "";
                                            }
                                        }
                                        questionCount += page.Count;
                                        return <li className="py-0" key={page.Id}>
                                            <Link to="#" {...attr} onClick={() => this.setPage(page.Id, position)}>{page.Name}</Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </>
                );
            }
        }
        return "";
    }
    renderPage(position) {
        let className = "singlePageBook";
        if (this.state.LessonPage) {
            const LessonPage = this.state.LessonPage.filter(p => p.Position === !position);
            if (LessonPage.length > 1) {
                className = "multiPageBook";
            }
        }
        return (
            <>
                <ScrollArea
                    horizontal={false}
                    className={`area w-100 ${className}`}
                    smoothScrolling={true}
                    contentClassName="content w-100"  >
                    <div className="page-inner pr-3 w-100"
                        style={{ fontSize: this.state.FontSize + 'px' }}
                    >
                        {this.renderQuestion(position)}
                    </div>
                </ScrollArea>
                {this.renderPageTitle(!position)}
            </>
        );
    }
    renderAudioPlayer() {
        const { t } = this.props;
        if (this.state.showSound && this.state.Sound) {
            return (
                <div id="AudioPlayer" className={`position-fixed w-100 bg-white`} style={{
                    bottom: 0,
                    zIndex: 9999999
                }}>
                    <div className="ribbon-target sound" onClick={() => this.hideSound()} title={t('Close')} style={{ top: '0px', height: '40px' }}>
                        <span className="ribbon-inner bg-danger"></span>
                        <i className="fas fa-times px-1"></i>
                    </div>
                    <div className="text-center" style={{ direction: 'ltr' }}>
                        <AudioPlayer src={this.state.Sound} />
                    </div>
                </div>
            );
        }
        return '';
    }
    renderQuestion(Position) {
        const { t, isOnEdit, user } = this.props;

        let toRemove = this.state.QuestionToRemove;
        let questionCount = 0;

        let count = 1000;
        let from = 0;
        let last = false;
        let LessonPageId = 0;
        if (this.state.LessonPage) {
            const LessonPage = this.state.LessonPage.filter(p => p.Position === !Position);
            if (LessonPage && LessonPage.length > 1) {
                if (Position === false) {
                    LessonPageId = this.state.currentLeft;
                } else {
                    LessonPageId = this.state.currentRight;
                }
                var page = LessonPage.filter(p => p.Id === LessonPageId);
                if (page && page.length === 0) {
                    page = LessonPage.sort((a, b) => a.Sort - b.Sort)[0];
                } else {
                    page = page[0];
                    const array = LessonPage.sort((a, b) => a.Sort - b.Sort);
                    for (let i = 0; i < array.length; i++) {
                        if (i === array.length - 1) {
                            last = true;
                        }
                        if (array[i].Id === page.Id) {
                            break;
                        }
                        from += array[i].Count;
                    }
                }
                if (last) {
                    count = 1000;
                } else {
                    count = page.Count;
                }
            }
        }

        let style = {
        };
        if (this.state.questionSeparated) {
            style = {
                borderWidth: `${this.state.questionSeparated.BorderWidth}px`,
                borderStyle: this.state.questionSeparated.BorderStyle,
                borderColor: this.state.questionSeparated.BorderColor,
                width: `${this.state.questionSeparated.width}%`
            };
        }
        const Questions = this.state.Questions.filter(p => p.Position === Position).sort((a, b) => a.Sort - b.Sort).slice(from, count + from);
        const length = Questions.length;

        const mins = this.state.Questions.filter(p => p.Position === Position).sort((a, b) => a.Sort - b.Sort).slice(0, from).filter(p => p.Type.Id === 11).length;
        questionCount = from - mins;

        return Questions.map((question, key) => {
            if (question.Type.Id !== 11) {
                questionCount++;
            }
            return (toRemove && toRemove.includes(question.Id)) ? "" :
                <div className={`question-container`} key={`question-container-${question.Id}`}>
                    <div className={`question-container bg-white question-container-${question.Id}`} >
                        <QuestionView
                            questionSeparated={this.state.questionSeparated}
                            indexType={this.state.indexType ? this.state.indexType : 1}
                            question={question}
                            answers={question.Answers}
                            key={question.Id}
                            index={questionCount}
                            showResult={this.props.showResult}
                            onEdit={false}
                            solveButton={true}
                            isExamQuestion={this.props.isExamQuestion}
                            saveExamAnswer={this.props.saveExamAnswer}
                            examAnswers={this.props.examAnswers}
                            FontSize={this.state.FontSize}></QuestionView>

                        <div className="folad-control">
                            <span className="folad-control-inner">
                                <span className="folad-control-bg">
                                    {
                                        isOnEdit ?
                                            <>
                                                <div
                                                    className="btn btn-sm mx-1"
                                                    title={t('Pages.Lessons.Questions.forms.Edit')}
                                                    onClick={() => this.props.showUpdateModal(question.Id, question.QuestionLessonId, question.Type.Id)}>
                                                    <i className="far fa-edit"></i>
                                                </div>
                                                <div
                                                    className="btn btn-sm mx-1"
                                                    title={t('Pages.Lessons.Questions.forms.Delete')}
                                                    onClick={() => this.props.showDeleteQuestion(question.Id)}>
                                                    <i className="far fa-trash"></i>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        user && user.user && (
                                            user.user.UserType.Id === 2 ||
                                            user.user.UserType.Id === 3 ||
                                            user.user.UserType.Id === 4
                                        ) ?
                                            <div
                                                className="btn btn-sm mx-1"
                                                onClick={() => this.showErrorModal(question.Id)}
                                                title={t('Pages.Lessons.Questions.forms.report')} >
                                                <i className="far fa-tools"></i>
                                            </div>
                                            : ""
                                    }
                                    {
                                        question.Type.Id !== 11 ?
                                            <div
                                                className="btn btn-sm mx-1 expand-button"
                                                alt={`question-container-${question.Id}`}
                                                title={t('Pages.Lessons.Questions.forms.fullscreen')} >
                                                <i className="far fa-expand"></i>
                                            </div>
                                            : ""
                                    }
                                </span>
                            </span>
                        </div>
                    </div>
                    {
                        key + 1 === length ? "" :
                            <>
                                <div className="w-100 my-1 d-flex justify-content-center">
                                    <div className="border-top-0 border-left-0 border-right-0" style={style}>
                                    </div>
                                </div>
                            </>
                    }
                </div>
        });
    }

    showErrorModal = questionErrorId => {
        this.setState({
            showErrorModal: true,
            questionErrorId
        });
    }

    hideErrorModal = () => {
        this.setState({
            showErrorModal: false
        });
    }

    renderPaint() {
        if (this.state.showPaint) {
            return <Paint key={`Painter`} showPaint={this.showPaint}></Paint>
        }
        return '';
    }

    goBack = () => {
        if (this.props.type === "Books") {
            history.push(`/ControlPanel/BookLessons/${this.state.IndexId}`);
        } else if (this.props.type === "Exams") {
            history.push(`/ControlPanel/Exams/${this.state.IndexId}`);
        } else if (this.props.type === "Quizzes") {
            history.push(`/ControlPanel/Quizzes/${this.state.IndexId}`);
        } else if (this.props.type === "SelfDevelopment") {
            history.push(`/ControlPanel/SelfDevelopment/${this.state.IndexId}`);
        } else {
            history.push(`/ControlPanel/Lessons/${this.state.IndexId}`);
        }
    }

    renderCountdownCircle = () => {
        if (this.props.showClock && this.props.duration && this.props.duration > 0) {
            return (
                <CountdownCircle
                    duration={this.props.duration}
                    BackgroundBorderColor={this.props.Clock.BackgroundBorderColor}
                    TimeBorderColor={this.props.Clock.TimeBorderColor} />
            );
        }
        return "";
    }

    render() {
        const { t, isOnEdit, user } = this.props;

        return (
            <>
                {this.renderPaint()}
                <section key={`section${this.state.Id}`} className={`pos-rel padding-bottom padding-top ${isOnEdit ? "pt-5" : ""}`}>
                    <div className="row mt-0" id="bookWapper" >
                        <Helmet>
                            <title>{this.state.Name} | {t('alarabeyya')}</title>
                            <link rel="stylesheet" type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/css/bootstrap-colorpicker.min.css" />
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-colorpicker/2.5.1/js/bootstrap-colorpicker.js"></script>
                            <script src={process.env.PUBLIC_URL + '/assets/redactor/redactor.js?v=' + process.env.REACT_APP_VERSION} ></script>
                            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
                            <link rel="stylesheet" href="https://cdn.jsdelivr.net/jquery.webui-popover/1.2.1/jquery.webui-popover.min.css" />

                            <meta name="keywords" content={this.state.Name} />

                            <meta property="og:title" content={this.state.Name + ' | ' + t('alarabeyya')} />
                            <meta property="twitter:title" content={this.state.Name + ' | ' + t('alarabeyya')} />

                            <meta property="og:url" content={`https://www.alarabeyya.com/LessonView/${this.state.Id}`} />
                            <meta property="twitter:url" content={`https://www.alarabeyya.com/LessonView/${this.state.Id}`} />

                            {
                                this.state.Bio ? <>
                                    <meta name="description" content={this.state.Bio} />
                                    <meta property="og:description" content={this.state.Bio} />
                                    <meta property="twitter:description" content={this.state.Bio} />
                                </> : ""
                            }
                            {
                                this.state.Icon ? <>
                                    <meta property="image" itemprop="image" content={this.state.Icon} />
                                    <meta property="og:image" itemprop="image" content={this.state.Icon} />
                                    <meta property="twitter:image" itemprop="image" content={this.state.Icon} />
                                    <link rel="apple-touch-icon" href={this.state.Icon} />
                                </> : ""
                            }
                        </Helmet>
                        <style dangerouslySetInnerHTML={{
                            __html: `.webui-popover-inner{ font-size:${this.state.FontSize}px !important; }html{background-position: 50% 50%;height: 100vh;background-size: cover;background-repeat: no-repeat;background-attachment:fixed;background-image: ${this.state.Background !== '' ? "url('" + this.state.Background + "?v=1')" : ""};}body{background: unset;}`
                        }}></style>
                        {
                            this.state.showErrorModal &&
                                user && user.user && (
                                    user.user.UserType.Id === 2 ||
                                    user.user.UserType.Id === 3 ||
                                    user.user.UserType.Id === 4
                                ) ?
                                <ErrorReportModal LessonId={this.state.Id} questionId={this.state.questionErrorId} hideErrorModal={this.hideErrorModal} />
                                : ""
                        }
                        <div className="container">
                            <Tools
                                user={user}
                                LessonId={this.state.Id}
                                isRated={this.props.isRated}
                                lessonTitle={this.state.Name}
                                showLessonInfoModal={this.props.showLessonInfoModal}
                                hideSound={this.hideSound}
                                Sound={this.state.Sound}
                                Url={this.state.Url}
                                openPDFViewWindow={this.openPDFViewWindow}
                                showSort={this.props.showSort}
                                bothSides={this.state.bothSides}
                                hideBookMenw={this.hideBookMenw}
                                isOnEdit={this.props.isOnEdit}
                                openViewWindow={this.openViewWindow}
                                showPagesModal={this.props.showPagesModal}
                                showAllSides={this.showAllSides}
                                hideLeftSide={this.hideLeftSide}
                                hideRightSide={this.hideRightSide}
                                showPaint={this.showPaint}
                                showSound={this.showSound}
                                resetFontSize={this.resetFontSize}
                                goBack={this.goBack}
                                moreFontSize={this.moreFontSize}
                                lessFontSize={this.lessFontSize} />
                        </div>
                        <div className="col-sm-12 col-md-11 col-lg-10 col-xxl-8 mx-auto">
                            <div className="row bookWapper position-relative full-h mx-auto">
                                <div className="book_cover col-md-12 full-h paper">
                                    <div className="book_pages col-md-12 full-h paper">
                                        <div className="book_pages col-md-12 full-h paper">
                                            <div className="book_pages col-md-12 full-h paper">
                                                <div className="col-md-12 open-page full-h paper" >
                                                    <div id="book-header">
                                                    </div>
                                                    <div id="book-body">
                                                        {this.renderCountdownCircle()}
                                                        <div className="full-h bookPageWapper page_right" {...this.state.styleRightSide}>
                                                            {this.renderPage(true)}
                                                        </div>
                                                        <div className="page_bind full-h "  {...this.state.styleBind}>
                                                            <span className="top"></span>
                                                            <span className="bottom"></span>
                                                        </div>
                                                        <div className={`full-h bookPageWapper page_left`} {...this.state.styleLeftSide} >
                                                            {this.renderPage(false)}
                                                        </div>
                                                    </div>
                                                    <div id="book-footer">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderAudioPlayer()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        question: state.questions,
        user: state.user,
        indices: state.indices
    };
};
export default connect(mapStateToProps, { fetchIndices, updateQuestionVersion })(withTranslation('common')(View));