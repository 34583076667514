import React from "react";
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { servicesUserInitial, servicesEmpty } from "../../actions/";

class ServiceForm extends React.Component {

    state = { initialize: false, cart: [], errorList: [], touchedActivateUntilDate: false, errorActivateUntilDate: '', SendPay: true };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.props.servicesEmpty();
    }

    componentDidMount() {
        this.props.servicesUserInitial(this.props.user.Id);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.services && props.services.services && props.services.services.length > 0) {

            var service = props.services.services.filter(p => p.IndexType.includes("Main"))[0];
            if (service?.Id) {

                if (props.currentBill) {
                    props.currentBill.Items.forEach(service => {
                        state.cart.push(service.Service);
                    });
                    state.ActivateUntilDate = props.currentBill.ActivateUntilDate.replaceAll("-", "/");
                    setTimeout(() => {
                        props.initialize({
                            Payment: props.currentBill.Payment,
                            ActivateUntilDate: state.ActivateUntilDate,
                            Note: props.currentBill.Note,
                            TotalPrice: props.currentBill.Price,
                            Discount: props.currentBill.Discount,
                            NatPrice: props.currentBill.Price - props.currentBill.Discount,
                        });
                    }, 500);
                } else {

                    var hasMain = state.cart.some(s => s.Id === service.Id);
                    if (!hasMain) {
                        state.cart.push(service);
                    }

                    let TotalPrice = 0;

                    if (state.cart && state.cart[0] && state.cart.length > 0) {
                        TotalPrice = Object.values(state.cart).reduce((r, { Price }) => r + Price, 0);
                    }

                    setTimeout(() => {
                        props.initialize({
                            TotalPrice: TotalPrice,
                            Discount: 0,
                            NatPrice: TotalPrice,
                        });
                    }, 500);
                }
                state.initialize = true;
            }
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        readOnly,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} readOnly={readOnly} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    validate = () => {
        const errors = {};

        if (!this.ActivateUntilDate.ref.value) {
            errors.ActivateUntilDate = 'Pages.UsersControl.bills.ActivateUntilDate.error';
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(this.ActivateUntilDate.ref.value)) {
            errors.ActivateUntilDate = 'Pages.UsersControl.bills.ActivateUntilDate.error_notmatch';
        }

        return errors;
    }

    onSubmit = e => {
        const { t } = this.props;

        let TotalPrice = Object.values(this.state.cart).reduce((r, { Price }) => r + Price, 0);
        let Discount = this.Discount.ref.current.getValue() ? this.Discount.ref.current.getValue() : 0;

        if (TotalPrice < Discount) {
            this.props.change('NatPrice', 0);
            this.props.change('Discount', TotalPrice);
        } else {
            this.props.change('NatPrice', TotalPrice - Discount);
        }

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.Register.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {

            var hasClassService = this.props.currentBill ? true : this.state.cart.some(p => p.IndexType.includes("Class"));
            var hasMainervice = this.props.currentBill ? false : this.state.cart.some(p => p.IndexType.includes("Main"));
            if (hasMainervice === true && hasClassService === false && this.props.isAdmin !== true) {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                let postData = {
                    Payment: this.Payment.ref.current.getValue(),
                    services: this.state.cart,
                    SendPay: this.state.SendPay,
                    Note: this.Note.ref.current.getValue(),
                    Discount: this.Discount.ref.current.getValue(),
                    Price: this.TotalPrice.ref.current.getValue(),
                    ActivateUntilDate: this.ActivateUntilDate.ref.value,
                };
                this.setState({ errorList: [] });

                this.props.onSubmit(postData, this._toastId.current);
            }
        }
        e.preventDefault();
    }

    renderServiceItem = IndexType => {
        const { services } = this.props;
        if (services && services.services) {
            const { t, user } = this.props;
            return services.services.filter(p => p.IndexType.includes(IndexType)).map(item => {
                let isCheck = this.state.cart?.some(s => s?.Id === item.Id);
                return <li key={item.Id} className="d-flex justify-content-between align-items-center">
                    <div className="custom-control custom-checkbox my-1 mr-sm-4 py-1 checkbox-primay">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            onChange={e => this.addServicesToCart(e.target.checked, item)}
                            defaultChecked={isCheck}
                            name={`serviceId-${item.Id}`}
                            id={`serviceId-${item.Id}`} />
                        <label className="custom-control-label" htmlFor={`serviceId-${item.Id}`} >
                            {item.Name}
                        </label>
                    </div>
                    <div className="h5">
                        {item.Price}
                        {
                            user.Country === "ps" || user.Country === "il" ?
                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i> :
                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i>
                        }
                    </div>
                </li>;
            });
        }
        return "";
    }

    renderServices() {
        const { t } = this.props;
        return (
            <>
                <h3 className="title py-2 display-5">
                    <i className="fad fa-users-class text-danger"></i>
                    {t('Pages.Profile.services.Class')}
                </h3>
                <ul className="mb-5">
                    {this.renderServiceItem("Class")}
                </ul>
                <h3 className="title py-2 display-5">
                    <i className="fad fa-books text-danger"></i>
                    {t('Pages.Profile.services.Books')}
                </h3>
                <ul className="mb-5">
                    {this.renderServiceItem("Book")}
                </ul>
                <h3 className="title py-2 display-5">
                    <i className="fad fa-grin-squint-tears text-danger"></i>
                    {t('Pages.Profile.services.Funs')}
                </h3>
                <ul className="mb-5">
                    {this.renderServiceItem("Fun")}
                </ul>
            </>
        );
    }

    addServicesToCart = (isChecked, service) => {
        let cart = this.state.cart;
        if (isChecked === true) {
            cart.push(service);
        } else {
            cart = cart.filter(s => s.Id !== service.Id);
        }
        this.setState({
            cart
        });

        let TotalPrice = Object.values(cart).reduce((r, { Price }) => r + Price, 0);
        let Discount = this.Discount.ref.current.getValue() ? this.Discount.ref.current.getValue() : 0;

        if (TotalPrice < Discount) {
            this.props.change('NatPrice', 0);
            this.props.change('Discount', TotalPrice);
        } else {
            this.props.change('NatPrice', TotalPrice - Discount);
        }
        this.props.change('TotalPrice', TotalPrice);
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            touchedActivateUntilDate: false,
            errorActivateUntilDate: ''
        });
    }
    onBlurActivateUntilDate = e => {
        const value = e.target.value;
        this.validateActivateUntilDate(value);
    }
    validateActivateUntilDate = value => {
        const { t } = this.props;
        if (!value) {
            this.setState({
                touchedActivateUntilDate: true,
                errorActivateUntilDate: t('Pages.UsersControl.bills.ActivateUntilDate.error')
            });
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value)) {
            this.setState({
                touchedActivateUntilDate: true,
                errorActivateUntilDate: t('Pages.UsersControl.bills.ActivateUntilDate.error_notmatch')
            });
        }
    }

    onDiscount = Discount => {
        let TotalPrice = Object.values(this.state.cart).reduce((r, { Price }) => r + Price, 0);
        if (TotalPrice >= Discount) {
            this.props.change('NatPrice', TotalPrice - Discount);
        } else {
            this.props.change('NatPrice', 0);
            this.props.change('Discount', TotalPrice);
        }
    }

    setSendPay = SendPay => {
        this.setState({
            SendPay
        });
    }

    render() {
        const { t, submitting } = this.props;
        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget widget-feature w-100">
                        <div className="widget-header">
                            <h3 className="title">
                                <i className="fad icon fa-money-check-edit-alt text-danger"></i>
                                {t('Pages.Profile.services.title')}
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div className="row row-col-3 w-100 mx-0">
                                <div className="form-group  col-md">
                                    <label className='text-dark font-size-h5'>{t('Pages.UsersControl.bills.ActivateUntilDate.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <DateRangePicker
                                            ref={ActivateUntilDate => this.ActivateUntilDate = ActivateUntilDate}
                                            initialSettings={{
                                                drops: 'auto',
                                                opens: 'center',
                                                autoUpdateInput: false,
                                                cancelClass: "btn btn-sm btn-danger",
                                                singleDatePicker: true,
                                                showDropdowns: true,
                                                minYear: 2021,
                                                defaultValue: this.state.ActivateUntilDate,
                                                locale: {
                                                    format: 'DD/MM/YYYY',
                                                    cancelLabel: t('Close'),
                                                    firstDay: 6,
                                                    applyLabel: t('Apply'),
                                                    monthNames: [
                                                        `${t("months.January")}`,
                                                        `${t("months.February")}`,
                                                        `${t("months.March")}`,
                                                        `${t("months.April")}`,
                                                        `${t("months.May")}`,
                                                        `${t("months.June")}`,
                                                        `${t("months.July")}`,
                                                        `${t("months.August")}`,
                                                        `${t("months.September")}`,
                                                        `${t("months.October")}`,
                                                        `${t("months.November")}`,
                                                        `${t("months.December")}`
                                                    ],
                                                    daysOfWeek: [
                                                        t("daysOfWeek.Sunday"),
                                                        t("daysOfWeek.Monday"),
                                                        t("daysOfWeek.Tuesday"),
                                                        t("daysOfWeek.Wednesday"),
                                                        t("daysOfWeek.Thursday"),
                                                        t("daysOfWeek.Friday"),
                                                        t("daysOfWeek.Saturday")
                                                    ],
                                                },
                                            }}
                                            onApply={this.handleApplyDate} >
                                            <input
                                                defaultValue={this.state.ActivateUntilDate}
                                                name="ActivateUntilDate"
                                                autoComplete="off"
                                                required
                                                type="text"
                                                onBlur={e => this.onBlurActivateUntilDate(e)}
                                                className={`form-control ${(this.state.touchedActivateUntilDate && this.state.errorActivateUntilDate) ? 'is-invalid' : ''}`}
                                                placeholder={t('Pages.UsersControl.bills.ActivateUntilDate.Placeholder')}
                                                title={t('Pages.UsersControl.bills.ActivateUntilDate.title')}
                                            />
                                        </DateRangePicker>
                                        {this.state.touchedActivateUntilDate && this.state.errorActivateUntilDate && <div className="invalid-feedback">{this.state.errorActivateUntilDate}</div>}
                                    </div>
                                </div>
                                <div className="form-group col-md">
                                    <label className='text-dark font-size-h5'>{t('Pages.UsersControl.bills.Payment.title')} </label>
                                    <div>
                                        <Field
                                            ref={Payment => this.Payment = Payment}
                                            name="Payment"
                                            component={this.renderField}
                                            type="text"
                                            placeholder={t('Pages.UsersControl.bills.Payment.Placeholder')}
                                            title={t('Pages.UsersControl.bills.Payment.title')}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-danger py-1 w-100 mb-4"></div>
                            {this.renderServices()}
                            <div className="bg-danger py-1 w-100 mb-4"></div>
                            <div className="contact-form">
                                <div className="row row-col-3 w-100 mx-0">
                                    <div className="form-group col-md">
                                        <label className='text-dark font-size-h5'>{t('Pages.UsersControl.bills.TotalPrice.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                        <div>
                                            <Field
                                                ref={TotalPrice => this.TotalPrice = TotalPrice}
                                                name="TotalPrice"
                                                component={this.renderField}
                                                type="number"
                                                readOnly={true}
                                                required={true}
                                                placeholder={t('Pages.UsersControl.bills.TotalPrice.Placeholder')}
                                                title={t('Pages.UsersControl.bills.TotalPrice.title')}
                                                className="form-control number"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group col-md">
                                        <label className='text-dark font-size-h5'>{t('Pages.UsersControl.bills.Discount.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                        <div>
                                            <Field
                                                defaultValue={0}
                                                onChange={e => this.onDiscount(e.target.value)}
                                                ref={Discount => this.Discount = Discount}
                                                name="Discount"
                                                component={this.renderField}
                                                type="number"
                                                required={true}
                                                placeholder={t('Pages.UsersControl.bills.Discount.Placeholder')}
                                                title={t('Pages.UsersControl.bills.Discount.title')}
                                                className="form-control number"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group col-md">
                                        <label className='text-dark font-size-h5'>{t('Pages.UsersControl.bills.TotalPrice.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                        <div>
                                            <Field
                                                ref={NatPrice => this.NatPrice = NatPrice}
                                                name="NatPrice"
                                                component={this.renderField}
                                                type="number"
                                                readOnly={true}
                                                required={true}
                                                placeholder={t('Pages.UsersControl.bills.NatPrice.Placeholder')}
                                                title={t('Pages.UsersControl.bills.NatPrice.title')}
                                                className="form-control number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group w-100">
                                    <label className='text-dark font-size-h5'>{t('Pages.UsersControl.bills.Note.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <Field
                                            ref={Note => this.Note = Note}
                                            name="Note"
                                            component={"textarea"}
                                            type="textarea"
                                            placeholder={t('Pages.UsersControl.bills.Note.Placeholder')}
                                            title={t('Pages.UsersControl.bills.Note.title')}
                                            className="form-control"
                                        />
                                    </div>
                                </div>


                                <div className="form-group w-100">
                                    <label className="text-dark font-size-h5" htmlFor="SendPay">
                                        <i className="icon fad fa-lock text-warning"></i> {t(`Pages.UsersControl.bills.SendPay.title`)}
                                    </label>
                                    <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-danger">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={e => this.setSendPay(e.target.checked)}
                                            ref={SendPay => this.SendPay = SendPay}
                                            defaultChecked={this.state.SendPay}
                                            name="SendPay"
                                            id="SendPay" />
                                        <label className="custom-control-label" htmlFor="SendPay" >
                                            {t(`Pages.UsersControl.bills.SendPay.Placeholder`)}
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="form-group w-100 text-center">
                        <button className="custom-button" disabled={submitting} >
                            <span>
                                <i className="icon fal fa-save"></i> {t('Pages.ProfileEdit.Send')}
                            </span>
                        </button>
                    </div>
                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.ActivateUntilDate) {
        errors.ActivateUntilDate = 'Pages.UsersControl.bills.ActivateUntilDate.error';
    } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(values.ActivateUntilDate)) {
        errors.ActivateUntilDate = 'Pages.UsersControl.bills.ActivateUntilDate.error_notmatch';
    }

    return errors;
}

const formServiceForm = reduxForm({
    form: 'ServiceForm',
    validate
});

const mapStateToProps = state => {
    return {
        services: state.services,
    };
};

export default connect(mapStateToProps, { servicesUserInitial, servicesEmpty })(withTranslation('common')(formServiceForm(ServiceForm)));
