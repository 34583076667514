import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import StudentInClassRoomItem from "./StudentInClassRoomItem";
import {
    fetchStudentInClassRooms as fetch,
    removeStudentInClassRooms as remove,
    undoStudentInClassRooms as undo,
    clearStudentInClassRooms as clear,
    updateStudentInClassRoom as update,
    addStudentInClassRoom as add
} from './actions/';
import api from '../../../api/api';
import SingleRegFrom from '../../Home/Forms/SingleRegFrom';

class StudentInClassRoomsList extends React.Component {

    state = { showAdd: false, search: '', classRoomId: 0, studentType: "all" };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.classRoomId && state.classRoomId !== props.classRoomId) {
            state.classRoomId = props.classRoomId;
            setTimeout(() => {
                props.fetch(props.classRoomId, props.LessonId);
            }, 100);
        }
        return state;
    }

    showAdd = () => {
        this.setState({
            showAdd: true
        });
    }
    handleCloseAdd = () => {
        this.setState({
            showAdd: false,
        });
    }
    Sync = () => {
        this.props.fetch(this.props.classRoomId, this.props.LessonId);
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteStudentInClassRoom(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    deleteStudentInClassRoom = id => {
        const { t, classRoomId } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/StudentInClassRooms/${id}?ClassRoomId=${classRoomId}`).then(response => {

            this.props.clear(id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.StudentInClassRoomsControl.toast.Delete.Title')}</div> <div className="h6">{t('Pages.StudentInClassRoomsControl.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    needAssistant = student => {
        if (student) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('ChangeNeedAssistantProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.needAssistantConfirm(student); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    needAssistantConfirm = student => {
        const { t, classRoomId } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/StudentNeedAssistant/`, {
            ClassRoomId: classRoomId,
            StudentId: student.Id
        }).then(response => {

            this.Sync();

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-edit"></i> {t('Pages.StudentInClassRoomsControl.toast.NeedAssistant.Title')}</div> <div className="h6">{t('Pages.StudentInClassRoomsControl.toast.NeedAssistant.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    renderStudentInClassRooms() {
        const { t, studentInClassRooms, forShare, LessonId, FileId, Lesson } = this.props;
        let isLoaded = false;
        let toRemove = [];

        if (studentInClassRooms) {
            toRemove = studentInClassRooms.toRemove;
            isLoaded = studentInClassRooms.isLoaded;
        }

        if (studentInClassRooms && studentInClassRooms.studentInClassRooms && studentInClassRooms.studentInClassRooms.length > 0) {
            let filterStudentInClassRooms = studentInClassRooms.studentInClassRooms.filter(v => v.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.StudentInClassRoomsControl.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterStudentInClassRooms.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.StudentInClassRoomsControl.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.StudentInClassRoomsControl.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            if (this.state.studentType === "without_assistant") {
                filterStudentInClassRooms = filterStudentInClassRooms.filter(p => p.NeedAssistant === false);
            } else if (this.state.studentType === "with_assistant") {
                filterStudentInClassRooms = filterStudentInClassRooms.filter(p => p.NeedAssistant === true);
            }

            return filterStudentInClassRooms.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(teacher =>
                (toRemove && toRemove.includes(teacher.Id)) ? "" :
                    <StudentInClassRoomItem
                        forShare={forShare}
                        FileId={FileId}
                        File={this.props.File}
                        type={this.props.type}
                        LessonId={LessonId}
                        Lesson={Lesson}
                        key={teacher.Id}
                        item={teacher}
                        Sync={this.Sync}
                        isTeacher={this.props.isTeacher}
                        updateStudentInClassRoom={this.props.update}
                        classRooms={this.props.classRooms.classRooms}
                        remove={this.showDelete}
                        needAssistant={this.needAssistant}
                    />
            );
        }

        if (studentInClassRooms && studentInClassRooms.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.StudentInClassRoomsControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }


        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.StudentInClassRoomsControl.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    onSubmit = (postData, _toastId) => {
        const { t, classRoomId } = this.props;

        api.post(`/StudentInClassRooms/${classRoomId}`,
            postData
        ).then(response => {
            if (response.data.code === "exists_username") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Username.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Username.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "exists_email") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "success") {

                this.props.add(response.data.data);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.StudentInClassRoomsControl.toast.Add.Title')}</div> <div className="h6">{t('Pages.StudentInClassRoomsControl.toast.Add.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleCloseAdd();
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    setSelectedStudents = studentType => {
        this.setState({
            studentType
        });
        if (this.props.setSelectedStudents) {
            this.props.setSelectedStudents(studentType);
        }
    }

    render() {
        const { t, user, studentInClassRooms } = this.props;

        let isLoaded = false;
        if (studentInClassRooms) {
            isLoaded = studentInClassRooms.isLoaded;
        }

        return (
            <>
                {
                    this.state.showAdd ?
                        <Modal
                            size="lg"
                            centered
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false}
                            show={this.state.showAdd}
                            onHide={this.handleCloseAdd}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div className="h5 mb-0">
                                        <i className="fad fa-plus text-primary icon"></i> {t("Pages.StudentInClassRoomsControl.Add")}
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category">
                                    <div className="widget-body">
                                        <SingleRegFrom
                                            country_code={user.user ? user.user.Country : ""}
                                            sendText={t('Pages.StudentInClassRoomsControl.Save')}
                                            isForTeacher={true}
                                            isStudent={true}
                                            onSubmit={this.onSubmit} />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }

                <div className="widget widget-search" >
                    <div className="widget-body">
                        <div className="d-flex">
                            <div className="p-2 flex-grow-1 ">
                                <form className="search-form">
                                    <div className="row ">
                                        <div className="form-group col-md-6">
                                            <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.StudentInClassRoomsControl.search")} />
                                            <button type="button"><i className="fas fa-search"></i></button>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <select
                                                onChange={e => this.setSelectedStudents(e.target.value)}
                                                title={t("Pages.StudentInClassRoomsControl.Add")}
                                                className="custom-control">
                                                <option value={"all"}>{t("Pages.StudentInClassRoomsControl.AllStudents")}</option>
                                                <option value={"with_assistant"}>{t("Pages.StudentInClassRoomsControl.NeedAssistant")}</option>
                                                <option value={"without_assistant"}>{t("Pages.StudentInClassRoomsControl.StudentsWithoutNeedAssistant")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {
                                this.props.forShare ? "" :
                                    <div className="pt-3">
                                        <div onClick={() => this.showAdd()} title={t("Pages.StudentInClassRoomsControl.Add")} className="btn btn-primary mx-1">
                                            <i className="fas fa-plus"></i>
                                        </div>
                                        <div onClick={() => this.Sync()} title={t("Pages.StudentInClassRoomsControl.Sync")} className="btn btn-danger">
                                            {
                                                isLoaded ?
                                                    <i className="fas fa-sync-alt"></i>
                                                    :
                                                    <i className="fas fa-sync-alt fa-spin"></i>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                {this.renderStudentInClassRooms()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        studentInClassRooms: state.studentInClassRooms,
        classRooms: state.classRooms
    };
};

export default connect(mapStateToProps, { fetch, remove, clear, undo, update, add })(withTranslation('common')(StudentInClassRoomsList));